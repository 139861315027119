import styled from "styled-components";

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    label {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.14;
    }
    span {
        width: 350px;
        color: red;
        font-size: 12px;
    }
`;

const Input = styled.input`
    ::-webkit-input-placeholder {
        color: #b1b1b1;
        font-size: 16px;
        font-family: "Klavika";
        font-style: normal;
        font-weight: 400;
    }
    border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
    border-radius: 4px;
    padding: 4px 6px;
    &:focus {
        outline: none;
        border: 1px solid ${({ theme: { colors } }) => colors.green.normal};
    }
`;

const Select = styled.select`
    border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
    border-radius: 4px;
    padding: 4px 6px;
    &:focus {
        outline: none;
        border: 1px solid ${({ theme: { colors } }) => colors.green.normal};
    }
`;

const TooltipSelect = styled.select`
    border:none;
    border-radius: 4px;
    padding: 4px 6px;
    outline: none;
    background: none;
    color: 
    &:focus {
        outline: none;
        border: none;
    }
`;

const Textarea = styled.textarea`
    border: 1px solid #f1f1f4;
    padding: 8px 12px;
`;

const CheckboxGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #696969;
`;
const Checkbox = styled.input`
  border: 1px solid ${({ theme: { colors } }) => colors.green.normal};
  margin: 1%;
  &:focus {
    background-color: ${({ theme: { colors } }) => colors.green.normal};
  }
  > input:checked  {
  &:after {
    background-color: ${({ theme: { colors } }) => colors.green.normal};
  }
`;

export { FormGroup, Input, Select, TooltipSelect, Textarea, Checkbox, CheckboxGroup };
