import i18next from "i18next";
import { english } from "./english";
import { polish } from "./polish";
import { czech } from "./czech";
import { german } from "./german";
import { hungarian } from "./hungarian";
import { romanian } from "./romanian";
// const english = require('./english');
// const polish = require('./polish');

i18next.init({
    lng: localStorage.getItem("lang") || "pl",
    fallbackLng: ["en", "pl", "cs"],
    debug: true,
    resources: {
        en: { translation: english },
        pl: { translation: polish },
        cs: { translation: czech },
        de: { translation: german },
        hu: { translation: hungarian },
        ro: { translation: romanian },
    },
});

export { i18next };

// Add this line to your app entrypoint. Usually it is src/index.js
// import './i18n/init';
