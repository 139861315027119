import styled from "styled-components";

export const UserAccountInfo = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
`;

export const SaveButton = styled.button`
    background-color: #1da263;
    color: #fff;
    padding: 3px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #158a4b;
    }
`;

export const DisableButton = styled.button`
    background-color: #d9534f;
    color: #fff;
    padding: 3px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #b52b2b;
    }
`;
export const customStyles = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? "1px solid #00A364" : "1px solid #ccc",
        borderRadius: "5px",
        minHeight: "20px",
        boxShadow: state.isFocused ? "0 0 0 1px #00A364" : "none",
        "&:hover": {
            borderColor: "#00A364",
        },
        cursor: "pointer",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
};
