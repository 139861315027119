const k = require("./keys");

export const polish = {
    [k._]: `:`,
    [k._1]: ``,
    [k._10]: `4`,
    [k._11]: `5`,
    [k._12]: `6`,
    [k._13]: `06:00`,
    [k._14]: `10`,
    [k._15]: `1:`,
    [k._16]: `]`,
    [k._17]: `100%`,
    [k._2]: `-`,
    [k._3]: `/`,
    [k._4]: `.`,
    [k._5]: `(`,
    [k._6]: `12:00`,
    [k._7]: `1`,
    [k._8]: `2`,
    [k._9]: `3`,
    [k.ADD]: `Dodaj`,
    [k.ADD_ADDRESS]: `Dodaj adres`,
    [k.ADD_ANOTHER_ROOM]: `Dodaj kolejne pomieszczenie`,
    [k.ADD_NEW_ADDRESS]: `Dodaj nowy adres`,
    [k.ADDTOCART]: `Dodaj do koszyka`,
    [k.ANNUAL_ELECTRICITY_CONSUMPTION]: `Roczne zużycie energii elektrycznej`,
    [k.ANNUAL_OPERATING_COSTS]: `Roczne koszty eksploatacyjne`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS]: `Czy na pewno chcesz usunąć ten adres?`,
    [k.ARCHITECT]: `Architekt`,
    [k.APARTMENT]: `Mieszkanie`,
    [k.BACK]: `Wróć`,
    [k.TRANSFER]: `Przelew`,
    [k.BANK_TRANSFER]: `Przelew bankowy`,
    [k.BLOCKOFFLATS]: `Blok`,
    [k.BUILDINGENERGYCLASS]: `Klasa energetyczna budynku`,
    [k.BUYBYMETER]: `Kupuję na metry`,
    [k.BUYONROLLS]: `Kupuję na rolki`,
    [k.BYMETERS]: `(na metry)`,
    [k.CONFIRM]: `Zatwierdź`,
    [k.CZECH]: `Czeski`,
    [k.CALCULATECOSTS]: `Oblicz koszty`,
    [k.CALCULATE_HEATING_COSTS]: `Oblicz koszty ogrzewania`,
    [k.CREATE_ACCOUNT]: `Załóż konto`,
    [k.CANCEL]: `Anuluj`,
    [k.CHOOSELANG]: `Wybierz język`,
    [k.CHOOSE_NEXT_STEP]: `Wybierz kolejny krok`,
    [k.COSTCALCULATION]: `Kalkulacja kosztów`,
    [k.CONSTRUCTION]: `Budownictwo`,
    [k.DELETE]: `Usuń`,
    [k.DEVELOPER]: `Deweloper`,
    [k.DISCOUNTGRANTED]: `Przyznany rabat`,
    [k.DISCOUNTLEVEL]: `Twój aktualny poziom rabatu`,
    [k.DISTRIBUTORS]: `Dystrybutorzy`,
    [k.ELECTRICHEATERS]: `Grzejniki elektryczne`,
    [k.EMPLOYEE]: `Pracownik`,
    [k.ENGLISH]: `Angielski`,
    [k.ENTER_ROOMS_DIMENSIONS]: `Podaj wymiary pokoju`,
    [k.ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE]: `Podaj wymiary pomieszczenia na bazie figury`,
    [k.ENTER_A_NEW_PASSWORD]: `Nowe hasło powinno zawierać minimum 8 znaków, małą i wielką literę oraz znak specjalny`,
    [k.FINISHED]: `Gotowe`,
    [k.FOILPOWER]: `Moc folii`,
    [k.FOILWIDTH]: `Szerokość folii`,
    [k.FRENCH]: `Francuski`,
    [k.GERMAN]: `Niemiecki`,
    [k.GROSS]: `brutto`,
    [k.ELECRICITYPRICE]: `Cena prądu (PLN / kWh)`,
    [k.ENERGYCLASS]: `Klasa energetyczna budynku (kWh / m2 na rok)`,
    [k.ENTER_INSTALLATION_PARAMS]: `Podaj parametry instalacji aby obliczyć zużycie`,
    [k.FOR_CLIENT]: `Dla klienta`,
    [k.GENERAL_HEATING_COST_CALCULATOR]: `Ogólny kalkulator kosztów ogrzewania na podczerwień z klasą
  energetyczną budynku:`,
    [k.LOWENERGY]: `A Niskoenergetyczny`,
    [k.GOTOCASHREGISTER]: `Przejdź do kasy`,
    [k.HEATINGCOSTCALCULATOR]: `Kalkulator kosztów ogrzewania`,
    [k.HEATINGFOIL]: `Folia grzewcza`,
    [k.HEATINGMAT]: `Mata grzewcza`,
    [k.HOUSE]: `Dom`,
    [k.HOUSINGESTATE]: `Osiedle domów`,
    [k.INSTALLER]: `Instalator`,
    [k.I_ORDER_WITH_SHIPPING_TO_CUSTOMERS]: `Zamawiam z wysyłką do klientów`,
    [k.I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE]: `Zamawiam na magazyn własny`,
    [k.ITALIAN]: `Włoski`,
    [k.HEATING_SYSTEM_POWER]: `Moc znamionowa systemu grzewczego`,
    [k.INSTALLATIONDESIGN]: `Projekt instalacji`,
    [k.INVESTMENT_MATERIALS_COSTS]: `Koszty inwestycyjne materiałów`,
    [k.INVESTORS]: `Inwestorzy`,
    [k.INVESTMENTS]: `Inwestycje`,
    [k.KNOWLEDGEBASE]: `Baza wiedzy`,
    [k.LARGEQUANTITYINSTOCK]: `Duża ilość w magazynie`,
    [k.LEAVEPROJECT]: `Opuść projekt`,
    [k.LENGTH_IN_CM]: `Długość w cm`,
    [k.LOGIN]: `Zaloguj`,
    [k.LOG_IN]: `Zaloguj się`,
    [k.LOGOUT]: `Wyloguj`,
    [k.MANAGER]: `Menedżer`,
    [k.MARKETING]: `Marketing`,
    [k.MEDIUMENERGYSAVING]: `C Środnio energooszczędny`,
    [k.ENERGYSAVING]: `B Energooszczędny`,
    [k.MIN_PV_INSTALATION_REQUIRED]: `Wymagana minimalna instalacja fotowoltaiczna do
  zbilansowania kosztów ogrzewania`,
    [k.MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL]: `produkty i przejdź na wyższy poziom rabatu`,
    [k.NO]: `Nie`,
    [k.NODATA]: `Brak danych`,
    [k.NORWEGIAN]: `Norweski`,
    [k.ONROLLS]: `(na rolki)`,
    [k.POLISH]: `Polski`,
    [k.MONTHLY_OPERATING_COSTS]: `Miesięczne koszty eksploatacyjne`,
    [k.NET]: `netto`,
    [k.NEWROOM]: `Nowe pomieszczenie`,
    [k.OFFERGENERATING]: `Generowanie oferty`,
    [k.OFFERS]: `Oferty`,
    [k.ORDERS]: `Zamówienia`,
    [k.ONLINE_PAYMENT]: `Płatność online`,
    [k.PAYMENT_METHOD]: `Forma płatności`,
    [k.OWN]: `Własna`,
    [k.OWNER]: `Właściciel`,
    [k.PASSIVE_A_PLUS]: `A+ Pasywny`,
    [k.PHOTOVOLTAIC]: `Fotowoltaika`,
    [k.PRESENTED_CALC_ARE_INFORMATIVE]: `*Przedstawione obliczenia mają charakter informacyjny i nie stanowią
  oferty handlowej w rozumieniu Art. 66.par.1 Kodeksu Cywilnego`,
    [k.PRICELIST]: `Cennik`,
    [k.PRODUCT]: `Produkt`,
    [k.PRODUCTSINCART]: `Produkty w koszyku`,
    [k.PRODUCTAVAILABLE]: `Produkt dostępny`,
    [k.PROFIT]: `Zysk`,
    [k.PROJECTS]: `Projekty`,
    [k.PUBLICFACILITY]: `Obiekt publiczny`,
    [k.RATED_ENERGY_CONSUMPTION]: `Znamionowy pobór energii`,
    [k.REJECT_CHANGES]: `Odrzuć zmiany`,
    [k.REMIND_PASSWORD]: `Przypomnij hasło`,
    [k.REMEMBER_ME]: `Zapamiętaj mnie`,
    [k.RETURN_TO_THE_LOGIN_PANEL]: `wróć do panelu logowania`,
    [k.QUANTITY]: `Ilość`,
    [k.ROMANIAN]: `Rumuński`,
    [k.SALE]: `Promocje`,
    [k.SAVEANDCLOSE]: `Zapisz i zakończ`,
    [k.SAVECHANGES]: `Zapisz zmiany`,
    [k.SEARCHFORPRODUCTS]: `Szukaj produktów`,
    [k.SEETHEPRODUCT]: `Zobacz produkt`,
    [k.SETTINGS]: `Ustawienia`,
    [k.SHOWCROSS_SECTION]: `Pokaż przekrój`,
    [k.SHOWLAYERS]: `Pokaż warstwy`,
    [k.SLOVAK]: `Słowacki`,
    [k.SMALLQUANTITYINSTOCK]: `Mała ilość w magazynie`,
    [k.SOMETHING_WENT_WRONG]: `Coś poszło nie tak:`,
    [k.SPAIN]: `Hiszpański`,
    [k.RELOADAPPLICATION]: `Przeładuj aplikację`,
    [k.SWEDISH]: `Szwedzki`,
    [k.THERMOSTAT]: `Termostat`,
    [k.THERMOSTATFUNCTIONS]: `Funkcje termostatu`,
    [k.THERMOSTATS]: `Termostaty`,
    [k.TOGETHER]: `Razem`,
    [k.TOGETHERTOPAY]: `Razem do zapłaty`,
    [k.USABLEAREA]: `Powierzchnia użytkowa (m2)`,
    [k.ZEROENERGY]: `A++ Zeroenergetyczny`,
    [k.FLOOR]: `Podłoga`,
    [k.CEILING]: `Sufit`,
    [k.WALL]: `Ściany`,
    [k.WAREHOUSE]: `Hala magazynowa`,
    [k.WHOLESALER]: `Hurtownia`,
    [k.WT2021]: `WT2021`,
    [k.ADDROOM]: `Dodaj pomieszczenie`,
    [k.CHANGE]: `Zmień`,
    [k.YES]: `Tak`,
    [k.GOBACKTOCREATOR]: `Wróć do kreatora`,
    [k.GOBACKTOCART]: `Wróć do cennika`,
    [k.SQUAREM]: `m²`,
    [k.PIECE]: `szt.`,
    [k.RUNNINGM]: `mb.`,
    [k.COLOR]: `Kolor`,
    [k.UNDO]: `Krok wstecz`,
    [k.REDO]: `Krok w przód`,
    [k.ZOOMIN]: `Przybliż`,
    [k.ZOOMOUT]: `Oddal`,
    [k.MEASURE]: `Miara`,
    [k.CLOSEPOLYGON]: `Domykanie figury`,
    [k.CONTACTORREQUIRED]: `wymagany stycznik`,
    [k.WRONGLOGINORPASSWORD]: "Nieprawidłowy login lub hasło.",
    [k.WIDTH_IN_CM]: "Szerokość w cm",
    [k.WIDTH]: "Szerokość",
    [k.MISSINGUSER]: "Nie znaleziono użytkownika.",
    [k.PASSWORD]: "Hasło",
    [k.PASSWORDREQUIRED]: "Hasło jest wymagane.",
    [k.CONFIRMPASSWORDMUSTBESAME]: "Hasło potwierdzające musi być takie samo.",
    [k.PASSWORDSHOULDBEMIN8CHARLONG]: "Hasło musi składać się z conajmniej 8 znaków.",
    [k.PASSWORDPATTERNQUOTE]: "Hasło musi zawierać co najmniej jedną: cyfrę, wielką literę, małą literę i znak specjalny (np.: @, #, &, !, $).",
    [k.PASSWORD_HAS_BEEN_RESET]: "Hasło zostało ustawione",
    [k.CALIBRATION]: "Kalibracja",
    [k.READY]: "Gotowe",
    [k.EDIT]: "Edytuj",
    [k.START]: "Start",
    [k.UNFOLDAUTOMATICALLY]: "Rozłóż automatycznie",
    [k.CUTTINGPOINT]: "Punkt cięcia",
    [k.TYPE]: "Typ",
    [k.FURNITURE]: "Mebel",
    [k.BATH_SHOWERTRAY]: "Wanna/brodzik",
    [k.SINK]: "Umywalka/WC wiszące",
    [k.KITCHEN_FURNITURE]: "Zabudowa kuchenna",
    [k.FIREPLACE]: "Kominek",
    [k.HOUSEHOLD_APPLIANCES]: "Sprzęt AGD",
    [k.YOUR_ADDRESSES]: "Twoje adresy",
    [k.YOUR_NET_PRICE]: "Twoja cena netto",
    [k.YOUR_GROSS_PRICE]: "Twoja cena brutto",
    [k.OPTIMAL]: "Optymalnie",
    [k.VERTICALY]: "Pionowo",
    [k.HORIZONTALY]: "Poziomo",
    [k.ALLWIDTHS]: "Wszystkie szer.",
    [k.DRAWOFFAREA]: "Czy chcesz dodać elementy stałej zabudowy?",
    [k.INNER]: "Wewnętrzny",
    [k.OUTER]: "Zewnętrzny",
    [k.OTHER]: "Inne",
    [k.ROOM]: "Pokój",
    [k.BATHROOM]: "Łazienka",
    [k.BASEMENT]: "Piwnica",
    [k.GROUND_FLOOR]: "Parter",
    [k.FIRST_FLOOR]: "Piętro",
    [k.ATTIC]: "Poddasze",
    [k.STOREY]: "Kondygnacja",
    [k.LEVEL]: "Poziom",
    [k.ROOM]: "Pomieszczenie",
    [k.ADDFILE]: "Dodaj plik",
    [k.DRAWPLAN]: "Rysuj rzut",
    [k.LIVINGROOM]: "Salon",
    [k.KITCHEN]: "Kuchnia",
    [k.VESTIBULE]: "Wiatrołap",
    [k.BEDROOM]: "Sypialnia",
    [k.DININGROOM]: "Jadalnia",
    [k.WARDROBE]: "Garderoba",
    [k.TOILET]: "Toaleta",
    [k.LAUNDRY]: "Pralnia",
    [k.HALL]: "Hol",
    [k.CABINET]: "Gabinet",
    [k.ANTEROOM]: "Przedpokój",
    [k.CORRIDOR]: "Korytarz",
    [k.PANTRY]: "Spiżarnia",
    [k.LIVING_DINING_ROOM]: "Salon + jadalnia",
    [k.LIVINGROOM_WITH_KITCHENETTE]: "Salon z aneksem kuchennym",
    [k.GARAGE]: "Garaż",
    [k.TECHNICALROOM]: "Pom. techniczne",
    [k.UTILITYROOM]: "Pom. gospodarcze",
    [k.RECREATIONROOM]: "Pom. rekreacyjne",
    [k.ENTRESOL]: "Antresola",
    [k.SAUNA]: "Sauna",
    [k.BOILERROOM]: "Kotłownia",
    [k.CUBBY]: "Schowek",
    [k.STAIRS]: "Schody",
    [k.FIREPLACE_ROOM]: "Pokój kominkowy",
    [k.GO_BACK_EDIT]: "Wróć do edycji",
    [k.GO_FURTHER]: "Przejdź dalej",
    [k.DRAW_NEXT]: "Rysuj kolejny",
    [k.START_DESIGNING]: "Rozpocznij projektowanie",
    [k.IN_THE_WIZARD_HEAT_DECOR]: "w kreatorze Heat Decor",
    [k.YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS]: "W kilku prostych krokach stworzysz projekt ogrzewania na podczerwień.",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Wpisz email podany podczas zakładania konta.",
    [k.WHAT_WILL_YOU_GAIN_BY_WORKING]: "Co zyskasz pracując",
    [k.WITH_THE_HEAT_DECOR_CREATOR]: "z kreatorem Heat Decor?",
    [k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE]: "• Precyzyjne projekty systemu ogrzewania w",
    [k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY]: "standardzie Heat Decor, gotowe do zastosowania",
    [k.THE_INSTALLATION_TEAM]: "przez ekipę instalacyjną",
    [k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR]: "• Łatwiejsze i pełniejsze opracowanie oferty dla",
    [k.YOUR_CLIENT]: "Twojego klienta",
    [k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS]: "• Ułatwienie procesu zamawiania produktów",
    [k.HEAT_DECOR]: "Heat Decor",
    [k.WE_ARE_CHANGING_THE_FUTURE_FOR]: "Zmieniamy przyszłość na ",
    [k.GREEN]: "zieloną",
    [k.WATCH_HEAT_DECOR]: "Obserwuj Heat Decor",
    [k.READ_AND_SUBSCRIBE]: "czytaj i subskrybuj",
    [k.BLOG]: "blog",
    [k.PRIMARY_USER]: "Główny użytkownik:",
    [k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES]: "• Ma dostęp do wszystkich funkcjonalności kreatora.",
    [k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR]:
        "• Określenie roli w strukturze firmy usprawni dalszą współpracę z Heat Decor.",
    [k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS]: "• Określenie typu firmy pozwoli na dopasowanie kreatora do potrzeb.",
    [k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER]: "• Po rejestracji Heat Decor skontaktuje się ze wskazanym głównym użytkownikiem.",
    [k.ADDITIONAL_USERS]: "Dodatkowi użytkownicy:",
    [k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS]: "• Mogą używać kreatora do opracowania projektów i ofert dla swoich klientów.",
    [k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES]: "• Mają ograniczone możliwości edycji konta.",
    [k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD]:
        "W przypadku powtarzających się trudności z korzystaniem kreatora skontaktuj się administratorem kreatora Heat Decor:",
    [k.EMAIL]: "Email",
    [k.TELEPHONE]: "Telefon:",
    [k.PRIVACY_POLICY]: "Polityka prywatności",
    [k.WEBSITE_REGULATIONS]: "Regulamin strony",
    [k.THANK_YOU_FOR_YOUR_REGISTRATION]: "Dziękujemy za rejestrację",
    [k.LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY]: "Zalogowanie będzie możliwe po skutecznym",
    [k.SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE]: "ustawieniu hasła i weryfikacji konta przez",
    [k.ADMINISTRATOR]: "Administratora.",
    [k.YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD]: "Otrzymasz na niego link do ustawienia nowego hasła.",
    [k.EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS]: "Email został wysłany. Postępuj zgodnie z instrukcją",
    [k.IN_THE_EMAIL]: " umieszczoną w emailu.",
    [k.CHECK_EMAIL]: "Sprawdź email",
    [k.TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION]:
        "Aby dokończyć proces rejestracji sprawdź wiadomość wysłaną automatycznie na podany email i kliknij link potwierdzający rejestrację.",
    [k.REGISTRATION_ERROR]: "Wystąpił błąd rejestracji",
    [k.AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT]: "W trakcie zakładania nowego konta wystąpił błąd.",
    [k.TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US]:
        "W razie powtarzania się błędu spróbuj zarejestrować się na inny adres email lub skontaktuj się z nami.",
    [k.ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION]: "Wpisz dane głównego użytkownika konta.",
    [k.REGISTRATION]: "Rejestracja",
    [k.ADDITIONAL_USERS_OPTIONAL]: "Dodatkowi użytkownicy (opcjonalnie)",
    [k.INVESTMENT]: "Inwestycja",
    [k.INVESTORS_NAME]: "Nazwa inwestora:",
    [k.ADDED_BY]: "Dodano przez:",
    [k.NOTES]: "Notatki",
    [k.STREET]: "Ulica",
    [k.CITY]: "Miejscowość",
    [k.ZIP_CODE]: "Kod pocztowy",
    [k.GROSS]: "brutto",
    [k.NET_PRICE_FOR_THE_CLIENT]: "Cena dla klienta netto:",
    [k.NET_PROFIT]: "Zysk netto:",
    [k.DOWNLOAD_MULTIPLE_PDF_FILES]: "Pobierz wiele PDF",
    [k.DELETE_PROJECTS]: "Usuń projekty",
    [k.ADD_TO_CART]: "Dodaj do koszyka",
    [k.REMOVE_OFFERS]: "Usuń oferty",
    [k.GENEROWANIE_OFERTY]: "Generowanie oferty",
    [k.RESET_PASS]: "Resetuj hasło",
    [k.VALUE_OF_THE_OFFER]: "Wartość Oferty",
    [k.YOUR_COST]: "Twój koszt",
    [k.YOUR_PRICE]: "Twoja cena",
    [k.THE_INVESTOR_PAYS]: "Inwestor płaci",
    [k.YOU_EARN]: "Zarabiasz",
    [k.GO_ON]: "Przejdź dalej",
    [k.ENTER_NEW_PASSWORD]: "Nadaj nowe hasło",
    [k.NEW_PASSWORD]: "Nowe hasło",
    [k.PRICE]: "Cena",
    [k.PRICE_FOR_THE_CLIENT]: "Cena dla klienta:",
    [k.A_PRICE_FOR_YOU]: "Cena dla ciebie:",
    [k.INCORRECT_LOGIN_OR_PASSWORD]: "Niepoprawny email lub hasło",
    [k.OFFER]: `Oferta`,
    [k.BY]: `przez`,
    [k.LIST_OF_ORDERS]: "Lista zamówień",
    [k.THANK_YOU_FOR_YOUR_ORDER]: "Dziękujemy za zamówienie",
    [k.GO_TO_ORDERS]: "Przejdź do zamówień",
    [k.SMS_CODE]: "Kod SMS",
    [k.INCORRECT_AUTHORIZATION_CODE]: "Błędny kod autoryzujący",
    [k.ENTER_AUTHORIZATION_CODE]: "Wpisz kod autoryzujący",
    [k.CONFIRMED]: "Potwierdzone",
    [k.RESEND_CODE]: "Wyślij ponownie kod",
    [k.REENTER_THE_6_DIGIT_CODE]: "Ponów wpisanie 6 cyfrowego kodu SMS nr",
    [k.TO_VERIFY_YOUR_ACCOUNT]: "w celu weryfikacji konta",
    [k.ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT]: "Wpisz 6 cyfrowy kod SMS w celu weryfikacji konta",
    [k.NR]: "nr",
    [k.GO_TO_PAYMENT]: "Przejdź do płatności",
    [k.ORDERED_PRODUCTS]: "Zamówione produkty",
    [k.NOTES_TO_THE_ORDER]: "Uwagi do zamówienia",
    [k.NO_PRODUCTS]: "Brak produktów",
    [k.THERE_ARE_NO_PRODUCTS_IN_YOUR_CART]: "Brak produktów w koszyku",
    [k.NO_OFFERS]: "Brak ofert",
    [k.YOUR_CART]: "Twój koszyk",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER]: "Twoje zamówienie z dostawą do klienta",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE]: "Twoje zamówienie z dostawą do Twojego magazynu",
    [k.PAY_FOR_YOUR_ORDER]: "Zapłać za zamówienie",
    [k.PHONE]: "Telefon",
    [k.SHIPPING_TO]: "Wysyłka do",
    [k.SAVE]: "Zapisz",
    [k.EMAIL_IS_REQUIRED]: "Email jest wymagany",
    [k.CODE_AND_CITY_ARE_REQUIRED]: "Kod i miasto są wymagane",
    [k.POSTAL_CODE_AND_CITY]: "Kod pocztowy i miasto",
    [k.STREET_AND_BUILDING_NUMBER_ARE_REQUIRED]: "Ulica i nr budynku są wymagane",
    [k.STREET_AND_NUMBER]: "Ulica i numer",
    [k.NAME_AND_SURNAME]: "Imię i nazwisko",
    [k.DISCOUNT]: "Rabat",
    [k.SUM]: "Suma",
    [k.YOU_WANT_TO_GIVE_A_DISCOUNT_ON_ALL_MATERIAL]: "Chcesz dać rabat na cały materiał",
    [k.ALL_MATERIAL_DISCOUNT]: "Rabat na cały materiał",
    [k.SORRY_NOTHING_HERE]: "Przepraszam... nic tutaj nie ma.",
    [k.GO_HOME]: "Wróc na stronę główną",
    [k.I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN]: "Rozumiem, nie pokazuj przy następnym logowaniu",
    [k.DISTRIBUTOR]: "Dystrybutor",
    [k.GENERATE_REPORT]: "Generuj raport",
    [k.SKETCH]: "Szkic",
    [k.GENERATE_AN_OFFER]: "Generuj ofertę",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT]: "Czy na pewno chcesz usunąć wybrany projekt?",
    [k.FIND_A_PROJECT]: "Znajdź projekt",
    [k.THERE_IS_NO_SUCH_PROJECT]: "Nie ma takiego projektu",
    [k.YOU_DONT_HAVE_ANY_PROJECT_YET]: "Nie masz jeszcze żadnego projektu",
    [k.YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT]: "Musisz posiadać inwestora oraz inwestycję aby utworzyć dla niej projekt",
    [k.DO_YOU_WANT_TO_CREATE_AN_INVESTOR]: "Czy chcesz utworzyć inwestora",
    [k.GETTING_STARTED_WITH_ADDING_AN_INVESTOR]: "Rozpoczęcie pracy zaczyna się od dodania inwestora",
    [k.ADD_AN_INVESTOR]: "Dodaj inwestora",
    [k.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN]: "Coś poszło nie tak, spróbuj ponownie",
    [k.SELECT_THE_TYPE_OF_ROOM]: "Wybierz typ pomieszczenia",
    [k.GO_BACK_TO_EDITING]: "Wróć do edycji",
    [k.TITLE]: "Tytuł",
    [k.GROUP]: "Grupa",
    [k.GENERAL]: "Ogólne",
    [k.HEATING_FOIL]: "Folia grzewcza",
    [k.ELECTRIC_HEATERS]: "Grzejniki elektryczne",
    [k.HEATING_MAT]: "Mata grzewcza",
    [k.IMAGE]: "Obraz",
    [k.DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT]: "Przeciągnij i upuść obraz lub kliknij i wybierz",
    [k.PREVIEW]: "Podgląd",
    [k.TEXT_EDITOR]: "Edytor tekstu",
    [k.SAVE_CHANGES]: "Zapisz zmiany",
    [k.EXIT]: "Wyjdź",
    [k.ADD_A_NEW_COMPANY]: "Dodaj nową firmę",
    [k.GO_BACK_TO_THE_LIST]: "Wróć do listy",
    [k.FIRST_NAME]: "Imię",
    [k.LAST_NAME]: "Nazwisko",
    [k.USER]: "Użytkownik",
    [k.IS_REQUIRED]: "jest wymagane",
    [k.FIRST_NAME_IS_REQUIRED]: "Imię jest wymagane",
    [k.LAST_NAME_IS_REQUIRED]: "Nazwisko jest wymagane",
    [k.PHONE_IS_REQUIRED]: "Telefon jest wymagany",
    [k.EMAIL_IS_INVALID]: "Email jest nieprawidłowy",
    [k.ADD_USER]: "Dodaj użytkownika",
    [k.REGISTER]: "Zarejestruj",
    [k.COUNTRY]: "Kraj",
    [k.PHONE_NUMBER]: "Numer telefonu",
    [k.PREFIX_IS_REQUIRED]: "Prefix jest wymagany",
    [k.NEXT_STEP]: "Następny krok",
    [k.TAX_ID_IS_REQUIRED]: "NIP jest wymagany",
    [k.COUNTRY_IS_REQUIRED]: "Kraj jest wymagany",
    [k.TAX_ID]: "NIP",
    [k.TAX_ID_IS_INCORRECT]: "NIP jest nieprawidłowy",
    [k.ZIP_CODE_IS_REQUIRED]: "Kod pocztowy jest wymagany",
    [k.CITY_IS_REQUIRED]: "Miasto jest wymagane",
    [k.HOUSE_NUMBER_IS_REQUIRED]: "Numer domu jest wymagany",
    [k.NUMBER]: "Numer",
    [k.DOCUMENT_NUMBER]: "Numer dokumentu",
    [k.DOWNLOAD]: "Pobierz",
    [k.WAS_THIS_ARTICLE_HELPFUL]: "Czy ten artykuł był pomocny",
    [k.MIN_READING]: "min czytania",
    [k.SEE_MORE]: "ZOBACZ WIĘCEJ",
    [k.ADDRESS]: "Adres",
    [k.DEFAULT_ADDRESS]: "Adres domyślny",
    [k.NAME]: "Nazwa",
    [k.NAME_OF_THE_INVESTMENT]: "Nazwa inwestycji",
    [k.SEARCH_CRITERIA]: "Kryteria wyszukiwania",
    [k.WHO_ADDED]: "Kto dodał",
    [k.IS_IT_A_COMPANY]: "Czy to firma",
    [k.BUSINESS]: "Firma",
    [k.NATURAL_PERSON]: "Osoba fiz.",
    [k.YOU_DONT_HAVE_ANY_INVESTOR_YET]: "Nie masz jeszcze żadnego inwestora",
    [k.DO_YOU_WANT_TO_ADD_IT_NOW]: "Czy chcesz go teraz dodać",
    [k.THERE_IS_NO_SUCH_INVESTOR]: "Nie ma takiego inwestora",
    [k.YOU_DONT_HAVE_ANY_INVESTMENTS_YET]: "Nie masz jeszcze żadnych inwestycji",
    [k.ADD_YOUR_FIRST_INVESTMENT]: "Dodaj pierwszą inwestycję",
    [k.THERE_IS_NO_SUCH_INVESTMENT]: "Nie ma takiej inwestycji",
    [k.ADD_AN_INVESTMENT]: "Dodaj inwestycję",
    [k.INVESTOR]: "Inwestor",
    [k.CONTACT_PERSON]: "Osoba do kontaktu",
    [k.STREET_IS_REQUIRED]: "Ulica jest wymagana",
    [k.NO_IS_REQUIRED]: "Nr jest wymagany",
    [k.CODE_IS_REQUIRED]: "Kod jest wymagany",
    [k.THE_ENERGY_CLASS_IS_REQUIRED]: "Klasa en.jest wymagana",
    [k.CUSTOM_ENERGY_CLASS_IS_REQUIRED]: "Własna klasa en. jest wymagana",
    [k.GENERATE_OFFERS]: "Generuj oferty",
    [k.THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT]: "Dana oferta znajduje się już w koszyku, czy na pewno chcesz ją usunąć?",
    [k.COMPANY_NAME_IS_REQUIRED]: "Nazwa firmy jest wymagana",
    [k.THE_ADDRESS_IS_REQUIRED]: "Adres jest wymagany",
    [k.THE_ZIP_CODE_IS_INCORRECT]: "Kod pocztowy jest nieprawidłowy",
    [k.THE_CITY_IS_NOT_CORRECT]: "Miasto jest nieprawidłowe",
    [k.THE_HOUSE_NUMBER_IS_INCORRECT]: "Numer domu jest nieprawidłowy",
    [k.COMPANY_NAME]: "Nazwa firmy",
    [k.PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED]: "Prefix i nr telefonu są wymagane",
    [k.POSTAL_CODE_AND_CITY_ARE_REQUIRED]: "Kod i miasto są wymagane",
    [k.STREET_AND_HOUSE_NUMBER_ARE_REQUIRED]: "Ulica i nr budynku są wymagane",
    [k.FULL_NAME_IS_REQUIRED]: "Imię i nazwisko jest wymagane",
    [k.REQUIRED_FIELD]: "Pole wymagane",
    [k.REQUIRED]: "Wymagane",
    [k.ADD_A_PROJECT]: "Dodaj projekt",
    [k.PROJECT_NAME_IS_REQUIRED]: "Nazwa projektu jest wymagana",
    [k.THE_BUILDING_NUMBER_IS_REQUIRED]: "Numer budynku jest wymagany",
    [k.No]: "Nr",
    [k.ADDITIONAL_CONTACT_PERSON]: "Dodatkowa osoba do kontaktu",
    [k.CLASS_ENERGY_IS_REQUIRED]: "Klasa en. jest wymagana",
    [k.ENERGY_CLASS]: "Klasa energetyczna",
    [k.THE_STREET_IS_NOT_CORRECT]: "Ulica jest nieprawidłowa",
    [k.HOUSE_NUMBER]: "Numer domu",
    [k.TYPE_OF_INVESTMENT]: "Rodzaj inwestycji",
    [k.CODE]: "Kod",
    [k.THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN]: "Inwestycja nie została dodana. Spróbuj ponownie",
    [k.COMPANY_TYPE]: "Typ firmy",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE]: "Telefon w celu weryfikacji konta kodem SMS",
    [k.GRAPHICS]: "Grafika",
    [k.GO_BACK_TO_LOGIN]: "Wróć do logowania",
    [k.ADD_A_NEW_INVESTMENT]: "Dodaj nową inwestycję",
    [k.GO_BACK_TO_THE_LIST_OF_INVESTMENTS]: "Wróć do listy inwestycji",
    [k.ADD_A_NEW_INVESTOR]: "Dodaj nowego inwestora",
    [k.COMPLETE_THE_INVESTOR_PROFILE]: "Uzupełnij profil inwestora",
    [k.GO_BACK_TO_INVESTORS]: "Wróć do inwestorów",
    [k.PROJECT_NAME]: "Nazwa projektu",
    [k.WHAT_DO_YOU_WANT_TO_DESIGN]: "Co chcesz projektować",
    [k.UPLOAD_THE_FILE_WITH_THE_VIEW]: "Wgraj plik z rzutem",
    [k.ADD_FILE]: "DODAJ PLIK",
    [k.DISCARD_CHANGES]: "Odrzuć zmiany",
    [k.DRAW_ANY_ROOM_PLAN]: "Narysuj dowolny rzut pomieszczeń",
    [k.GENERATE_AS_IMAGE]: "Generuj jako obraz",
    [k.DRAW_PLAN]: "RYSUJ RZUT",
    [k.CREATE_A_PROJECT]: "Stwórz projekt",
    [k.CREATE_A_THROW_IN_THE_WIZARD]: "Stwórz rzut w kreatorze",
    [k.COME_BACK]: "Wróć",
    [k.HEATING_MAT_UNDER_THE_TILES]: "Mata grzewcza pod płytki",
    [k.HEATING_FOIL_UNDER_THE_SPOUT]: "Folia grzewcza pod wylewkę",
    [k.HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS]: "Folia grzewcza pod panele winylowe SPC",
    [k.HEATING_FOIL_FOR_LAMINATED_PANELS]: "Folia grzewcza pod panele laminowane",
    [k.SELECT_A_HEATING_SYSTEM]: "Wybierz system grzewczy",
    [k.GIVE_THE_REAL_DIMENSION]: "PODAJ REALNY WYMIAR",
    [k.ENTER_THE_REAL_LENGTH]: "Podaj realną długość",
    [k.DESIGNATED_EPISODE]: "wyznaczonego odcinka",
    [k.SEE_PREVIEW]: "Zobacz podgląd",
    [k.ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT]: "Dopasuj rzut przycinając go i obracając",
    [k.THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA]: "Brak produktów dla podanych kryteriów",
    [k.CHOOSE_A_MODEL]: "WYBIERZ MODEL",
    [k.PLN_GROSS]: "zł brutto",
    [k.FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM]: "W celu kalibracji zaznacz najdłuższy bok pomieszczenia",
    [k.STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM]: "Krok 1: Wykonaj obrys pomieszczenia",
    [k.SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT]: "Wybierz rodzaj stałej zabudowy",
    [k.STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION]: "Krok 2: Nanieś powierzchnie wyłączone pod stałą zabudowę",
    [k.ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL]: "Dodaj warstwę folii paroizolacyjnej",
    [k.PLACE_THE_THERMOREGULATOR]: "Umieść termoregulator na krawędzi pomieszczenia",
    [k.SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING]: "Wybierz klasę energetyczną budynku",
    [k.THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD]: "Projekt posiada niezapisane zmiany. Na pewno chcesz opuścić kreator?",
    [k.DO_YOU_WANT_TO_SAVE_THE_CHANGES]: "Czy chcesz zapisać zmiany?",
    [k.CONFIRM_NEW_PASSWORD]: "Potwierdź nowe hasło",
    [k.SET_A_PASSWORD]: "Ustaw hasło",
    [k.THE_PHONE_IS_NOT_VALID]: "Telefon jest nieprawidłowy",
    [k.MANAGE_ADDRESSES]: "Zarządzaj adresami",
    [k.PLEASE_SELECT_A_SHIPPING_ADDRESS]: "Wybierz adres do wysyłki",
    [k.ADD_A_COMPANY]: "Dodaj firmę",
    [k.SEARCH_FOR_A_COMPANY]: "Szukaj firmy",
    [k.MESSAGE]: "Wiadomość",
    [k.NO_EMAIL_ADDRESS]: "Brak adresu email",
    [k.EDIT_CUSTOMER_DETAILS]: "Edytuj dane klienta",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY]: "Czy na pewno chcesz usunąć daną firmę?",
    [k.RULER_CALIBRATION]: "Kalibracja linijki",
    [k.CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW]: "Kalibruj narzędzie, aby nauczyć program przeliczania odległości dla swojego rzutu",
    [k.SEE_AN_EXAMPLE]: "Zobacz przykład",
    [k.CALCULATE_YOUR_HEATING_COSTS]: "Oblicz koszty ogrzewania",
    [k.THE_AREA_OF_THE_ROOM]: "Powierzchnia pomieszczenia",
    [k.PERMANENT_BUILDINGS]: "Stała zabudowa",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM]: "Czy na pewno chcesz usunąć pomieszczenie?",
    [k.THERMOREGULATORS]: "Termoregulatory",
    [k.FLOOR_SENSOR]: "Czujnik podłogowy",
    [k.INDOOR_AIR_SENSOR]: "Czujnik powietrza wewn.",
    [k.OUTSIDE_AIR_SENSOR]: "Czujnik powietrza zewn.",
    [k.OPTIONAL]: "Opcjonalnie",
    [k.PROGRAMMING]: "Programowanie",
    [k.LEVEL_OF_SECURITY]: "Stopień ochrony",
    [k.CONTROL_PANEL]: "Panel sterowania",
    [k.TOUCH]: "Dotykowy",
    [k.KEYBOARD]: "Klawiatura",
    [k.KNOB]: "Gałka",
    [k.FIND_OUT_MORE]: "Dowiedz się więcej",
    [k.CHOOSE]: "Wybierz",
    [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART]: "Czy na pewno chcesz usunąć z koszyka ten produkt?",
    [k.FILTER]: "FILTRUJ",
    [k.CUTTING]: "Cięcie",
    [k.POWER]: "Moc",
    [k.MAX_TEMP]: "Max temp.",
    [k.AREA]: "Powierzchnia",
    [k.HEATING_MAT_POWER]: "Moc maty grzewczej",
    [k.HEIGHT]: "Wysokość",
    [k.THICKNESS]: "Grubość",
    [k.PRODUCT_PARAMETERS]: "PARAMETRY PRODUKTU",
    [k.LENGTH]: "Długość",
    [k.HEATING_POWER]: "Moc grzewcza",
    [k.FOIL_SURFACE]: "Powierzchnia folii",
    [k.MAX_HEATING_TEMPERATURE]: "Max temp. grzania",
    [k.THE_WIDTH_OF_THE_FILM]: "Szerokość folii",
    [k.POSSIBILITY_TO_CUT_THE_FILM_CO]: "Możliwość cięcia folii co",
    [k.MAT_AREA]: "Powierzchnia maty",
    [k.THERMOSTAT_MODEL]: "Model termostatu",
    [k.RESISTANCE]: "Rezystancja",
    [k.FOIL_MODEL]: "Model folii",
    [k.BRAND]: "Marka",
    [k.WEIGHT]: "Waga",
    [k.FOIL_GUARANTEE]: "Gwarancja folia",
    [k.CONNECTION_KITS_PCS]: "Zestawy przyłączeniowe [szt.]",
    [k.WIRES]: "Przewody",
    [k.TAPE_PCS]: "Taśma [szt]",
    [k.THERMOSTAT_WARRANTY]: "Gwarancja termostat",
    [k.CONNECTOR]: "Konektor",
    [k.MAT_GUARANTEE]: "Gwarancja mata",
    [k.PRO_TAPE_PCS]: "Taśma PRO [szt.]",
    [k.PRODUCT_DESCRIPTION]: "Opis produktu",
    [k.RETURN_TO_THE_KNOWLEDGE_BASE]: "Wróć do bazy wiedzy",
    [k.SIMILAR_ARTICLES]: "Podobne artykuły",
    [k.NUMBER_OF_INVESTMENTS]: "Ilość Inwestycji",
    [k.VIEW_PROFILE]: "Zobacz profil",
    [k.LATEST_INVESTMENTS]: "Najnowsze inwestycje",
    [k.THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT]: "Brak produktów w projekcie",
    [k.BILL_OF_MATERIALS_FOR_PROJECTS]: "Zestawienie materiałów dla projektów",
    [k.YOUR_CALCULATION_WITHOUT_ASSEMBLY]: "TWOJA KALKULACJA BEZ MONTAŻU",
    [k.YOU_WANT_TO_ADD_A_MONTAGE]: "Chcesz dodać montaż",
    [k.NUMBER_OF_KILOMETERS]: "Ilość kilometrów",
    [k.PRICE_FOR_ASSEMBLY]: "Cena za montaż",
    [k.YOUR_CALCULATION_WITH_ASSEMBLY]: "TWOJA KALKULACJA Z MONTAŻEM",
    [k.CHECK_WHAT_RATE_YOU_ARE_AT]: "Sprawdź na jakiej stawce jesteś",
    [k.SHOW_HEATING_COSTS]: "Pokaż koszty ogrzewania",
    [k.USABLE_AREA]: "Powierzchnia użytkowa",
    [k.SELECTED_ENERGY_CLASS]: "Wybrana klasa energetyczna",
    [k.THE_PRICE_OF_ELECTRICITY]: "Cena prądu",
    [k.HEATING_PRICE_IN_KWH_YEAR]: "Cena ogrzewania w kWh/rok",
    [k.SAVE_AND_GENERATE_AN_OFFER]: "Zapisz i generuj ofertę",
    [k.NUMBER_OF_PROJECTS]: "Ilość projektów",
    [k.GO_TO_INVESTMENT]: "Przejdź do inwestycji",
    [k.LATEST_PROJECTS]: "Najnowsze projekty",
    [k.ASSEMBLY]: "Montaż",
    [k.START_TYPING]: "Zacznij pisać",
    [k.ORDERS_PLACED]: "Złożone zamówienia",
    [k.NO_ORDERS_PLACED]: "Brak złożonych zamówień",
    [k.SUBMIT]: "Wyślij",
    [k.SAYS]: "mówi",
    [k.ROTATE]: "Obróć",
    [k.SCALE]: "Skala",
    [k.ADD_A_NEW_PROJECT]: "Dodaj nowy projekt",
    [k.GO_BACK_TO_PROJECTS]: "Wróć do projektów",
    [k.SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED]: "Wybierz stronę, na której znajduje się rzut",
    [k.STEP]: "Krok",
    [k.CREATED_BY]: "stworzona przez",
    [k.INVESTOR_NAME]: "Nazwa inwestora",
    [k.YOU_DONT_HAVE_ANY_OFFER_YET]: "Nie masz jeszcze żadnej oferty",
    [k.CURRENT_VIEW_CREATOR_SAVED]: `Po naciśnięciu przycisku "ZAPISZ I ZAKOŃCZ" aktualny widok z kreatora zostanie zapisany do oferty PDF. W pozostałych przypadkach widok nie zostanie zapisany.`,
    [k.ENTER_DWG_FILE_SIZE]: `Wpisz rozmiar pliku DWG.`,
    [k.CREATED_BY2]: `Utworzono przez`,
    [k.DO_YOU_WANT_TO_SAVE_CHANGES]: `Czy chcesz zapisać zmiany?`,
    [k.NUMBER_OF_ROOMS]: `Ilość pomieszczeń`,
    [k.FOIL]: "Folia",
    [k.MAT]: "Mata",
    [k.NETTO_PRICE]: "Cena netto",
    [k.QUANTITY_PCS_M]: "Ilość szt/mb",
    [k.NET_VALUE]: "Wartość netto",
    [k.MATERIAL_COST]: "Koszt materiału",
    [k.INVESTOR_COST]: "Koszt inwestora",
    [k.ADD_NOTE]: "Dodaj notatkę",
    [k.REMOVE_PROJECT_FROM_OFFER]: "Usuń projekt z oferty",
    [k.COVERING_AREA_OF_ROOM]: "Pokrycie powierzchni pomieszczenia",
    [k.SEE_ASSORTMENT]: "Zobacz asortyment",
    [k.LP]: "Lp.",

    [k.SUMMARY]: "Podsumowanie",
    [k.THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION]: "Zamówienie zostało przekazane do realizacji",
    [k.THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB]: "Status zamówienia można śledzić w zakładce Zamówienia",
    [k.THANK_YOU_FOR_YOUR_TRUST]: "Dziękujemy  za zaufanie",

    [k.TO_PAY]: "Do zapłaty",
    [k.SHIPPING_TO_YOUR_OWN_WAREHOUSE]: "Wysyłka na własny magazyn",
    [k.SHIPPING_TO_THE_CUSTOMER]: "Wysyłka do klienta",
    [k.YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER]:
        "Otrzymałeś dodatkowy rabat wynikający z połączenia dwóch inwestycji w jedno zamówienie",
    [k.SHIPPING_ADDRESS]: "Adres do wysyłki",
    [k.ADDRESSEE]: "Adresat",
    [k.WAREHOUSE_ADDRESS]: "Adres Magazynu",
    [k.CONTACT]: "Kontakt",
    [k.CHOOSE_PAYMENT_METHOD]: "Wybierz metodę płatności",
    [k.TRADITIONAL_BANK_TRANSFER]: "Przelew tradycyjny",
    [k.PAYMENT_DEADLINE]: "Termin płatności",
    [k.CASH_ON_DELIVERY]: "Za pobraniem",
    [k.INVESTMENT_DATA]: "Dane inwestycji",
    [k.DELIVERY_ADDRESS]: "Adres dostawy",
    [k.LIST_OF_PRODUCTS]: "Lista produktów",
    [k.PRODUCT_NAME]: "Nazwa produktu",
    [k.AMOUNT_OF_PRODUCTS]: "Ilość produktów",
    [k.NET_PRICE]: "Cena netto",
    [k.GROSS_PRICE]: "Cena brutto",
    [k.INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST]:
        "Info: Jeśli chcesz dodać materiał rezerwowy do inwestycji, kliknij edytuj w prawyn górnym rogu listy produktów.",
    [k.INVESTMENT_ADDRESS]: "Adres inwestycji",
    [k.BANK_TRANSFER_DETAILS]: "Dane do przelewu",
    [k.RECIPIENT]: "Odbiorca",
    [k.ACCOUNT_NUMBER]: "Numer konta",
    [k.DOWNLOAD_PDF]: "Pobierz PDF",
    [k.GO_TO_ORDER]: "Przejdź do zamówienia",
    [k.CHOOSE_A_BANK]: "Wybierz bank",
    [k.THE_VALUE_OF_THE_MATERIALS]: "Wartość materiałów",
    [k.SHOPPING_CART_ITEMS]: "Elementy koszyka",
    [k.INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER]: "Info: Aby edytować adres wysyłki należy kliknąć w ołówek w prawym górnym rogu",
    [k.NO_ORDERS]: "Brak zamówień",
    [k.STATUS_OF_THE_ORDER]: "Status zamówienia",
    [k.DATE]: "Data",
    [k.SHIPPING_NO]: "Nr przewozowy",
    [k.DOWNLOAD_THE_INVOICE]: "Pobierz fakturę",
    [k.MORE_INFORMATION]: "Więcej informacji",
    [k.SHIPPING_INFORMATION]: "Dane do wysyłki",
    [k.ORDER_STATUS]: "Status zamówienia",
    [k.CONTACT_PERSON2]: "Osoba kontaktowa",
    [k.ORDER_NOTES]: "Uwagi do zamówienia (opcjonalne)",
    [k.QUOTA]: "Kwota",
    [k.GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT]: "Rozpoczęcie pracy nad projektem zaczyna się od dodania inwestycji",
    [k.DUPLICATE]: "Powiel",
    [k.ROOMS]: "Pomieszczenia",
    [k.COMPLETED_OFFERS]: "Zrealizowane oferty",
    [k.NO_PROJECTS]: "Brak projektów",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS]: "Czy na pewno chcesz usunąć wybrane projekty?",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS]: "Czy na pewno chcesz usunąć wybrane oferty?",
    [k.COMPANY]: "Firma",
    [k.YOUR_ADVANTAGE_IN_ASSEMBLY]: "Twoja korzyść na montażu",
    [k.YOUR_BENEFIT_ON_THE_MATERIAL]: "Twoja korzyść na materiale",
    [k.ADDED_TO_CART]: "Dodano do koszyka",
    [k.CREATED]: "Utworzone",
    [k.PENDING]: "W oczekiwaniu",
    [k.COMPLETE]: "Ukończone",
    [k.REJECTED]: "Odrzucone",
    [k.GO_TO_OFFERS]: "Przejdź do ofert",
    [k.DONE_WITH_INSTALLATION]: "Wykonane z montażem",
    [k.DONE_WITHOUT_INSTALLATION]: "Wykonane bez montażu",
    [k.THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED]: "Nazwa inwestycji jest wymagana",
    [k.INVESTOR_DATA]: "Dane inwestora",
    [k.USERS]: "Użytkownicy",
    [k.RULES]: "Reguły",
    [k.CONTACT_DETAILS]: "Dane kontaktowe",
    [k.THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER]: "Logo wykorzystywane będzie na ofercie oraz na fakturach dla klienta",
    [k.UPLOAD_THE_FILE]: "Wgraj plik",
    [k.UPLOAD_THE_LOGO_FILE]: "Wgraj plik z logo",
    [k.MAX_SIZE]: "Maks. wielkość",
    [k.MAX_DIMENSIONS]: "Maks. wymiary",
    [k.CURRENT_FILE]: "Aktualny plik",
    [k.NO_PHOTO]: "Brak zdjęcia",
    [k.DELETE_FILE]: "Usuń plik",
    [k.WIZARD_LANGUAGE]: "Język kreatora",
    [k.COMPANY_LOGO]: "Logo firmy",
    [k.MAIN_USER]: "Użytkownik główny",
    [k.OTHER_USERS]: "Pozostali użytkownicy",
    [k.ADD_NEW_PROFILE]: "dodaj nowy profil",
    [k.CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM]: "Zostały naniesione zmian, czy chcesz je zapisać?",
    [k.DISCARD]: "Odrzuć",
    [k.POSITION_OPTIONAL]: "Stanowisko (opcjonalnie)",
    [k.LOCK_PROFILE]: "zablokuj profil",
    [k.DELETE_PROFILE]: "usuń profil",
    [k.DO_SAVE_CHANGES]: "Czy zapisać zmiany?",
    [k.POSITION]: "Stanowisko",
    [k.THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED]: "Nazwa klasy energetycznej jest wymagana",
    [k.THE_NAME_OF_THE_ENERGY_CLASS]: "Nazwa klasy energetycznej",
    [k.EU_DEMAND]: "Zapotrzebowanie EU",
    [k.EU_REQUIREMENT_IS_REQUIRED]: "Zapotrzebowanie EU jest wymagane",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT]: "System grzewczy pod wylewką",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED]: "System grzewczy pod wylewką jest wymagany",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "System grzewczy pod wylewką dla pomieszczeń: łazienka, toaleta, pralnia, sauna.",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]:
        "System grzewczy pod wylewką dla pomieszczeń: łazienka, toaleta, pralnia, sauna jest wymagany",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL]: "System grzewczy pod panelem",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED]: "System grzewczy pod panelem jest wymagany",
    [k.REMOVE_ENERGY_CLASS]: "usuń klasę energetyczną",
    [k.TABLE_OF_ENERGY_CLASSES]: "Tabela klas energetycznych",
    [k.EU_DEMAND_EVERY_KWH]: "Zapotrzebowanie EU co KWh/m2*rok",
    [k.SYSTEM_UNDER_THE_SPOUT]: "System pod wylewką",
    [k.SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "System pod wylewką dla pomieszczeń (łazienka, toaleta, pralnia, sauna)",
    [k.SYSTEM_UNDER_THE_PANEL]: "System pod panelem",
    [k.INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS]: "Info: Istnieje możliwość dodania własnych ustawień klasy energetycznej.",
    [k.ADD_YOUR_OWN_ENERGY_CLASS]: "Dodaj własną klasę energetyczną",
    [k.ASSEMBLY_RULE]: "Reguła montażu",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM]: "Uwaga! Kwota netto jest liczona od powierzchni użytkowej pomieszczenia (obrys).",
    [k.HEAT_DECOR_RECOMMENDATION]: "Rekomendacja Heat Decor",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT]: "Uwaga! Kwota netto jest liczona od powierzchni maty grzewczej.",
    [k.VAT_RULE]: "Reguła VAT",
    [k.ACTIVE_VAT_RULES]: "Aktywne reguły VAT",
    [k.ADD_A_NEW_USER]: "Dodaj nowego użytkownika",
    [k.UNLOCK_PROFILE]: "odblokuj profil",
    [k.NET_PRICE_PER_M2]: "Cena netto za m2",
    [k.SYSTEM_FOR_TILES]: "System pod płytki",
    [k.INSULATION_MAT]: "mata izolacyjna 5 mm",
    [k.XPS_EXTRUDED_POLYSTYRENE]: "styropian ekstrudowany XPS 6 mm",
    [k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY]: "System pod panele (podkład XPS 6 mm)",
    [k.SYSTEM_FOR_PANELS_5_MM_BASE]: "System pod panele (podkład 5 mm)",
    [k.THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM]: "Minimalna wartość nie może być większa od maksymalnej",
    [k.UNDER_LAMINATED_PANELS]: "pod panele laminowane",
    [k.UNDER_THE_SPOUT]: "pod wylewkę",
    [k.UNDER_SPC_VINYL_PANELS]: "pod panele winylowe SPC",
    [k.PRODUCT_TAILORED_TO_YOUR_SYSTEM]: "produkt dopasowany do Twojego systemu",
    [k.YEARS_WARRANTY]: "lat gwarancji",
    [k.THERMOREGULATOR]: "Termoregulator",
    [k.INTERNAL_AIR_SENSOR]: "Czujnik powietrza wewnętrzny",
    [k.EXTERNAL_AIR_SENSOR]: "Czujnik powietrza zewnętrzny",
    [k.CONNECTION]: "Połączenie",
    [k.HAS]: "Posiada",
    [k.DOES_NOT_HAVE]: "Nie posiada",
    [k.WHITE]: "Biały",
    [k.BLACK]: "Czarny",
    [k.MANUAL]: "Manualny",
    [k.INFO_ZIGBEE]: "Info: *Do projektu dodana zostanie centrala ZigBee 3.0 niezbędna do działania systemu. Obsługuje do 50 urządzeń.",
    [k.SKIP]: "Pomiń",
    [k.ATTENTION]: "Uwaga!",
    [k.ERROR]: "Błąd",
    [k.INFO]: "Info",
    [k.DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS]: "Czy chcesz dodać kolejny elementy stałej zabudowy?",
    [k.RECTANGLE]: "Prostokąt",
    [k.LINE]: "Linia",
    [k.ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED]: "Naliczył się dodatkowy rabat!",
    [k.IRREGULAR_SHAPE]: "Nieregularny kształt",
    [k.VAPOR_BARRIER_FILM]: "Folia paroizolacyjna",
    [k.INSULATING_UNDERLAY]: "Podkład izolacyjny",
    [k.WIRES_BROWN]: "Przewody - brązowy",
    [k.WIRES_BLUE]: "Przewody - niebieski",
    [k.CONNECTORS_FOR_HEATING_FOIL]: "Konektory dla folii grzewczej (typ A lub B)",
    [k.SELF_FUSING_TAPE]: "Taśma samowulkanizująca",
    [k.HEAT_DECOR_ADHESIVE_TAPE]: "Taśma klejąca Heat Decor",
    [k.ARRANGEMENT]: "Ułożenie",
    [k.DRAG]: "Przeciągnij",
    [k.DELETE_ROOM]: "Usuń pomieszczenie",
    [k.INCORRECT_FORMAT]: "Niepoprawny format",
    [k.USE_HAND]: "Przesuń widok",
    [k.FILE_TYPE_MUST_BE]: "Zły typ pliku. Plik musi być typu: image/jpeg, image/png, .pdf, image/gif, .dwg image/jpeg, image/png, .pdf, image/gif lub .dwg",
    [k.THERE_IS_NO_SUCH_OFFER]: "Nie ma takiej oferty",
    [k.COPY_DATA]: "Kopiuj dane",
    [k.DELETE_THERMOSTAT]: `Usuń termostat`,
    [k.SHOWER_BATH__WASHING_MACHINE]: `Prysznic/wanna/pralka`,
    [k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL]: `Umywalka stojąca/muszla stojąca`,
    [k.WALL_HUNG_WASHBASIN__HUNG_BOWL]: `Umywalka wisząca/muszla wisząca`,
    [k.SEWAGE_GRATE]: `Kratka kanalizacyjna`,
    [k.SELECT_POINTS_TO_CREATE_A_FIGURE]: `Zaznacz punkty, aby utworzyć figurę`,
    [k.STAINLESS_STEEL_MESH]: "Siatka ze stali nierdzewnej",
    [k.GROUNDING_WIRE]: "Przewód uziemiający",
    [k.CONNECTORS_FOR_STAINLESS_STEEL_MESH]: "Konektory dla siatki ze stali nierdzewnej (typ A lub B)",
    [k.SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS]: "Taśma samowulkanizująca dla konektorów do folii grzewczej",
    [k.REINFORCED_TAPE]: "Taśma zbrojona",
    [k.INSTALLATION_BOX]: "Puszka instalacyjna",
    [k.PROTECTIVE_CONDUIT]: "Peszel ochronny",
    [k.CHAT]: "Czat",
    [k.CITY]: "Miejscowość",
    [k.INPUTENERGY]: "Klasa energetyczna",
    [k.LISTOFINVEST]: "Lista inwestycji",
    [k.PROJECTSSTATUS]: "Status projektu",
    [k.FLOORAREA]: "Metraż",
    [k.COMPLETENOTES]: "Uzupełnij notatki",
    [k.TEXTADDINVEST]:
        "Inwestor w Kreatorze Heat Decor to osoba fizyczna lub prawna, która zamierza zakupić materiały niezbędne do zainstalowania nowoczesnego systemu grzewczego i opcjonalnie zlecić jego montaż. Dane identyfikujące Inwestora to numer NIP, nazwa w przypadku osoby prawnej oraz imię i nazwisko w przypadku osoby fizycznej, oraz dane kontaktowe.",
    [k.IMPORTANT]: "Ważne!",
    [k.TEXTADDINVEST2]: "Miejscem zamieszkania lub prowadzenia działalności przez Inwestora musi być Polska.",
    [k.INVESTORPROFILTEXT]: "Dla tego inwestora nie ma żadnej inwestycji",
    [k.INVESTORPROFILTEXT2]: "Czy chcesz ją teraz dodać?",
    [k.INVESTORPROFILTEXT3]: "Rozpoczęcie pracy nad projektem zaczyna się od dodania inwestycji",
    [k.HEATING_SYSTEM]: "System grzewczy",
    [k.SYSTEMCOMPONENTS]: "Elementy systemu",
    [k.MODIFY_THE_PERMANENT_STRUCTURE]: "Modyfikuj stałą zabudowę",
    [k.ACCESSORIES]: "Akcesoria",
    [k.DATE_CREATED]: "Data utworzenia",
    [k.COPY_OFFER]: "Kopiuj ofertę",
    [k.UNDO]: "COFNIJ",
    [k.FILL_IN_THE_DETAILS]: "Uzupełnij dane osoby do kontaktu",
    [k.E_G]: "np. Architekt",
    [k.ADD_INVEST_TEXT]:
        "Inwestycja w Kreatorze Heat Decor to ogół pomieszczeń w budynku, w których planowany jest nowoczesny system grzewczy. Dane identyfikujące Inwestycję to wgrane do Kreatora rzuty poszczególnych pomieszczeń czy kondygnacji, powierzchnia użytkowa poszczególnych pomieszczeń, oznaczone strefy bezpieczeństwa, w których nie wolno instalować elektrycznego ogrzewania podłogowego, oznaczone miejsca ze stałą zabudową, która nie posiada nóżek o wysokości minimum 3 cm, zapotrzebowanie na energię potrzebną do ogrzewania wynikające z termoizolacyjności budynku (EUco) oraz dane adresowe.",
    [k.COMPLATE_THE_INVEST]: "Uzupełnij dane inwestycji",
    [k.DISCOUNT_VALUE]: "Wartość rabatu",
    [k.ADD_MONTAGE]: "Dodaj montaż",
    [k.MOUNTING_SYSTEM]: "System do montażu",
    [k.COST_OF_INSTALL]: "Koszt usługi montażu",
    [k.NOTE_CHECK]: "Uwaga! Sprawdź jak masz ustawioną regułę montażu",
    [k.VAT_RATE]: "Stawka VAT",
    [k.NOTE_CHECK_VAT]: "Uwaga! Sprawdź na jakiej stawce VAT jesteś!",
    [k.HEATING_COSTS]: "Koszty ogrzewania",
    [k.ADD_HEATING_COSTS]: "Dodaj koszty ogrzewania",
    [k.RESET]: "Resetuj",
    [k.RETURN_TO_OFFERS]: "WRÓĆ DO OFERT",
    [k.LACK_OF_ROOMS]: "Brak pomieszczeń",
    [k.DRAW_A_ROOM]: "Narysuj pomieszczenie",
    [k.HEATING_FOIL_UNDER_THE_LAYERED]: "Folia grzewcza pod deskę warstwową",
    [k.CONFIGURATION_OF_PRICE]: "Konfiguracja ceny za 1 km",
    [k.SALEOFFER]: "Oferta sprzedaży",
    [k.COSTOFINSTALLSERVICE]: "Koszt usługi montażowej",
    [k.COSTOFALLMATERIALS]: "Koszt wszystkich materiałów",
    [k.LISTOFMATERIALS]: "Koszt wszystkich materiałów",
    [k.MATERIAL]: "Materiał",
    [k.NO]: "Brak",
    [k.MANUFACTUREROFHEATING]: "Producent folii grzewczej i mat grzewczych: Heat Decor",
    [k.VISUALIZATION]: "Wizualizacja systemu grzewczego",
    [k.VISUALIZATIONOFTHEHEATING]: "Wizualizacja systemu grzewczego stanowi załącznik do niniejszej oferty.",
    [k.LOGODISTRIBUTOR]: "Logo dystrybutora",
    [k.VISUALIZATIONMADE]: "Wizualizacja wykonana  przez",
    [k.AREAOFTHEHEATING]: "Powierzchnia foli grzewczej",
    [k.POWEROFTHEHEATING]: "Moc foli grzewczej",
    [k.ELECTRICALCONNECTION]: "Przyłącze elektryczne",
    [k.ESTIMATEDANNUAL]: "Szacunkowy roczny koszt eksploatacji",
    [k.ENERGYCLASSSOLO]: "Klasa energetyczna",
    [k.CHANGETHEPOWER]: "Zmień moc folii",
    [k.GERMAN]: "Niemiecki",
    [k.ROMANIAN]: "Rumuński",
    [k.HUNGARIAN]: "Węgierski",
    [k.RODO]: "Rodo dystrybutora",
    [k.RODOTEXT]:
        "Heat Decor sp. z o.o. nie ponosi odpowiedzialności za projekt/zestawienie stworzone przez Użytkownika, w tym dobór rodzaju systemu grzewczego do pomieszczenia, dobór jego mocy i materiałów. Użytkownik ponosi  odpowiedzialności i ryzyko za wybór, ew. nieprawidłowe działanie i efektywność skonfigurowanego przez siebie systemu grzewczego. ",
    [k.RODOHD]: "Rodo Heat Decor",
    [k.SCOPEOFRESPONSIBILITY]: "Zakres odpowiedzialności",
    [k.ATTACHMENTS]: "Załączniki",
    [k.ATTACHMENTSTEXT]: "Oferta jest kompletna wraz z załącznikiem zawierającymi wizualizację systemu grzewczego w poszczególnych pomieszczeniach.",
    [k.ANNOTATIONS]: "Adnotacje do oferty",
    [k.THANKYOU]: "Dziękujemy",
    [k.THANKYOUTEXT]: "za zainteresowanie nowoczesnym systemem grzewczym.",
    [k.THANKYOUQUESTION]: "W przypadku pytań prosimy o kontakt z osobą ofertującą.",
    [k.OFFERINGPERSON]: "Osoba ofertująca",
    [k.OFFERINGCOMPANY]: "Firma ofertująca",
    [k.RECOMMENDMODERN]: "Polecamy nowoczesne systemy grzewcze Heat Decor",
    [k.MAINTENANCE]: "bezserwisowe",
    [k.COMPLETLY]: "całkowicie",
    [k.SILENT]: "ciche",
    [k.FRIENDLY]: "przyjazne",
    [k.ENVIRONMENT]: "dla środowiska",
    [k.WITHINTELLIGENT]: "z inteligentnym",
    [k.CONTROL]: "sterowaniem",
    [k.VISUALIZATIONOFTHEDISTRIBUTION]: "Wizualizacja rozłożenia",
    [k.HEATINGSYSTEM]: "systemu grzewczego",
    [k.TOTALPOWER]: "Łączna moc systemów",
    [k.HEATINGFLOORS]: "grzewczych kondygnacji",
    [k.LEGEND]: "Legenda",
    [k.PERMANENT_BUILDINGS2]: "zabudowa stała",
    [k.CAPACITYOFHEATING]: "MOCE SYSTEMÓW GRZEWCZYCH W POSZCZEGÓLNYCH POMIESZCZENIACH",
    [k.NAMEOFROOM]: "Nazwa pomieszczenia",
    [k.SYSTEMTYPE]: "Typ systemu",
    [k.AREACOVERAGE]: "Pokrycie powierzchni [ % ]",
    [k.POWERPER]: "Moc na (m2) [ W/(m2) ]",
    [k.SYSTEMPOWER]: "Moc systemu w pomieszczeniu [ W ]",
    [k.HEATINGCIRCUIT]: "Rezystancja obwodu grzewczego [ \u03A9 ]",
    [k.CONTACTOR]: "* - do pomieszczenia wymagany jest stycznik",
    [k.OFFERNO]: "OFERTA NR",
    [k.DEVELOPMENT]: "Opracowanie",
    [k.BASEMENTPLAN]: "Rzut Piwnicy",
    [k.FLOORPLAN]: "Rzut Piętra",
    [k.ATTICPLAN]: "Rzut Piętra",
    [k.STOREYPLAN]: "Rzut Kondygnacji",
    [k.LEVELPLAN]: "Rzut poziomu",
    [k.ROOMPLAN]: "Rzut pomieszczenia",
    [k.GROUNDFLOORPLAN]: "Rzut Parteru",
    [k.NO_THERMOSTAT]: "Brak termostatu",
    [k.EXTERNAL_SENSOR]: "Zewnętrzny czujnik",
    [k.APPLY_DISCOUNT_TO_ALL_PRODUCTS]: "Zastosuj rabat do wszystkich produktów",
    [k.INVESTOR_PRICE_BELOW_COST]: "Uwaga! Cena dla inwestora jednego z produktów jest poniżej Twojej ceny zakupu!",
    [k.ENTER_MESSAGE]: "Wpisz wiadomość",
    [k.CURRENCY]: "Waluta",
    [k.POLISH_ZLOTY]: "Złoty polski (PLN)",
    [k.EURO]: "Euro (EUR)",
    [k.CZECH_KORUNA]: "Korona czeska (CZK)",
    [k.BRITISH_POUND]: "Funt brytyjski (GBP)",
    [k.CURRENCY_IS_REQUIRED]: "Waluta jest wymagana",
    [k.EXPIRATIONDATE]: "Data wygaśnięcia",
    [k.SELECT_DISTRIBUTOR]: "Wybierz dystrybutora",
    [k.SELECT_ACTIVE_PRODUCTS]: "Wybierz aktywne produkty",
    [k.MANAGE_PRODUCTS]: "Zarządzanie produktami",
    [k.LOGFF]: "Wyloguj użytkownika",
    [k.SELECTUSER]: "Wybierz użytkownika",
    [k.USERACCOUNT]: "Konto użytkownika",
    [k.US_DOLLAR]: "Dolar amerykański (USD)",
    [k.PRODUCT_SUMMARY]: "Podsumowanie produktów",
    [k.ERROR_OCCURRED]: "Wystąpił błąd",
};
