import { React, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import plusIcon from "../assets/images/icons/plusIcon.svg";
import minusIcon from "../assets/images/icons/minusIcon.svg";
import closeIcon from "../assets/images/icons/closeIcon.svg";
import i18n from "i18next";
import k from "../i18n/keys";
import { useSettings } from "../_context/settingsContext";

const StyledColumnsTable = styled.div`
    display: inline-block;
    text-align: center;
    padding: 0px 5px;
    padding-top: 25px;
`;

const ProductColumn = styled.div`
    text-align: left;
    display: flex;
    padding-top: 0px;
    margin-top: 5px;
`;

const PictureProduct = styled.div`
    display: flex;
    margin-right: 16px;
    align-items: center;
    img {
        height: 40px;
        border-radius: 8px 0px 0px 8px;
    }
`;

export const ProductTable = ({ data, columns, changeFullDiscount, fullDiscount, changeProductDiscount, investorPriceIsBelow, setInvestorPriceIsBelow, changeProductAmount }) => {
    const [showFullDiscount, setShowFullDiscount] = useState(false);
    const [groupedProducts, setGroupedProducts] = useState([]);
    const { currencyExchangeRate } = useSettings();

    useEffect(() => {
        if (data.length > 0) {
            let notGroupedProducts = JSON.parse(JSON.stringify(data));
            let groupedProducts = [];
            for (let i = 0; i < notGroupedProducts.length; i++) {
                if (groupedProducts.find((x) => x.productId === notGroupedProducts[i].productId)) {
                    let product = groupedProducts.find((x) => x.productId === notGroupedProducts[i].productId);

                    product.amount += notGroupedProducts[i].amount;

                    product.bruttoPriceBasic += notGroupedProducts[i].bruttoPriceBasic;
                    product.nettoPriceBasic += notGroupedProducts[i].nettoPriceBasic;

                    product.bruttoPriceDistributor += notGroupedProducts[i].bruttoPriceDistributor;
                    product.nettoPriceDistributor += notGroupedProducts[i].nettoPriceDistributor;

                    product.originalBruttoPriceBasic += notGroupedProducts[i].originalBruttoPriceBasic;
                    product.originalNettoPriceBasic += notGroupedProducts[i].originalNettoPriceBasic;
                } else {
                    groupedProducts.push(notGroupedProducts[i]);
                }
            }
            setGroupedProducts(groupedProducts);
        }
    }, [data]);

    const setFullDiscount = (showFullDisc) => {
        setShowFullDiscount(showFullDisc);
        changeFullDiscount(0);
    };

    return (
        <div
            style={{
                background: "#FFFFFF",
                border: "1px solid #C5C5C5",
                borderRadius: "4px",
                fontFamily: "'Klavika'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "14px",
                width: window.innerWidth > 1024 ? null : "100vw",
                overflowX: window.innerWidth > 1024 ? null : "scroll",
            }}>
            <Row
                style={{
                    height: "40px",
                    background: "#1DA263",
                    borderRadius: "4px 4px 0px 0px",
                    margin: "-1px",
                    display: "grid",
                    gridTemplateColumns: window.innerWidth > 1024 ? "6% 35% 15% 15% 15% 14%" : "50px 200px 100px 100px 100px 100px",
                    width: window.innerWidth > 1024 ? null : "fit-content",
                    alignContent: "center",
                }}>
                {columns.map((col, colId) => (
                    <Col
                        key={colId}
                        style={{
                            color: "rgb(255, 255, 255)",
                            borderRight: col.noBorder ? "none" : "1px solid #ECECEC",
                            textAlignLast: "center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                        }}>
                        {col.label}
                    </Col>
                ))}
            </Row>

            {groupedProducts.map((row, i) => (
                <>
                    <Row
                        style={{
                            display: "grid",
                            gridTemplateColumns: window.innerWidth > 1024 ? "6% 35% 15% 15% 15% 14%" : "50px 200px 100px 100px 100px 100px",
                            fontFamily: "'Klavika'",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",
                            alignItems: "center",
                            color: "#282828",
                            height: "77px",
                            marginRight: "0px",
                            marginLeft: "0px",
                            width: window.innerWidth > 1024 ? null : "fit-content",
                            borderBottom: "1px solid #ECECEC",
                        }}>
                        <Col style={{ height: "80%", borderRight: "1px solid #ECECEC", textAlign: "-webkit-center" }}>
                            <StyledColumnsTable>{i + 1}.</StyledColumnsTable>
                        </Col>
                        <Col style={{ height: "80%", borderRight: "1px solid #ECECEC", textAlign: "-webkit-center" }}>
                            <ProductColumn>
                                <PictureProduct>
                                    <img src={row.productPicture ? `data:jpeg;base64,${row.productPicture ?? "0x00"}` : `${process.env.PUBLIC_URL}/Logo.png`} alt="" />
                                </PictureProduct>
                                <span style={{ display: "grid" }}>
                                    <span>{row.productName}</span>
                                    <span style={{ fontWeight: "400", fontSize: "10px", lineHeight: "12px", color: "#C5C5C5" }}>SKU: {row.symbol ? row.symbol : "brak"}</span>
                                    <span style={{ fontWeight: "400", fontSize: "10px", lineHeight: "12px", color: "#C5C5C5" }}>EAN: {row.ean ? row.ean : "brak"}</span>
                                </span>
                            </ProductColumn>
                        </Col>

                        <Col style={{ display: "grid", placeContent: "center", borderRight: "1px solid #ECECEC" }}>
                            <div style={{ color: showFullDiscount ? "lightGray" : "", alignItems: "center", display: "inline-flex", placeContent: "center" }}>
                                <div style={{ marginRight: "8px" }}>
                                    <img
                                        src={minusIcon}
                                        alt="plus"
                                        style={{ width: "12px", height: "12px", marginLeft: "-5px", cursor: !showFullDiscount ? "pointer" : "default" }}
                                        disabled={row.amount === 0}
                                        onClick={() => {
                                            row.amount > 0 ? changeProductAmount(row.productId, row.amount - 1) : null;
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        border: "1px solid #C5C5C5",
                                        borderRadius: "4px",
                                        width: "30px",
                                        padding: "7px",
                                        height: "30px",
                                        fontFamily: "'Klavika'",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        textAlign: "center",
                                    }}>
                                    {row.amount}
                                </div>

                                <div style={{ marginLeft: "13px" }}>
                                    <img
                                        src={plusIcon}
                                        alt="plus"
                                        style={{ width: "12px", height: "12px", marginLeft: "-5px", cursor: !showFullDiscount ? "pointer" : "default" }}
                                        onClick={() => changeProductAmount(row.productId, row.amount + 1)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col style={{ height: "80%", borderRight: "1px solid #ECECEC", textAlign: "-webkit-center" }}>
                            <StyledColumnsTable>{(row.nettoPriceDistributor / currencyExchangeRate)?.toFixed(2)}</StyledColumnsTable>
                        </Col>
                        <Col
                            style={{
                                height: "80%",
                                borderRight: "1px solid #ECECEC",
                                textAlign: "-webkit-center",
                                color: row.nettoPriceDistributor > row.nettoPriceBasic ? "#DC3545" : "#282828",
                            }}>
                            <StyledColumnsTable>{(row.nettoPriceBasic / currencyExchangeRate)?.toFixed(2)}</StyledColumnsTable>
                        </Col>

                        <Col style={{ display: "grid", placeContent: "center" }}>
                            <div style={{ color: showFullDiscount ? "lightGray" : "", alignItems: "center", display: "inline-flex", placeContent: "center" }}>
                                <div style={{ marginRight: "8px" }}>
                                    <img
                                        src={minusIcon}
                                        alt="plus"
                                        style={{ width: "12px", height: "12px", marginLeft: "-5px", cursor: !showFullDiscount ? "pointer" : "default" }}
                                        disabled={showFullDiscount || row.discountPercentage == 0}
                                        onClick={() => (!showFullDiscount && row.discountPercentage > 0 ? changeProductDiscount(row.productId, -1) : {})}
                                    />
                                </div>
                                <div
                                    disabled={showFullDiscount}
                                    style={{
                                        border: "1px solid #C5C5C5",
                                        borderRadius: "4px",
                                        width: "30px",
                                        padding: "7px",
                                        height: "30px",
                                        fontFamily: "'Klavika'",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                    }}>
                                    {(showFullDiscount ? 0 : row.discountPercentage) + "%"}
                                </div>
                                <div style={{ marginLeft: "13px" }}>
                                    <img
                                        src={plusIcon}
                                        alt="plus"
                                        style={{ width: "12px", height: "12px", marginLeft: "-5px", cursor: !showFullDiscount ? "pointer" : "default" }}
                                        disabled={showFullDiscount}
                                        onClick={() => (!showFullDiscount ? changeProductDiscount(row.productId, 1) : {})}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            ))}
            <Row style={{ background: "#ECECEC", height: "15px", margin: "0px", width: window.innerWidth > 1024 ? null : "fit-content" }}></Row>
            <Row style={{ backgroundColor: "white", height: "87px", margin: "0px", display: "grid", gridTemplateColumns: "60% 40%" }}>
                <Col style={{ marginTop: "7px" }}>
                    {investorPriceIsBelow && (
                        <div
                            style={{
                                padding: "5px",
                                height: "min-content",
                                background: "#FFF3CD",
                                border: "1px solid #FFE69C",
                                borderRadius: "4px",
                                fontFamily: "'Klavika'",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "#664D03",
                                paddingTop: "8px",
                                display: "inline-flex",
                                justifyContent: "space-between",
                                width: "inherit",
                            }}>
                            <span>{i18n.t(k.INVESTOR_PRICE_BELOW_COST)}</span>
                            <img
                                src={closeIcon}
                                alt="close"
                                style={{ width: "10.5px", height: "10.5px", alignSelf: "center", marginTop: "-3px" }}
                                onClick={() => setInvestorPriceIsBelow(false)}
                            />
                        </div>
                    )}
                </Col>
                <Col style={{ marginTop: "7px" }}>
                    <div>
                        {" "}
                        {showFullDiscount && (
                            <div
                                style={{
                                    background: "#FFFFFF",
                                    border: "1px solid #C5C5C5",
                                    borderRadius: "4px",
                                    alignItems: "center",
                                    gridTemplateColumns: "73% 28%",
                                    display: "grid",
                                    marginLeft: "15px",
                                }}>
                                <span
                                    style={{
                                        fontFamily: "'Klavika'",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        lineHeight: "24px",
                                        display: "flex",
                                        alignItems: "center",
                                        textAlign: "center",
                                        color: "#CCCCCC",
                                        marginRight: "auto",
                                        height: "40px",
                                        marginLeft: "10px",
                                    }}>
                                    {i18n.t(k.DISCOUNT)}
                                </span>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ marginRight: "8px" }}>
                                        <img
                                            src={minusIcon}
                                            alt="plus"
                                            style={{ width: "12px", height: "12px", marginLeft: "-5px" }}
                                            disabled={fullDiscount == 0}
                                            onClick={() => changeFullDiscount(parseFloat(fullDiscount) - 1)}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            border: "1px solid #C5C5C5",
                                            borderRadius: "4px",
                                            width: "30px",
                                            padding: "7px",
                                            height: "30px",
                                            fontFamily: "'Klavika'",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            lineHeight: "14px",
                                        }}>
                                        {fullDiscount + "%"}
                                    </div>
                                    <div style={{ marginLeft: "13px" }}>
                                        <img
                                            src={plusIcon}
                                            alt="plus"
                                            style={{ width: "12px", height: "12px", marginLeft: "-5px" }}
                                            onClick={() => changeFullDiscount(parseFloat(fullDiscount) + 1)}
                                        />
                                    </div>
                                </span>
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex", marginLeft: "15px" }}>
                        {" "}
                        <input
                            id="discount"
                            type="checkbox"
                            onClick={() => setFullDiscount(!showFullDiscount)}
                            style={{ marginTop: "10px", width: "16px", height: "16px", border: "1px solid #C5C5C5", marginRight: "5px", borderRadius: "2px" }}
                        />
                        <label htmlFor="discount" style={{ fontWeight: "400", fontSize: "12px", lineHeight: "12px", color: "#282828", marginTop: "13px" }}>
                            <span>{i18n.t(k.APPLY_DISCOUNT_TO_ALL_PRODUCTS)}</span>
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
