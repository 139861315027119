import { React, useState, useEffect } from "react";
import styled from "styled-components";

const Multiselect = styled.div`
    width: auto;
    border-radius: 2px;
    position: relative;
    z-index: 1;
`;
const SelectBox = styled.div`
    position: relative;
    select {
        width: 95%;
        font-weight: bold;
        height: 34px;
        border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
        border-radius: 4px;
        color: ${({ theme: { colors } }) => colors.gray.dark};
        text-transform: none;
        font-size: 16px;
        padding-left: 5px;
        &:focus {
            outline: none;
            border: 1px solid ${({ theme: { colors } }) => colors.green.normal};
        }
    }

    font-weight: 700;
    color: ${({ theme: { colors } }) => colors.gray.dark};

    height: 30px;
    background-color: #${({ theme: { colors } }) => colors.base};
`;
const OverSelect = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #${({ theme: { colors } }) => colors.base} !important;
`;
const Checkboxes = styled.div`
    border: 1px #dadada solid;
    background-color: #${({ theme: { colors } }) => colors.base} !important;
    box-shadow: 8px 8px 24px #00000029;
    position: absolute;
`;

const Checkbox = styled.div`
    display: inline-block;
    width: max-content;
    padding-right: 10px;
    background: ${({ theme: { colors } }) => colors.base};
    > input {
        opacity: 0;
    }
    > input + label {
        vertical-align: sub;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        margin-left: -10px;
        color: ${({ theme: { colors } }) => colors.gray.dark};
        font-weight: 700;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0px;
            width: 20px;
            height: 20px;
            border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
            background: ${({ theme: { colors } }) => colors.gray.light};
            border-radius: 5px;
        }
        &:after {
            content: "✔";
            position: absolute;
            left: 0px;
            height: 20px;
            text-align: -webkit-center;
            font-size: 16px;
            font: -webkit-small-control;
            width: 20px;
            color: ${({ theme: { colors } }) => colors.gray.light};
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            background-color: ${({ theme: { colors } }) => colors.green.normal};
            border-color: ${({ theme: { colors } }) => colors.green.normal};
            border-radius: 5px;
        }
    }
    > input:not(:checked) + label {
        &:after {
            opacity: 0;
            transform: scale(0);
            font-weight: 400;
        }
    }
`;

export const MultiselectDropdown = ({ filterName, setSelectedData, investmentTypeData, options, fieldName, onKeyDown, refresh }) => {
    const [toggle, setToggle] = useState(false);
    const [filters, setFilter] = useState([]);
    const [selected, setSelected] = useState([]);

    const getFilter = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            let filtersArray = filters;
            filtersArray.push(e.target.name);
            setFilter(filtersArray);
            setSelectedData(filtersArray);

            let newSelected;
            let firstOption;
            options.map((option) => {
                if (filtersArray.includes(option.Value.toString()) && firstOption == undefined) {
                    firstOption = option.Name;
                }
            });

            if (filtersArray.length == 1) newSelected = firstOption;
            else newSelected = firstOption + " (+" + (filtersArray.length - 1) + ")";
            setSelected(newSelected);
        } else if (!isChecked && filters.includes(e.target.name)) {
            let filtersArray = filters;
            var index = filtersArray.indexOf(e.target.name);
            if (index !== -1) {
                filtersArray.splice(index, 1);
                setFilter(filtersArray);
                let newSelected;
                let firstOption;

                options.map((option) => {
                    if (filtersArray.includes(option.Value.toString()) && firstOption == undefined) {
                        firstOption = option.Name;
                    }
                });
                if (filtersArray.length == 1) newSelected = firstOption;
                else if (filtersArray.length == 0) newSelected = "";
                else newSelected = firstOption + " (+" + (filtersArray.length - 1) + ")";
                setSelected(newSelected);
            }
        }
    };

    useEffect(() => {
        if (refresh == true) {
            setFilter([]);
            setSelectedData([]);
            if (window.location.href.includes("/investments")) {
                options.map((option) => {
                    document.getElementById(`${fieldName}-${option.Value}`).checked = false;
                });
            }
        }
    }, [refresh, options]);

    return (
        <form onKeyDown={onKeyDown}>
            <Multiselect>
                <SelectBox onClick={() => setToggle(!toggle)}>
                    <select style={{ fontFamily: "'Klavika'", fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "21px", color: "#B1B1B1" }}>
                        <option>{selected && selected.length > 0 ? selected : filterName}</option>
                    </select>
                    <OverSelect></OverSelect>
                </SelectBox>
                <Checkboxes hidden={!toggle} onChange={(e) => getFilter(e)}>
                    <div
                        style={{
                            backgroundColor: "white",
                            position: "relative",
                            maxHeight: "400px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            fontFamily: "'Klavika'",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                        }}>
                        {options.map((option, optionId) => (
                            <Checkbox key={optionId}>
                                <input id={`${fieldName}-${option.Value}`} type="checkbox" name={option.Value} />
                                <label htmlFor={`${fieldName}-${option.Value}`}>{option.Name}</label>
                            </Checkbox>
                        ))}
                    </div>
                </Checkboxes>
            </Multiselect>
        </form>
    );
};
