import { React, useEffect, useState } from "react";
import styled from "styled-components";
import plFlag from "../assets/images/flags/165-poland.svg";
import enFlag from "../assets/images/flags/110-united kingdom.svg";
import deFlag from "../assets/images/flags/208-germany.svg";
import frFlag from "../assets/images/flags/197-france.svg";
import espFlag from "../assets/images/flags/230-spain.svg";
import skFlag from "../assets/images/flags/218-slovakia.svg";
import czFlag from "../assets/images/flags/202-czech republic.svg";
import noFlag from "../assets/images/flags/205-norway.svg";
import swFlag from "../assets/images/flags/190-sweden.svg";
import itFlag from "../assets/images/flags/263-italy.svg";
import roFlag from "../assets/images/flags/213-romania.svg";
import i18n from "i18next";
import k from "../i18n/keys";

const LangOptionsWrapper = styled.div`
    display: flex;
    cursor: pointer;
    margin: -25px 0 10px 0;
    ol {
        display: flex;
        list-style-type: none;
        border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
        border-radius: 4px;
        padding: 8px 12px;
        background-color: ${({ theme: { colors } }) => colors.base};
    }
    ol > li {
        display: flex;
        justify-content: space-between;
        width: 190px;
        height: 25px;
        div {
            width: 110px;
            color: ${({ theme: { colors } }) => colors.black};
        }
    }
    ol > li > ul {
        list-style-type: none;
        padding: 0;
        position: absolute;
        border-left: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
        border-right: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
        border-bottom: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
        border-radius: 4px;
        padding: 8px 12px;
        background-color: ${({ theme: { colors } }) => colors.base};
        width: 150px;
        margin-left: -13px;
        margin-top: 22px;
    }
    ol > li > ul > li {
        display: flex;
        align-items: flex-start;
        background-color: ${({ theme: { colors } }) => colors.base};
        z-index: 100;
        p {
            margin: 0px;
        }
        &:hover {
            font-weight: bold;
        }
    }
    img {
        width: 20px;
        margin-right: 5px;
        border-radius: 50%;
    }
    .ChosenLangWrapper {
        img {
            margin-right: -5px;
        }
        display: flex;
        p {
            margin-left: 10px;
        }
    }
`;

const LangDropdown = () => {
    const [showLangOptions, setShowLangOptions] = useState(false);
    const [browsersLanguage, setBrowsersLanguage] = useState(false);

    useEffect(() => {
        if (!browsersLanguage) {
            let userLang = navigator.language || navigator.userLanguage;
            localStorage.setItem("lang", userLang);
            setBrowsersLanguage(true);
        }
    }, []);

    const handleClickChangeLanguage = (lang) => {
        localStorage.setItem("lang", lang);
        window.location.reload();
    };

    const showChangedLanguage = () => {
        let lang = localStorage.getItem("lang");
        switch (lang) {
            case "pl":
                return i18n.t(k.POLISH);
            case "en":
                return i18n.t(k.ENGLISH);
            case "de":
                return i18n.t(k.GERMAN);
            case "fr":
                return i18n.t(k.FRENCH);
            case "esp":
                return i18n.t(k.SPAIN);
            case "sk":
                return i18n.t(k.SLOVAK);
            case "cz":
                return i18n.t(k.CZECH);
            case "no":
                return i18n.t(k.NORWEGIAN);
            case "sw":
                return i18n.t(k.SWEDISH);
            case "it":
                return i18n.t(k.ITALIAN);
            case "ro":
                return i18n.t(k.ROMANIAN);
            default:
                break;
        }
        return null;
        // return lang;
    };

    return (
        <LangOptionsWrapper>
            <ol>
                <li onClick={() => setShowLangOptions(!showLangOptions)}>
                    <p>
                        {localStorage.getItem("lang") == null || localStorage.getItem("lang") === "" ? (
                            <span style={{ color: "#d9d9d9", marginRight: "6px" }}>{i18n.t(k.CHOOSELANG)}</span>
                        ) : (
                            showChangedLanguage()
                        )}
                    </p>
                    <span className="ChosenLangWrapper">
                        <img style={{ zIndex: "3" }} src={plFlag} alt="" />
                        <img style={{ zIndex: "2" }} src={enFlag} alt="" />
                        <img style={{ zIndex: "1" }} src={deFlag} alt="" />
                        <p>+8</p>
                    </span>
                    <ul onMouseLeave={() => setShowLangOptions(false)} style={showLangOptions ? { display: "flex", flexDirection: "column", zIndex: "1000" } : { display: "none" }}>
                        <li onClick={() => handleClickChangeLanguage("pl")}>
                            <img src={plFlag} alt="" />
                            {/* <p>{i18n.t(k.POLISH)}</p> */}
                            {i18n.t(k.POLISH)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("en")}>
                            <img src={enFlag} alt="" />
                            {i18n.t(k.ENGLISH)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("de")}>
                            <img src={deFlag} alt="" />
                            {i18n.t(k.GERMAN)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("fr")}>
                            <img src={frFlag} alt="" />
                            {i18n.t(k.FRENCH)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("esp")}>
                            <img src={espFlag} alt="" />
                            {i18n.t(k.SPAIN)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("sk")}>
                            <img src={skFlag} alt="" />
                            {i18n.t(k.SLOVAK)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("cz")}>
                            <img src={czFlag} alt="" />
                            {i18n.t(k.CZECH)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("no")}>
                            <img src={noFlag} alt="" />
                            {i18n.t(k.NORWEGIAN)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("sw")}>
                            <img src={swFlag} alt="" />
                            {i18n.t(k.SWEDISH)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("it")}>
                            <img src={itFlag} alt="" />
                            {i18n.t(k.ITALIAN)}
                        </li>
                        <li onClick={() => handleClickChangeLanguage("ro")}>
                            <img src={roFlag} alt="" />
                            {i18n.t(k.ROMANIAN)}
                        </li>
                    </ul>
                </li>
            </ol>
        </LangOptionsWrapper>
    );
};

export { LangDropdown };
