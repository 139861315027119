import { React, useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { DialogContent as ReachDialog } from "@reach/dialog";
import { FaSpinner } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import device from "../_styles/media-queries";
import { ImFilesEmpty } from "react-icons/im";
import i18n from "i18next";
import k from "../i18n/keys";
import { ActionButton } from "./buttons";
import { useNavigate } from "react-router-dom";

const spin = keyframes`
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
`;

const Spinner = styled(FaSpinner)`
    animation: ${spin} 1s linear infinite;
`;

Spinner.defaultProps = {
    "aria-label": "loading",
};

const Dialog = styled(ReachDialog)`
    max-width: 450px;
    border-radius: 3px;
    padding-bottom: 3.5em;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
    margin: 20vh auto;
    .recommendation {
        color: #fff;
        display: flex;
        justify-content: center;
        height: 26px;
        h5 {
            width: fit-content;
            background-color: #1da263;
            padding: 3px 10px;
            border-radius: 4px 4px 0px 0px;
            font-size: 16px;
            font-weight: 300;
        }
    }
    @media only screen and ${device.maxLaptop} {
        width: 80vw;
    }
`;

const ErrorBox = styled.div`
    color: ${({ theme: { colors } }) => colors.danger};
`;

const ValidationMessage = styled.div`
    color: red;
    font-size: 12px;
`;

const StyledLogo = styled.img`
    max-width: 90px;
    @media ${device.laptopM} {
        max-width: 120px;
    }
`;
const StyledLogoAll = styled.img`
    max-width: 90px;
    @media ${device.laptopM} {
        max-width: 120px;
    }
`;

const SearcherWrapper = styled.div`
    flex-grow: 1;
    margin: 5px;
    position: relative;
    span {
        position: absolute;
        bottom: 13px;
        right: 5px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    @media ${device.laptop} {
        flex-direction: row;
        justify-content: space-between;
        width: 314px;
    }
`;

const SuggestionWrapper = styled.div`
    position: absolute;
    background: ${({ theme: { colors } }) => colors.base} 0% 0% no-repeat padding-box;
    box-shadow: 8px 8px 24px #00000029;
    border-radius: 4px;
    border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
    width: 100%;
`;

const SuggestionItem = styled(ActionButton)`
    padding: 5px;
    width: 100%;
    text-align-last: left;
`;
const SearchInput = styled.input`
    width: 100%;
    height: 100%;
    padding: 12px 40px 12px 10px;
    border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
    border-radius: 4px;
    &:focus {
        outline: none;
        border: 1px solid ${({ theme: { colors } }) => colors.green.normal};
    }
`;

const Greenbox = styled.div`
    position: relative;
    font-size: 55px;
    color: #ffffff;
    background-color: ${({ theme: { colors } }) => `${colors.green.normal}bb`};
    border-radius: 10px;
    padding: 50px 70px;
    h1 {
        text-align: left;
        letter-spacing: 0px;
        color: #ffffff;
    }
    h5 {
        font-weight: normal;
        padding: 10px 0;
        color: #ffffff;
    }
    h2 {
        margin: 30px 0 10px;
    }
    p {
        letter-spacing: 0px;
        color: #ffffff;
        padding-bottom: 5px;
        font-size: 18px;
    }
    div {
        position: absolute;
        left: -30px;
        top: -30px;
        width: 73px;
        height: 73px;
        font-weight: bold;
        font-size: 50px;
        text-align: center;
        border-radius: 37px;
        color: #000000;
        background: #efefef;
        svg {
            color: #000000;
        }
    }
    a {
        text-decoration: none;
        color: #ffffff;
    }
    b {
        letter-spacing: 0px;
        color: #ffffff;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
    }
    ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        margin-left: -30px;
        font-size: 25px;
    }
    h3 {
        letter-spacing: 0px;
        color: #ffffff;
        padding-bottom: 5px;
        font-size: 25px;
    }
    @media ${device.maxLaptopL} {
        h1 {
            font-size: 32px;
        }
    }
    @media ${device.maxTablet} {
        ul {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }
    @media ${device.maxLaptopM} {
        background-color: ${({ theme: { colors } }) => `${colors.green.normal}`};
        padding: 25px;
        border-radius: 10px;
        margin: 40px 0;
        div {
            position: absolute;
            left: -20px;
            top: -25px;
            width: 53px;
            height: 50px;
            font-size: 35px;
        }
        h1 {
            font-size: 25px;
        }
        p {
            font-size: 15px;
        }
    }
`;

function Searcher({ placeholder, action, getSearchList, suggestionList, ...props }) {
    const [autocomplite, setAutocomplite] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const myInput = useRef();

    const search = (value) => {
        action(value);
    };

    const onChange = (value) => {
        setAutocomplite(true);
        if (value.length > 2 || value.length === 0) {
            getSearchList(value);
            search(value);
        } else {
            setAutocomplite(false);
            search("");
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChange(searchTerm);
        }, 800);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    return (
        <SearcherWrapper onBlur={() => setAutocomplite(false)}>
            <SearchInput placeholder={placeholder} ref={myInput} defaultValue="" onChange={() => setSearchTerm(myInput.current.value)} />
            <span>
                <AiOutlineSearch onClick={() => search(myInput.current.value)} />
            </span>
            {autocomplite === true && suggestionList && suggestionList.length > 0 ? (
                <SuggestionWrapper>
                    {" "}
                    {suggestionList.map((item) => (
                        <SuggestionItem key={item.id} onMouseDown={() => search(item.fullTitle || item.name)}>
                            {item.fullTitle || item.name}
                        </SuggestionItem>
                    ))}
                </SuggestionWrapper>
            ) : (
                ""
            )}
        </SearcherWrapper>
    );
}

function Logo({ ...props }) {
    const navigate = useNavigate();
    return <StyledLogo src={props.newLogo ? props.newLogo : `${process.env.PUBLIC_URL}/Logo.png`} onClick={() => navigate("/")}></StyledLogo>;
}

function LogoAll({ ...props }) {
    return <StyledLogoAll src={`${process.env.PUBLIC_URL}/LogoAll.png`}></StyledLogoAll>;
}

const StyledIcon = styled.img`
    max-width: 36px;
`;

const StyledIcon2 = styled.img`
    max-width: 36px;
    filter: brightness(0) saturate(100%) invert(25%) sepia(17%) saturate(17%) hue-rotate(353deg) brightness(90%) contrast(78%);
    &:hover {
        filter: none;
    }
`;

const StyledIconColor = styled.img`
    max-width: 36px;
    filter: invert(43%) sepia(97%) saturate(388%) hue-rotate(99deg) brightness(98%) contrast(84%);
    &:hover {
        filter: none;
    }
`;

function Hamburger({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/Hamburger.svg`}></StyledIcon>;
}
function PeopleImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/people.png`}></StyledIcon>;
}
function PeopleBigImg({ ...props }) {
    return <StyledIcon style={props.addedStyles} src={`${process.env.PUBLIC_URL}/peopleBig.svg`}></StyledIcon>;
}

function BuildingImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/building.png`}></StyledIcon>;
}

function BoundingImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/bounding.png`}></StyledIcon>;
}

function TagsImg({ currentTab, ...props }) {
    if (currentTab == 4 && currentTab != undefined) {
        return <StyledIconColor src={`${process.env.PUBLIC_URL}/tags.svg`}></StyledIconColor>;
    } else {
        return <StyledIcon src={`${process.env.PUBLIC_URL}/tags.svg`}></StyledIcon>;
    }
}

function BasketImg({ currentTab, ...props }) {
    if (currentTab == 5 && currentTab != undefined) {
        return <StyledIconColor src={`${process.env.PUBLIC_URL}/basket3.svg`}></StyledIconColor>;
    } else {
        return <StyledIcon src={`${process.env.PUBLIC_URL}/basket3.svg`}></StyledIcon>;
    }
}

function ChatImg({ currentTab, ...props }) {
    if (currentTab == 7 && currentTab != undefined) {
        return <StyledIconColor src={`${process.env.PUBLIC_URL}/message-circle.svg`}></StyledIconColor>;
    } else {
        return <StyledIcon src={`${process.env.PUBLIC_URL}/message-circle.svg`}></StyledIcon>;
    }
}

function GearImg({ currentTab, ...props }) {
    if (currentTab == 6 && currentTab != undefined) {
        return <StyledIconColor src={`${process.env.PUBLIC_URL}/gear.svg`}></StyledIconColor>;
    } else {
        return <StyledIcon src={`${process.env.PUBLIC_URL}/gear.svg`}></StyledIcon>;
    }
}

function LogOutImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/Log-out.svg`}></StyledIcon>;
}

function CartImg({ currentTab, ...props }) {
    if (currentTab == 0) {
        return <StyledIconColor src={`${process.env.PUBLIC_URL}/cart.svg`}></StyledIconColor>;
    } else {
        return <StyledIcon src={`${process.env.PUBLIC_URL}/cart.svg`}></StyledIcon>;
    }
}

function PlusCircleImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/PlusCircle.png`}></StyledIcon>;
}

function UnionImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/Union.svg`}></StyledIcon>;
}
function ArrowImg({ ...props }) {
    return <StyledIcon src={`${process.env.PUBLIC_URL}/Arrow.svg`}></StyledIcon>;
}

function FullPageSpinner() {
    return (
        <div
            style={{
                fontSize: "4em",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <Spinner />
        </div>
    );
}

function PageSpinner() {
    return (
        <div
            style={{
                fontSize: "4em",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                position: "absolute",
                top: "0",
                left: "0",
                background: "#5a5a5a1c",
                zIndex: 1,
            }}>
            <Spinner />
        </div>
    );
}

const RoundedWhiteBox = styled.div`
    border-radius: 4px;
    background: ${({ theme: { colors } }) => colors.base};
    padding: ${({ withPadding }) => (withPadding ? "20px" : "0")};
    p {
        font-size: 14px;
        color: #696969;
        line-height: 20px;
    }
    @media only screen and ${device.maxMobileL} {
        margin-top: 0;
    }
`;

const errorMessageVariants = {
    stacked: { display: "block" },
    inline: { display: "inline-block" },
};

const parse = (error) => {
    var result = "";
    if (error)
        for (const key of Object.keys(error)) {
            const val = error[key];
            if (Array.isArray(val)) {
                for (var err of val) {
                    result = result.concat(err + "\n");
                }
            } else if (typeof val === "string") {
                result = result.concat(val + "\n");
            }
        }

    return result;
};

function ErrorMessage({ errorMessage, variant = "stacked", ...props }) {
    return (
        <ErrorBox role="alert" style={{ ...errorMessageVariants[variant] }} {...props}>
            {!errorMessage && <span>{i18n.t(k.INCORRECT_LOGIN_OR_PASSWORD)} </span>}
            <pre
                style={{
                    whiteSpace: "break-spaces",
                    margin: "0",
                    marginBottom: -5,
                    ...errorMessageVariants[variant],
                }}>
                <span>{parse(errorMessage)}</span>
            </pre>
        </ErrorBox>
    );
}

function FullPageErrorFallback({ error, onRefresh }) {
    const navigate = useNavigate();
    return (
        <div
            role="alert"
            style={{
                color: (theme) => ({ color: theme.colors.danger }),
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
            <pre>{error.message}</pre>
            <button onClick={() => onRefresh()}>Please re-login</button>
        </div>
    );
}

const EmptyListMaskWrapper = styled.div`
    margin: 60px 25px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.gray.dark};
    svg {
        font-size: 46px;
        color: ${({ theme: { colors } }) => colors.gray.normal};
        margin-bottom: 40px;
    }
    p {
        font-size: 14px;
        margin-top: 30px;
    }
`;

function EmptyListMask({ title, description }) {
    return (
        <EmptyListMaskWrapper>
            <ImFilesEmpty />
            <h3>{title}</h3>
            <p>{description}</p>
        </EmptyListMaskWrapper>
    );
}

const FlexBox = styled.div`
    display: flex;
    flex-direction: ${({ isCol }) => (isCol ? "column" : undefined)};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
    justify-content: ${({ justify }) => (justify ? justify : undefined)};
    max-width: 74%;
`;

export {
    FullPageErrorFallback,
    ErrorMessage,
    Spinner,
    Dialog,
    Logo,
    LogoAll,
    PeopleImg,
    Hamburger,
    PlusCircleImg,
    PeopleBigImg,
    BuildingImg,
    UnionImg,
    ArrowImg,
    TagsImg,
    GearImg,
    BoundingImg,
    CartImg,
    BasketImg,
    LogOutImg,
    FullPageSpinner,
    PageSpinner,
    Searcher,
    RoundedWhiteBox,
    EmptyListMask,
    FlexBox,
    ValidationMessage,
    SearcherWrapper,
    SearchInput,
    SuggestionWrapper,
    SuggestionItem,
    Greenbox,
    ChatImg,
};
