import React from "react";
import styled from "styled-components";
import { BsFillDropletFill } from "react-icons/bs";

const ColorPickerWrapper = styled.div`
    display: flex;
    cursor: pointer;
    ol {
        list-style-type: none;
        background-color: ${({ theme: { colors } }) => colors.base};
        border: ${({ theme: { colors }, primary }) => `1px solid ${primary ? colors.green.normal : "#C5C5C5"}`};
        border-radius: 30px;
        padding: 0;
        width: 30px;
        height: 30px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        margin-right: 10px;
    }
    ol > li {
        display: flex;
        justify-content: center;
    }
    ol > li > ul {
        list-style-type: none;
        padding: 10px;
        position: absolute;
        border-radius: 30px;
        width: 40px;
        height: auto;
        background-color: ${({ theme: { colors } }) => colors.base};
        margin-top: -210px;
    }
    ol > li > ul > li {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        z-index: 100;
        &:hover {
            font-weight: bold;
        }
    }
`;

const ColorPicker = ({ setChosenColor, chosenColor, showColorOptions, setShowColorOptions }) => {
    return (
        <ColorPickerWrapper id="tip-color">
            <ol onClick={() => setShowColorOptions(!showColorOptions)}>
                <li>
                    <BsFillDropletFill fill={chosenColor} />
                    <ul
                        onMouseLeave={() => setShowColorOptions(false)}
                        style={showColorOptions ? { display: "flex", flexDirection: "column", zIndex: "1000" } : { display: "none" }}>
                        <li onClick={() => setChosenColor("rgb(255, 0, 0, 0.4)")}>
                            <BsFillDropletFill fill="rgb(255, 0, 0, 0.4)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(0, 255, 0, 0.3)")}>
                            <BsFillDropletFill fill="rgb(0, 255, 0, 0.3)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(0, 0, 255, 0.3)")}>
                            <BsFillDropletFill fill="rgb(0, 0, 255, 0.3)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(237, 155, 64, 0.5)")}>
                            <BsFillDropletFill fill="rgb(237, 155, 64, 0.5)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(165, 42, 42, 0.5)")}>
                            <BsFillDropletFill fill="rgb(165, 42, 42, 0.5)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(128, 128, 128, 0.5)")}>
                            <BsFillDropletFill fill="rgb(128, 128, 128, 0.5)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(238, 130, 238, 0.5)")}>
                            <BsFillDropletFill fill="rgb(238, 130, 238, 0.5)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(173, 216, 230, 0.5)")}>
                            <BsFillDropletFill fill="rgb(173, 216, 230, 0.8)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(255, 192, 203, 0.5)")}>
                            <BsFillDropletFill fill="rgb(255, 192, 203, 0.7)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(240, 128, 128, 0.5)")}>
                            <BsFillDropletFill fill="rgb(240, 128, 128, 0.5)" />
                        </li>
                        <li onClick={() => setChosenColor("rgb(144, 238, 144, 0.7)")}>
                            <BsFillDropletFill fill="rgb(144, 238, 144, 0.7)" />
                        </li>
                    </ul>
                </li>
            </ol>
        </ColorPickerWrapper>
    );
};

export { ColorPicker };
