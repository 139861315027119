import { QueryClient } from "react-query";
import * as auth from "../_helpers/authProvider";

const apiURL = process.env.REACT_APP_API_URL;
const queryClient = new QueryClient();

async function client(endpoint, { data, token, method, headers: customHeaders, ...customConfig } = {}) {
    const config = {
        method: method ? method : data ? "POST" : "GET",
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            ...customHeaders,
            Authorization: token ? `Bearer ${token}` : undefined,
            "Content-Type": data ? "application/json" : undefined,
            Accept: data ? "application/json" : undefined,
        },
        ...customConfig,
    };

    return window.fetch(`${apiURL}/${endpoint}`, config).then(async (response) => {
        if (response.status === 401) {
            queryClient.clear();
            await auth.logout();
            window.location.assign(window.location);
            return Promise.reject({ message: "Please re-authenticate." });
        }
        if (response.ok) {
            if (response.status !== 204) {
                if (response.headers.get("Content-Type") === "application/pdf") return response;
                else return await response.json();
            }
        } else {
            const data = await response.json();
            if (data.Errors) {
                return Promise.reject({ ...data.Errors });
            } else return Promise.reject([]);
        }
    });
}

export { client };
