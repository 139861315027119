import React, { useCallback, useState, useEffect } from "react";
import Select from "react-select";
import { FormGroup } from "../../_components";
import { useClient } from "../../_context/authContext";
import { RequestMethod } from "../../_utils";
import { UserAccountInfo, SaveButton, DisableButton, customStyles } from "./style";
import i18n from "i18next";
import k from "../../i18n/keys";
import useToast from "../../hooks/useToast";

const DistributorClientSelector = ({ user, toggle }) => {
    const [distributors, setDistributors] = useState([]);
    const [currentUsersList, setCurrentUsersList] = useState([]);
    const [selectedDistributorId, setSelectedDistributorId] = useState("");
    const [selectedUserId, setSelectedUserId] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const clientApi = useClient();
    const { toastError } = useToast();

    const getDistributorList = useCallback(() => {
        clientApi(`Distributors/GetAll`, RequestMethod.GET)
            .then((res) => setDistributors(res || []))
            .catch((error) => {
                toastError(error);
            });
    }, []);

    const getUsersList = useCallback((distributorId) => {
        clientApi(`User/GetUsersList`, RequestMethod.POST, {
            headers: { "Content-Type": "application/json" },
            data: { distributorId },
        })
            .then((data) => {
                if (!data || data.length === 0) {
                    setCurrentUsersList([]);
                } else {
                    setCurrentUsersList(data);
                }
            })
            .catch((error) => {
                toastError(error);
            });
    }, []);

    const getCurrentUser = useCallback(() => {
        clientApi(`User/GetCurrentUsersList`, RequestMethod.GET)
            .then((users) => {
                if (users && users.length > 0) {
                    setCurrentUser(users[0]);
                }
            })
            .catch((error) => {
                toastError(error);
            });
    }, []);
    const handleDistributorChange = (selectedOption) => {
        const distributorId = selectedOption?.value || "";
        setSelectedDistributorId(distributorId);
        setSelectedUserId("");
        setCurrentUsersList([]);
        if (distributorId) {
            getUsersList(distributorId);
        }
    };

    const handleUserChange = (selectedOption) => {
        const userId = selectedOption?.value || "";
        setSelectedUserId(userId);
    };

    useEffect(() => {
        getDistributorList();
        getCurrentUser();
    }, [getDistributorList, getCurrentUser]);

    const handleSaveContext = async () => {
        try {
            const context = await clientApi(`UserAdminContext/GetUserAdminContext`, RequestMethod.POST, {
                headers: { "Content-Type": "application/json" },
                data: {
                    distributorId: selectedDistributorId,
                    userId: selectedUserId,
                },
            });

            const payload = {
                selectedDistributorId: selectedDistributorId,
                selectedUserId: selectedUserId,
                isActive: true,
            };

            await clientApi(`UserAdminContext/SaveUserAdminContext`, RequestMethod.POST, {
                headers: { "Content-Type": "application/json" },
                data: payload,
            });

            window.location.reload();
        } catch (error) {
            alert("Nie udało się zapisać zmian. Proszę spróbować ponownie.");
        }
    };

    const handleDisableContext = async () => {
        try {
            await clientApi(`UserAdminContext/SaveUserAdminContext`, RequestMethod.POST, {
                headers: { "Content-Type": "application/json" },
                data: {
                    isActive: false,
                },
            });

            window.location.reload();
        } catch (error) {
            alert("Nie udało się wylogować użytkownika. Proszę spróbować ponownie.");
        }
    };
    return (
        <div style={{ display: toggle ? "none" : "block" }}>
            {user?.username === "export@heatdecor.com" ? (
                <>
                    <FormGroup>
                        {currentUser && (
                            <UserAccountInfo>
                                {i18n.t(k.USERACCOUNT)}: {`${currentUser.email}`}
                            </UserAccountInfo>
                        )}
                        <Select
                            options={distributors.map((distributor) => ({
                                value: distributor.id,
                                label: distributor.name,
                            }))}
                            value={selectedDistributorId ? { value: selectedDistributorId, label: distributors.find((dis) => dis.id === selectedDistributorId)?.name } : null}
                            onChange={handleDistributorChange}
                            placeholder={`Dystrybutorzy: (${distributors.length})`}
                            styles={customStyles}
                            isSearchable
                        />
                    </FormGroup>

                    {selectedDistributorId && (
                        <FormGroup>
                            <Select
                                options={currentUsersList.map((user) => ({
                                    value: user.id,
                                    label: user.email,
                                }))}
                                value={selectedUserId ? { value: selectedUserId, label: currentUsersList.find((user) => user.id === selectedUserId)?.email } : null}
                                onChange={handleUserChange}
                                placeholder={`Użytkownicy: (${currentUsersList.length})`}
                                styles={customStyles}
                                isSearchable
                            />
                        </FormGroup>
                    )}

                    {selectedUserId && (
                        <FormGroup>
                            <SaveButton onClick={handleSaveContext}>{i18n.t(k.CONFIRM)}</SaveButton>
                        </FormGroup>
                    )}

                    {currentUser?.email !== "export@heatdecor.com" && (
                        <FormGroup>
                            <DisableButton onClick={handleDisableContext}> {i18n.t(k.LOGFF)}</DisableButton>
                        </FormGroup>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default DistributorClientSelector;
