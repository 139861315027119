import { React } from "react";
import { Button } from "./buttons";
import styled from "styled-components";

const TabNavWrapper = styled.div`
    margin: 25px 0 20px;
    button {
        margin-right: 60px;
    }
    button:nth-child(${({ activeIndex }) => activeIndex}) {
        color: ${({ theme: { colors } }) => colors.green.normal};
        font-weight: bold;
    }
`;

export const TabNav = ({ tabs, activeTab, handleClick, ...props }) => {
    return (
        <TabNavWrapper activeIndex={activeTab}>
            {tabs.map((item, key) => {
                return (
                    <Button key={key} variant="inline" type="button" primary onClick={() => handleClick(item.Id)}>
                        {item.Label}
                    </Button>
                );
            })}
        </TabNavWrapper>
    );
};
