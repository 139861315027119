import "./_styles/bootstrap";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { AppProviders } from "./_context";
import "./i18n/init";
import React from "react";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

root.render(
    // <React.StrictMode>
    <AppProviders>
        <App />
    </AppProviders>
    // </React.StrictMode>
);
