import { React, useEffect, useState } from "react";
import "./ContextMenu.css";

function ContextMenu({ contextMenu, contextMenuClass, contextMenuTrigger, recalculatePosition = true }) {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [showContextMenu, setShowContextMenu] = useState(false);

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (!event.target?.parentElement?.id?.includes("contexMenuTable_tr")) {
            setShowContextMenu(false);
        }
    };

    const handleContextMenu = (event) => {
        event.preventDefault();

        setMousePosition({
            x: event.clientX,
            y: event.clientY,
        });

        setShowContextMenu(true);
    };

    const getContextMenu = () => {
        if (contextMenu && contextMenu.length > 0)
            return (
                <table id="contexMenuTable" className={contextMenuClass ?? "contextMenu-Table"}>
                    <tbody>
                        {contextMenu.map((m, i) => {
                            return (
                                <tr id={`contexMenuTable_tr_${i}`} className={m.className ?? ""} style={m.style ?? {}} onClick={() => m.onClick()} key={i}>
                                    <td>{m.label}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
    };

    return (
        <div style={{ display: "contents" }}>
            {contextMenu && (
                <div
                    className="contextMenu"
                    style={{
                        display: showContextMenu ? "block" : "none",
                        pointerEvents: showContextMenu ? "auto" : "none",
                        top: `${mousePosition.y}px`,
                        left: `${mousePosition.x}px`,
                    }}>
                    {getContextMenu()}
                </div>
            )}
            <div onMouseEnter={handleContextMenu} onMouseLeave={() => setShowContextMenu(false)} style={{ display: "contents" }}>
                {contextMenuTrigger && (
                    <div id="contextMenuTrigger" style={{ display: "contents" }}>
                        {contextMenuTrigger()}
                    </div>
                )}
            </div>
        </div>
    );
}
export default ContextMenu;
