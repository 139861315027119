const size = {
    mobileS: "375px",
    mobileM: "425px",
    mobileL: "575px",
    tablet: "768px",
    laptop: "1024px",
    laptopM: "1200px",
    laptopL: "1440px",
    desktop: "2560px",
};

export default {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    tabletHeight: `(min-height: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopM: `(min-width: ${size.laptopM})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
    maxMobileS: `(max-width: ${size.mobileS})`,
    maxMobileM: `(max-width: ${size.mobileM})`,
    maxMobileL: `(max-width: ${size.mobileL})`,
    maxTablet: `(max-width: ${size.tablet})`,
    maxTabletHeight: `(max-height: ${size.tablet})`,
    maxLaptop: `(max-width: ${size.laptop})`,
    maxLaptopM: `(max-width: ${size.laptopM})`,
    maxLaptopL: `(max-width: ${size.laptopL})`,
    maxDesktop: `(max-width: ${size.desktop})`,
};
