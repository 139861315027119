import i18n from "i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import k from "../i18n/keys";
import "../_styles/toastStyles.css";

const useToast = () => {
    const options = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    };

    const toastSuccess = (message) => {
        toast.success(message, options);
    };
    const toastError = (message) => {
        if (typeof message === "string") {
            toast.error(message, options);
        } else {
            if (message?.message) {
                toast.error(message?.message, options);
            } else if (message?.General) {
                toast.error(message?.General, options);
            } else if (message) {
                if (message.length === 0) {
                    toast.error(i18n.t(k.ERROR_OCCURRED), options);
                } else {
                    Object.keys(message).forEach((field) => {
                        const errorMessages = message[field];
                        if (errorMessages && errorMessages.length > 0) {
                            const errorMessage = errorMessages[0];
                            toast.error(errorMessage, options);
                        }
                    });
                }
            } else {
                toast.error(i18n.t(k.ERROR_OCCURRED), options);
            }
        }
    };
    const toastInfo = (message) => {
        toast.info(message, options);
    };
    const toastWarning = (message) => {
        toast.warning(message, options);
    };

    return { toastSuccess, toastError, toastInfo, toastWarning };
};
export default useToast;
