const k = require("./keys");

export const romanian = {
    [k._]: `:`,
    [k._1]: ``,
    [k._10]: `4`,
    [k._11]: `5`,
    [k._12]: `6`,
    [k._13]: `06:00`,
    [k._14]: `10`,
    [k._15]: `1:`,
    [k._16]: `]`,
    [k._17]: `100%`,
    [k._2]: `-`,
    [k._3]: `/`,
    [k._4]: `.`,
    [k._5]: `(`,
    [k._6]: `12:00`,
    [k._7]: `1`,
    [k._8]: `2`,
    [k._9]: `3`,
    [k.ADD]: `Adaugă`,
    [k.ADD_ADDRESS]: `Adaugă adresă`,
    [k.ADD_ANOTHER_ROOM]: `Adaugă încă o cameră`,
    [k.ADD_NEW_ADDRESS]: `Adaugă o nouă adresă`,
    [k.ADDTOCART]: `Adaugă în coș`,
    [k.ANNUAL_ELECTRICITY_CONSUMPTION]: `Consum anual de energie electrică`,
    [k.ANNUAL_OPERATING_COSTS]: `Costuri anuale de operare`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS]: `Ești sigur că vrei să ștergi această adresă?`,
    [k.ARCHITECT]: `Arhitect`,
    [k.APARTMENT]: `Apartament`,
    [k.BACK]: `Înapoi`,
    [k.TRANSFER]: `Transfer`,
    [k.BANK_TRANSFER]: `Transfer bancar`,
    [k.BLOCKOFFLATS]: `Bloc`,
    [k.BUILDINGENERGYCLASS]: `Clasa energetică a clădirii`,
    [k.BUYBYMETER]: `Cumpăr pe metru`,
    [k.BUYONROLLS]: `Cumpăr pe role`,
    [k.BYMETERS]: `(pe metru)`,
    [k.CONFIRM]: `Confirmă`,
    [k.CZECH]: `Cehă`,
    [k.CALCULATECOSTS]: `Calculează costuri`,
    [k.CALCULATE_HEATING_COSTS]: `Calculează costurile de încălzire`,
    [k.CREATE_ACCOUNT]: `Creează cont`,
    [k.CANCEL]: `Anulează`,
    [k.CHOOSELANG]: `Alege limba`,
    [k.CHOOSE_NEXT_STEP]: `Alege următorul pas`,
    [k.COSTCALCULATION]: `Calcul costuri`,
    [k.CONSTRUCTION]: `Construcție`,
    [k.DELETE]: `Șterge`,
    [k.DEVELOPER]: `Dezvoltator`,
    [k.DISCOUNTGRANTED]: `Reducere acordată`,
    [k.DISCOUNTLEVEL]: `Nivelul tău actual de reducere`,
    [k.DISTRIBUTORS]: `Distribuitori`,
    [k.ELECTRICHEATERS]: `Radiatoare electrice`,
    [k.EMPLOYEE]: `Angajat`,
    [k.ENGLISH]: `Engleză`,
    [k.ENTER_ROOMS_DIMENSIONS]: `Introdu dimensiunile camerei`,
    [k.ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE]: `Introdu dimensiunile camerei pe baza figurii`,
    [k.ENTER_A_NEW_PASSWORD]: `Noua parolă trebuie să conțină cel puțin 8 caractere, o literă mică și una mare și un caracter special`,
    [k.FINISHED]: `Finalizat`,
    [k.FOILPOWER]: `Puterea foliei`,
    [k.FOILWIDTH]: `Lățimea foliei`,
    [k.FRENCH]: `Franceză`,
    [k.GERMAN]: `Germană`,
    [k.GROSS]: `brut`,
    [k.ELECRICITYPRICE]: `Prețul electricității (PLN / kWh)`,
    [k.ENERGYCLASS]: `Clasa energetică a clădirii (kWh / m2 pe an)`,
    [k.ENTER_INSTALLATION_PARAMS]: `Introdu parametrii instalației pentru a calcula consumul`,
    [k.FOR_CLIENT]: `Pentru client`,
    [k.GENERAL_HEATING_COST_CALCULATOR]: `Calculator general al costurilor de încălzire cu clasă
  energetică a clădirii:`,
    [k.LOWENERGY]: `A Eficient energetic`,
    [k.GOTOCASHREGISTER]: `Mergi la casă`,
    [k.HEATINGCOSTCALCULATOR]: `Calculator costuri de încălzire`,
    [k.HEATINGFOIL]: `Folie de încălzire`,
    [k.HEATINGMAT]: `Covor de încălzire`,
    [k.HOUSE]: `Casă`,
    [k.HOUSINGESTATE]: `Ansamblu rezidențial`,
    [k.INSTALLER]: `Instalator`,
    [k.I_ORDER_WITH_SHIPPING_TO_CUSTOMERS]: `Comand cu livrare la clienți`,
    [k.I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE]: `Comand pentru depozitul meu`,
    [k.ITALIAN]: `Italiană`,
    [k.HEATING_SYSTEM_POWER]: `Puterea sistemului de încălzire`,
    [k.INSTALLATIONDESIGN]: `Proiect de instalație`,
    [k.INVESTMENT_MATERIALS_COSTS]: `Costuri de materiale de investiție`,
    [k.INVESTORS]: `Investitori`,
    [k.INVESTMENTS]: `Investiții`,
    [k.KNOWLEDGEBASE]: `Bază de cunoștințe`,
    [k.LARGEQUANTITYINSTOCK]: `Cantitate mare în stoc`,
    [k.LEAVEPROJECT]: `Părăsește proiectul`,
    [k.LENGTH_IN_CM]: `Lungime în cm`,
    [k.LOGIN]: `Autentificare`,
    [k.LOG_IN]: `Conectează-te`,
    [k.LOGOUT]: `Deconectare`,
    [k.MANAGER]: `Manager`,
    [k.MARKETING]: `Marketing`,
    [k.MEDIUMENERGYSAVING]: `C Mediu eficient energetic`,
    [k.ENERGYSAVING]: `B Eficient energetic`,
    [k.MIN_PV_INSTALATION_REQUIRED]: `Instalație fotovoltaică minimă necesară
  pentru a compensa costurile de încălzire`,
    [k.MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL]: `produse și mergi la un nivel superior de reducere`,
    [k.NO]: `Nu`,
    [k.NODATA]: `Date indisponibile`,
    [k.NORWEGIAN]: `Norvegiană`,
    [k.ONROLLS]: `(pe role)`,
    [k.POLISH]: `Poloneză`,
    [k.MONTHLY_OPERATING_COSTS]: `Costuri lunare de operare`,
    [k.NET]: `net`,
    [k.NEWROOM]: `Cameră nouă`,
    [k.OFFERGENERATING]: `Generare ofertă`,
    [k.OFFERS]: `Oferte`,
    [k.ORDERS]: `Comenzi`,
    [k.ONLINE_PAYMENT]: `Plată online`,
    [k.PAYMENT_METHOD]: `Metodă de plată`,
    [k.OWN]: `Propriu`,
    [k.OWNER]: `Proprietar`,
    [k.PASSIVE_A_PLUS]: `A+ Pasiv`,
    [k.PHOTOVOLTAIC]: `Fotovoltaic`,
    [k.PRESENTED_CALC_ARE_INFORMATIVE]: `*Calculul prezentat este informativ și nu reprezintă o ofertă comercială în sensul art. 66 par. 1 din Codul Civil`,
    [k.PRICELIST]: `Listă de prețuri`,
    [k.PRODUCT]: `Produs`,
    [k.PRODUCTSINCART]: `Produse în coș`,
    [k.PRODUCTAVAILABLE]: `Produs disponibil`,
    [k.PROFIT]: `Profit`,
    [k.PROJECTS]: `Proiecte`,
    [k.PUBLICFACILITY]: `Clădire publică`,
    [k.RATED_ENERGY_CONSUMPTION]: `Consum nominal de energie`,
    [k.REJECT_CHANGES]: `Respinge modificările`,
    [k.REMIND_PASSWORD]: `Reamintește parola`,
    [k.REMEMBER_ME]: `Ține-mă minte`,
    [k.RETURN_TO_THE_LOGIN_PANEL]: `Înapoi la panoul de autentificare`,
    [k.QUANTITY]: `Cantitate`,
    [k.ROMANIAN]: `Română`,
    [k.SALE]: `Promoții`,
    [k.SAVEANDCLOSE]: `Salvează și închide`,
    [k.SAVECHANGES]: `Salvează modificările`,
    [k.SEARCHFORPRODUCTS]: `Caută produse`,
    [k.SEETHEPRODUCT]: `Vezi produsul`,
    [k.SETTINGS]: `Setări`,
    [k.SHOWCROSS_SECTION]: `Arată secțiunea transversală`,
    [k.SHOWLAYERS]: `Arată straturile`,
    [k.SLOVAK]: `Slovacă`,
    [k.SMALLQUANTITYINSTOCK]: `Cantitate mică în stoc`,
    [k.SOMETHING_WENT_WRONG]: `Ceva nu a mers bine:`,
    [k.SPAIN]: `Spaniolă`,
    [k.RELOADAPPLICATION]: `Reîncarcă aplicația`,
    [k.SWEDISH]: `Suedeză`,
    [k.THERMOSTAT]: `Termostat`,
    [k.THERMOSTATFUNCTIONS]: `Funcții ale termostatului`,
    [k.THERMOSTATS]: `Termostate`,
    [k.TOGETHER]: `Împreună`,
    [k.TOGETHERTOPAY]: `Total de plată`,
    [k.USABLEAREA]: `Suprafață utilă (m2)`,
    [k.ZEROENERGY]: `A++ Zero energetic`,
    [k.FLOOR]: `Pardoseală`,
    [k.CEILING]: `Tavan`,
    [k.WALL]: `Pereți`,
    [k.WAREHOUSE]: `Depozit`,
    [k.WHOLESALER]: `Angrosist`,
    [k.WT2021]: `WT2021`,
    [k.ADDROOM]: `Adaugă cameră`,
    [k.CHANGE]: `Schimbă`,
    [k.YES]: `Da`,
    [k.GOBACKTOCREATOR]: `Înapoi la creator`,
    [k.GOBACKTOCART]: `Înapoi la coș`,
    [k.SQUAREM]: `m²`,
    [k.PIECE]: `buc.`,
    [k.RUNNINGM]: `m.l.`,
    [k.COLOR]: `Culoare`,
    [k.UNDO]: `Anulează`,
    [k.REDO]: `Reface`,
    [k.ZOOMIN]: `Apropie`,
    [k.ZOOMOUT]: `Depărtează`,
    [k.MEASURE]: `Măsură`,
    [k.CLOSEPOLYGON]: `Închide poligon`,
    [k.CONTACTORREQUIRED]: `contactor necesar`,
    [k.WRONGLOGINORPASSWORD]: "Nume de utilizator sau parolă incorectă.",
    [k.WIDTH_IN_CM]: "Lățime în cm",
    [k.WIDTH]: "Lățime",
    [k.MISSINGUSER]: "Utilizatorul nu a fost găsit.",
    [k.PASSWORD]: "Parolă",
    [k.PASSWORDREQUIRED]: "Parola este necesară.",
    [k.CONFIRMPASSWORDMUSTBESAME]: "Parola de confirmare trebuie să fie aceeași.",
    [k.PASSWORDSHOULDBEMIN8CHARLONG]: "Parola trebuie să conțină cel puțin 8 caractere.",
    [k.PASSWORDPATTERNQUOTE]: "Parola trebuie să conțină cel puțin un: număr, literă mare, literă mică și un caracter special (de exemplu: @, #, &, !, $).",
    [k.PASSWORD_HAS_BEEN_RESET]: "Parola a fost resetată",
    [k.CALIBRATION]: "Calibrare",
    [k.READY]: "Gata",
    [k.EDIT]: "Editează",
    [k.START]: "Start",
    [k.UNFOLDAUTOMATICALLY]: "Desfășoară automat",
    [k.CUTTINGPOINT]: "Punct de tăiere",
    [k.TYPE]: "Tip",
    [k.FURNITURE]: "Mobilă",
    [k.BATH_SHOWERTRAY]: "Cadă/duș",
    [k.SINK]: "Chiuvetă/WC suspendat",
    [k.KITCHEN_FURNITURE]: "Mobilier de bucătărie",
    [k.FIREPLACE]: "Șemineu",
    [k.HOUSEHOLD_APPLIANCES]: "Electrocasnice",
    [k.YOUR_ADDRESSES]: "Adresele tale",
    [k.YOUR_NET_PRICE]: "Prețul tău net",
    [k.YOUR_GROSS_PRICE]: "Prețul tău brut",
    [k.OPTIMAL]: "Optimal",
    [k.VERTICALY]: "Vertical",
    [k.HORIZONTALY]: "Orizontal",
    [k.ALLWIDTHS]: "Toate lățimile",
    [k.DRAWOFFAREA]: "Dorești să adaugi elemente de construcție permanentă?",
    [k.INNER]: "Interior",
    [k.OUTER]: "Exterior",
    [k.OTHER]: "Altele",
    [k.ROOM]: "Cameră",
    [k.BATHROOM]: "Baie",
    [k.BASEMENT]: "Subsol",
    [k.GROUND_FLOOR]: "Parter",
    [k.FIRST_FLOOR]: "Etajul întâi",
    [k.ATTIC]: "Mansardă",
    [k.STOREY]: "Etaj",
    [k.LEVEL]: "Nivel",
    [k.ADDFILE]: "Adaugă fișier",
    [k.DRAWPLAN]: "Desenează plan",
    [k.LIVINGROOM]: "Living",
    [k.KITCHEN]: "Bucătărie",
    [k.VESTIBULE]: "Vestibul",
    [k.BEDROOM]: "Dormitor",
    [k.DININGROOM]: "Sală de mese",
    [k.WARDROBE]: "Dulap",
    [k.TOILET]: "Toaletă",
    [k.LAUNDRY]: "Spălătorie",
    [k.HALL]: "Hol",
    [k.CABINET]: "Birou",
    [k.ANTEROOM]: "Antreu",
    [k.CORRIDOR]: "Coridor",
    [k.PANTRY]: "Cămara",
    [k.LIVING_DINING_ROOM]: "Living + sală de mese",
    [k.LIVINGROOM_WITH_KITCHENETTE]: "Living cu chicinetă",
    [k.GARAGE]: "Garaj",
    [k.TECHNICALROOM]: "Cameră tehnică",
    [k.UTILITYROOM]: "Cameră de utilități",
    [k.RECREATIONROOM]: "Cameră de recreere",
    [k.ENTRESOL]: "Mezanin",
    [k.SAUNA]: "Saună",
    [k.BOILERROOM]: "Cameră de cazan",
    [k.CUBBY]: "Depozit",
    [k.STAIRS]: "Scări",
    [k.FIREPLACE_ROOM]: "Cameră cu șemineu",
    [k.GO_BACK_EDIT]: "Înapoi la editare",
    [k.GO_FURTHER]: "Continuă",
    [k.DRAW_NEXT]: "Desenează următorul",
    [k.START_DESIGNING]: "Începe proiectarea",
    [k.IN_THE_WIZARD_HEAT_DECOR]: "în creatorul Heat Decor",
    [k.YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS]: "În câțiva pași simpli, poți crea un proiect de încălzire cu infraroșu.",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Introdu adresa de e-mail furnizată la crearea contului.",
    [k.WHAT_WILL_YOU_GAIN_BY_WORKING]: "Ce câștigi lucrând",
    [k.WITH_THE_HEAT_DECOR_CREATOR]: "cu creatorul Heat Decor?",
    [k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE]: "• Proiecte precise ale sistemului de încălzire în",
    [k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY]: "standardul Heat Decor, gata de utilizare",
    [k.THE_INSTALLATION_TEAM]: "de echipa de instalare",
    [k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR]: "• Pregătirea mai ușoară și mai completă a unei oferte pentru",
    [k.YOUR_CLIENT]: "clientul tău",
    [k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS]: "• Facilitarea procesului de comandare a produselor",
    [k.HEAT_DECOR]: "Heat Decor",
    [k.WE_ARE_CHANGING_THE_FUTURE_FOR]: "Schimbăm viitorul pentru",
    [k.GREEN]: "verde",
    [k.WATCH_HEAT_DECOR]: "Urmărește Heat Decor",
    [k.READ_AND_SUBSCRIBE]: "citește și abonează-te",
    [k.BLOG]: "blog",
    [k.PRIMARY_USER]: "Utilizator principal:",
    [k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES]: "• Are acces la toate funcționalitățile creatorului.",
    [k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR]:
        "• Definirea rolului în structura companiei va facilita cooperarea ulterioară cu Heat Decor.",
    [k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS]: "• Specificarea tipului de companie va permite ajustarea creatorului la nevoile tale.",
    [k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER]: "• După înregistrare, Heat Decor va contacta utilizatorul principal indicat.",
    [k.ADDITIONAL_USERS]: "Utilizatori suplimentari:",
    [k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS]: "• Pot folosi creatorul pentru a dezvolta proiecte și oferte pentru clienții lor.",
    [k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES]: "• Au capacități limitate de editare a contului.",
    [k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD]:
        "În caz de dificultăți repetate cu utilizarea creatorului, contactează administratorul creatorului Heat Decor:",
    [k.EMAIL]: "Email",
    [k.TELEPHONE]: "Telefon:",
    [k.PRIVACY_POLICY]: "Politica de confidențialitate",
    [k.WEBSITE_REGULATIONS]: "Regulamentul site-ului",
    [k.THANK_YOU_FOR_YOUR_REGISTRATION]: "Îți mulțumim pentru înregistrare",
    [k.LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY]: "Autentificarea va fi posibilă după",
    [k.SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE]: "setarea parolei și verificarea contului de către",
    [k.ADMINISTRATOR]: "Administrator.",
    [k.YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD]: "Vei primi un link pentru a seta o nouă parolă.",
    [k.EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS]: "Email-ul a fost trimis. Urmează instrucțiunile",
    [k.IN_THE_EMAIL]: " incluse în email.",
    [k.CHECK_EMAIL]: "Verifică email-ul",
    [k.TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION]:
        "Pentru a finaliza procesul de înregistrare, verifică mesajul trimis automat pe adresa de email furnizată și fă clic pe linkul pentru a confirma înregistrarea.",
    [k.REGISTRATION_ERROR]: "A apărut o eroare la înregistrare",
    [k.AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT]: "A apărut o eroare la crearea unui nou cont.",
    [k.TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US]:
        "Dacă eroarea persistă, încearcă să te înregistrezi cu o altă adresă de email sau contactează-ne.",
    [k.ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION]: "Introdu informațiile utilizatorului principal al contului.",
    [k.REGISTRATION]: "Înregistrare",
    [k.ADDITIONAL_USERS_OPTIONAL]: "Utilizatori suplimentari (opțional)",
    [k.INVESTMENT]: "Investiție",
    [k.INVESTORS_NAME]: "Numele investitorului:",
    [k.ADDED_BY]: "Adăugat de:",
    [k.NOTES]: "Note",
    [k.STREET]: "Stradă",
    [k.CITY]: "Oraș",
    [k.ZIP_CODE]: "Cod poștal",
    [k.GROSS]: "brut",
    [k.NET_PRICE_FOR_THE_CLIENT]: "Preț net pentru client:",
    [k.NET_PROFIT]: "Profit net:",
    [k.DOWNLOAD_MULTIPLE_PDF_FILES]: "Descarcă mai multe fișiere PDF",
    [k.DELETE_PROJECTS]: "Șterge proiecte",
    [k.ADD_TO_CART]: "Adaugă în coș",
    [k.REMOVE_OFFERS]: "Șterge oferte",
    [k.GENEROWANIE_OFERTY]: "Generarea ofertei",
    [k.RESET_PASS]: "Resetează parola",
    [k.VALUE_OF_THE_OFFER]: "Valoarea ofertei",
    [k.YOUR_COST]: "Costul tău",
    [k.YOUR_PRICE]: "Prețul tău",
    [k.THE_INVESTOR_PAYS]: "Investitorul plătește",
    [k.YOU_EARN]: "Câștigi",
    [k.GO_ON]: "Continuă",
    [k.ENTER_NEW_PASSWORD]: "Introdu o nouă parolă",
    [k.NEW_PASSWORD]: "Parolă nouă",
    [k.PRICE]: "Preț",
    [k.PRICE_FOR_THE_CLIENT]: "Preț pentru client:",
    [k.A_PRICE_FOR_YOU]: "Preț pentru tine:",
    [k.INCORRECT_LOGIN_OR_PASSWORD]: "Login sau parolă incorectă",
    [k.OFFER]: "Ofertă",
    [k.BY]: "de",
    [k.LIST_OF_ORDERS]: "Lista comenzilor",
    [k.THANK_YOU_FOR_YOUR_ORDER]: "Mulțumim pentru comandă",
    [k.GO_TO_ORDERS]: "Mergi la comenzi",
    [k.SMS_CODE]: "Cod SMS",
    [k.INCORRECT_AUTHORIZATION_CODE]: "Cod de autorizare incorect",
    [k.ENTER_AUTHORIZATION_CODE]: "Introdu codul de autorizare",
    [k.CONFIRMED]: "Confirmat",
    [k.RESEND_CODE]: "Trimite codul din nou",
    [k.REENTER_THE_6_DIGIT_CODE]: "Reintrodu codul SMS de 6 cifre nr",
    [k.TO_VERIFY_YOUR_ACCOUNT]: "pentru a verifica contul",
    [k.ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT]: "Introdu codul SMS de 6 cifre pentru a verifica contul",
    [k.NR]: "nr",
    [k.GO_TO_PAYMENT]: "Mergi la plată",
    [k.ORDERED_PRODUCTS]: "Produse comandate",
    [k.NOTES_TO_THE_ORDER]: "Note la comandă",
    [k.NO_PRODUCTS]: "Fără produse",
    [k.THERE_ARE_NO_PRODUCTS_IN_YOUR_CART]: "Nu sunt produse în coș",
    [k.NO_OFFERS]: "Fără oferte",
    [k.YOUR_CART]: "Coșul tău",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER]: "Comanda ta cu livrare la client",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE]: "Comanda ta cu livrare la depozitul tău",
    [k.PAY_FOR_YOUR_ORDER]: "Plătește pentru comandă",
    [k.PHONE]: "Telefon",
    [k.SHIPPING_TO]: "Expediere către",
    [k.SAVE]: "Salvează",
    [k.EMAIL_IS_REQUIRED]: "Emailul este necesar",
    [k.CODE_AND_CITY_ARE_REQUIRED]: "Codul și orașul sunt necesare",
    [k.POSTAL_CODE_AND_CITY]: "Cod poștal și oraș",
    [k.STREET_AND_BUILDING_NUMBER_ARE_REQUIRED]: "Strada și numărul clădirii sunt necesare",
    [k.STREET_AND_NUMBER]: "Stradă și număr",
    [k.NAME_AND_SURNAME]: "Nume și prenume",
    [k.DISCOUNT]: "Reducere",
    [k.SUM]: "Sumă",
    [k.YOU_WANT_TO_GIVE_A_DISCOUNT_ON_ALL_MATERIAL]: "Dorești să oferi o reducere pentru tot materialul",
    [k.ALL_MATERIAL_DISCOUNT]: "Reducere pentru tot materialul",
    [k.SORRY_NOTHING_HERE]: "Ne pare rău... nu este nimic aici.",
    [k.GO_HOME]: "Mergi la pagina principală",
    [k.I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN]: "Înțeleg, nu arăta la următoarea autentificare",
    [k.DISTRIBUTOR]: "Distribuitor",
    [k.GENERATE_REPORT]: "Generează raport",
    [k.SKETCH]: "Schita",
    [k.GENERATE_AN_OFFER]: "Generează ofertă",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT]: "Ești sigur că vrei să ștergi proiectul selectat?",
    [k.FIND_A_PROJECT]: "Găsește un proiect",
    [k.THERE_IS_NO_SUCH_PROJECT]: "Nu există un astfel de proiect",
    [k.YOU_DONT_HAVE_ANY_PROJECT_YET]: "Nu ai încă niciun proiect",
    [k.YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT]: "Trebuie să ai un investitor și o investiție pentru a crea un proiect pentru aceasta",
    [k.DO_YOU_WANT_TO_CREATE_AN_INVESTOR]: "Vrei să creezi un investitor",
    [k.GETTING_STARTED_WITH_ADDING_AN_INVESTOR]: "Începerea lucrului începe cu adăugarea unui investitor",
    [k.ADD_AN_INVESTOR]: "Adaugă investitor",
    [k.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN]: "Ceva a mers greșit, încearcă din nou",
    [k.SELECT_THE_TYPE_OF_ROOM]: "Selectează tipul camerei",
    [k.GO_BACK_TO_EDITING]: "Înapoi la editare",
    [k.TITLE]: "Titlu",
    [k.GROUP]: "Grup",
    [k.GENERAL]: "General",
    [k.HEATING_FOIL]: "Folie de încălzire",
    [k.ELECTRIC_HEATERS]: "Radiatoare electrice",
    [k.HEATING_MAT]: "Covoraș de încălzire",
    [k.IMAGE]: "Imagine",
    [k.DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT]: "Trage și plasează imaginea sau fă clic și selectează",
    [k.PREVIEW]: "Previzualizare",
    [k.TEXT_EDITOR]: "Editor de text",
    [k.SAVE_CHANGES]: "Salvează modificările",
    [k.EXIT]: "Ieși",
    [k.ADD_A_NEW_COMPANY]: "Adaugă o companie nouă",
    [k.GO_BACK_TO_THE_LIST]: "Înapoi la listă",
    [k.FIRST_NAME]: "Prenume",
    [k.LAST_NAME]: "Nume de familie",
    [k.USER]: "Utilizator",
    [k.IS_REQUIRED]: "este necesar",
    [k.FIRST_NAME_IS_REQUIRED]: "Prenumele este necesar",
    [k.LAST_NAME_IS_REQUIRED]: "Numele de familie este necesar",
    [k.PHONE_IS_REQUIRED]: "Telefonul este necesar",
    [k.EMAIL_IS_INVALID]: "Emailul este invalid",
    [k.ADD_USER]: "Adaugă utilizator",
    [k.REGISTER]: "Înregistrează-te",
    [k.COUNTRY]: "Țară",
    [k.PHONE_NUMBER]: "Număr de telefon",
    [k.PREFIX_IS_REQUIRED]: "Prefixul este necesar",
    [k.NEXT_STEP]: "Următorul pas",
    [k.TAX_ID_IS_REQUIRED]: "ID fiscal este necesar",
    [k.COUNTRY_IS_REQUIRED]: "Țara este necesară",
    [k.TAX_ID]: "ID fiscal",
    [k.TAX_ID_IS_INCORRECT]: "ID-ul fiscal este incorect",
    [k.ZIP_CODE_IS_REQUIRED]: "Codul poștal este necesar",
    [k.CITY_IS_REQUIRED]: "Orașul este necesar",
    [k.HOUSE_NUMBER_IS_REQUIRED]: "Numărul casei este necesar",
    [k.NUMBER]: "Număr",
    [k.DOCUMENT_NUMBER]: "Număr document",
    [k.DOWNLOAD]: "Descarcă",
    [k.WAS_THIS_ARTICLE_HELPFUL]: "A fost acest articol de ajutor?",
    [k.MIN_READING]: "min de citire",
    [k.SEE_MORE]: "VEZI MAI MULT",
    [k.ADDRESS]: "Adresă",
    [k.DEFAULT_ADDRESS]: "Adresă implicită",
    [k.NAME]: "Nume",
    [k.NAME_OF_THE_INVESTMENT]: "Numele investiției",
    [k.SEARCH_CRITERIA]: "Criterii de căutare",
    [k.WHO_ADDED]: "Cine a adăugat",
    [k.IS_IT_A_COMPANY]: "Este o companie",
    [k.BUSINESS]: "Companie",
    [k.NATURAL_PERSON]: "Persoană fizică",
    [k.YOU_DONT_HAVE_ANY_INVESTOR_YET]: "Nu ai încă niciun investitor",
    [k.DO_YOU_WANT_TO_ADD_IT_NOW]: "Vrei să-l adaugi acum",
    [k.THERE_IS_NO_SUCH_INVESTOR]: "Nu există un astfel de investitor",
    [k.YOU_DONT_HAVE_ANY_INVESTMENTS_YET]: "Nu ai încă nicio investiție",
    [k.ADD_YOUR_FIRST_INVESTMENT]: "Adaugă prima ta investiție",
    [k.THERE_IS_NO_SUCH_INVESTMENT]: "Nu există o astfel de investiție",
    [k.ADD_AN_INVESTMENT]: "Adaugă investiție",
    [k.INVESTOR]: "Investitor",
    [k.CONTACT_PERSON]: "Persoană de contact",
    [k.STREET_IS_REQUIRED]: "Strada este necesară",
    [k.NO_IS_REQUIRED]: "Nr. este necesar",
    [k.CODE_IS_REQUIRED]: "Codul este necesar",
    [k.THE_ENERGY_CLASS_IS_REQUIRED]: "Clasa energetică este necesară",
    [k.CUSTOM_ENERGY_CLASS_IS_REQUIRED]: "Clasa energetică personalizată este necesară",
    [k.GENERATE_OFFERS]: "Generează oferte",
    [k.THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT]: "Oferta este deja în coș, ești sigur că vrei să o ștergi?",
    [k.COMPANY_NAME_IS_REQUIRED]: "Numele companiei este obligatoriu",
    [k.THE_ADDRESS_IS_REQUIRED]: "Adresa este obligatorie",
    [k.THE_ZIP_CODE_IS_INCORRECT]: "Codul poștal este incorect",
    [k.THE_CITY_IS_NOT_CORRECT]: "Orașul nu este corect",
    [k.THE_HOUSE_NUMBER_IS_INCORRECT]: "Numărul casei este incorect",
    [k.COMPANY_NAME]: "Numele companiei",
    [k.PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED]: "Prefixul și numărul de telefon sunt obligatorii",
    [k.POSTAL_CODE_AND_CITY_ARE_REQUIRED]: "Codul poștal și orașul sunt obligatorii",
    [k.STREET_AND_HOUSE_NUMBER_ARE_REQUIRED]: "Strada și numărul clădirii sunt obligatorii",
    [k.FULL_NAME_IS_REQUIRED]: "Numele complet este obligatoriu",
    [k.REQUIRED_FIELD]: "Câmp obligatoriu",
    [k.REQUIRED]: "Obligatoriu",
    [k.ADD_A_PROJECT]: "Adaugă un proiect",
    [k.PROJECT_NAME_IS_REQUIRED]: "Numele proiectului este obligatoriu",
    [k.THE_BUILDING_NUMBER_IS_REQUIRED]: "Numărul clădirii este obligatoriu",
    [k.No]: "Nr.",
    [k.ADDITIONAL_CONTACT_PERSON]: "Persoană de contact suplimentară",
    [k.CLASS_ENERGY_IS_REQUIRED]: "Clasa energetică este obligatorie",
    [k.ENERGY_CLASS]: "Clasa energetică",
    [k.THE_STREET_IS_NOT_CORRECT]: "Strada nu este corectă",
    [k.HOUSE_NUMBER]: "Număr casă",
    [k.TYPE_OF_INVESTMENT]: "Tipul investiției",
    [k.CODE]: "Cod",
    [k.THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN]: "Investiția nu a fost adăugată. Încearcă din nou",
    [k.COMPANY_TYPE]: "Tipul companiei",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE]: "Număr de telefon pentru verificarea contului cu un cod SMS",
    [k.GRAPHICS]: "Grafică",
    [k.GO_BACK_TO_LOGIN]: "Înapoi la autentificare",
    [k.ADD_A_NEW_INVESTMENT]: "Adaugă o nouă investiție",
    [k.GO_BACK_TO_THE_LIST_OF_INVESTMENTS]: "Înapoi la lista de investiții",
    [k.ADD_A_NEW_INVESTOR]: "Adaugă un nou investitor",
    [k.COMPLETE_THE_INVESTOR_PROFILE]: "Completează profilul investitorului",
    [k.GO_BACK_TO_INVESTORS]: "Înapoi la investitori",
    [k.PROJECT_NAME]: "Numele proiectului",
    [k.WHAT_DO_YOU_WANT_TO_DESIGN]: "Ce dorești să proiectezi",
    [k.UPLOAD_THE_FILE_WITH_THE_VIEW]: "Încarcă fișierul cu planul",
    [k.ADD_FILE]: "ADAUGĂ FIȘIER",
    [k.DISCARD_CHANGES]: "Renunță la modificări",
    [k.DRAW_ANY_ROOM_PLAN]: "Desenează orice plan de cameră",
    [k.GENERATE_AS_IMAGE]: "Generează ca imagine",
    [k.DRAW_PLAN]: "DESENEAZĂ PLAN",
    [k.CREATE_A_PROJECT]: "Creează un proiect",
    [k.CREATE_A_THROW_IN_THE_WIZARD]: "Creează un plan în asistent",
    [k.COME_BACK]: "Înapoi",
    [k.HEATING_MAT_UNDER_THE_TILES]: "Covor de încălzire sub plăci",
    [k.HEATING_FOIL_UNDER_THE_SPOUT]: "Folie de încălzire sub șapă",
    [k.HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS]: "Folie de încălzire sub panouri vinil SPC",
    [k.HEATING_FOIL_FOR_LAMINATED_PANELS]: "Folie de încălzire pentru panouri laminate",
    [k.SELECT_A_HEATING_SYSTEM]: "Selectează un sistem de încălzire",
    [k.GIVE_THE_REAL_DIMENSION]: "INDICĂ DIMENSIUNEA REALĂ",
    [k.ENTER_THE_REAL_LENGTH]: "Introduceți lungimea reală",
    [k.DESIGNATED_EPISODE]: "segment desemnat",
    [k.SEE_PREVIEW]: "Vezi previzualizarea",
    [k.ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT]: "Ajustează planul prin tăiere și rotire",
    [k.THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA]: "Nu există produse pentru criteriile date",
    [k.CHOOSE_A_MODEL]: "ALEGE UN MODEL",
    [k.PLN_GROSS]: "PLN brut",
    [k.FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM]: "Pentru calibrare, marchează cea mai lungă latură a camerei",
    [k.STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM]: "Pasul 1: Desenează conturul camerei",
    [k.SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT]: "Selectează tipul elementului fix de construcție",
    [k.STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION]: "Pasul 2: Aplică suprafețele excluse pentru construcție permanentă",
    [k.ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL]: "Adaugă un strat de folie barieră de vapori",
    [k.PLACE_THE_THERMOREGULATOR]: "Plasează termoregulatorul pe marginea camerei",
    [k.SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING]: "Selectează clasa energetică a clădirii",
    [k.THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD]: "Proiectul are modificări nesalvate. Ești sigur că vrei să ieși din asistent?",
    [k.DO_YOU_WANT_TO_SAVE_THE_CHANGES]: "Vrei să salvezi modificările?",
    [k.CONFIRM_NEW_PASSWORD]: "Confirmă noua parolă",
    [k.SET_A_PASSWORD]: "Setează o parolă",
    [k.THE_PHONE_IS_NOT_VALID]: "Telefonul nu este valid",
    [k.MANAGE_ADDRESSES]: "Gestionează adresele",
    [k.PLEASE_SELECT_A_SHIPPING_ADDRESS]: "Selectează o adresă de livrare",
    [k.ADD_A_COMPANY]: "Adaugă o companie",
    [k.SEARCH_FOR_A_COMPANY]: "Caută o companie",
    [k.MESSAGE]: "Mesaj",
    [k.NO_EMAIL_ADDRESS]: "Fără adresă de email",
    [k.EDIT_CUSTOMER_DETAILS]: "Editează detaliile clientului",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY]: "Ești sigur că vrei să ștergi această companie?",
    [k.RULER_CALIBRATION]: "Calibrare riglă",
    [k.CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW]: "Calibrează instrumentul pentru a învăța programul de calcul al distanțelor pentru planul tău",
    [k.SEE_AN_EXAMPLE]: "Vezi un exemplu",
    [k.CALCULATE_YOUR_HEATING_COSTS]: "Calculează costurile de încălzire",
    [k.THE_AREA_OF_THE_ROOM]: "Suprafața camerei",
    [k.PERMANENT_BUILDINGS]: "Construcții permanente",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM]: "Ești sigur că vrei să ștergi camera?",
    [k.THERMOREGULATORS]: "Termoregulatoare",
    [k.FLOOR_SENSOR]: "Senzor de pardoseală",
    [k.INDOOR_AIR_SENSOR]: "Senzor de aer interior",
    [k.OUTSIDE_AIR_SENSOR]: "Senzor de aer exterior",
    [k.OPTIONAL]: "Opțional",
    [k.PROGRAMMING]: "Programare",
    [k.LEVEL_OF_SECURITY]: "Nivel de siguranță",
    [k.CONTROL_PANEL]: "Panou de control",
    [k.TOUCH]: "Tactil",
    [k.KEYBOARD]: "Tastatură",
    [k.KNOB]: "Buton rotativ",
    [k.FIND_OUT_MORE]: "Află mai multe",
    [k.CHOOSE]: "Alege",
    [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART]: "Ești sigur că vrei să elimini acest produs din coș?",
    [k.FILTER]: "FILTRARE",
    [k.CUTTING]: "Tăiere",
    [k.POWER]: "Putere",
    [k.MAX_TEMP]: "Temp. max",
    [k.AREA]: "Suprafață",
    [k.HEATING_MAT_POWER]: "Puterea covorașului de încălzire",
    [k.HEIGHT]: "Înălțime",
    [k.THICKNESS]: "Grosime",
    [k.PRODUCT_PARAMETERS]: "PARAMETRII PRODUSULUI",
    [k.LENGTH]: "Lungime",
    [k.HEATING_POWER]: "Putere de încălzire",
    [k.FOIL_SURFACE]: "Suprafața foliei",
    [k.MAX_HEATING_TEMPERATURE]: "Temp. maximă de încălzire",
    [k.THE_WIDTH_OF_THE_FILM]: "Lățimea foliei",
    [k.POSSIBILITY_TO_CUT_THE_FILM_CO]: "Posibilitatea de a tăia folia la fiecare",
    [k.MAT_AREA]: "Suprafața covorașului",
    [k.THERMOSTAT_MODEL]: "Modelul termostatului",
    [k.RESISTANCE]: "Rezistență",
    [k.FOIL_MODEL]: "Modelul foliei",
    [k.BRAND]: "Marcă",
    [k.WEIGHT]: "Greutate",
    [k.FOIL_GUARANTEE]: "Garanția foliei",
    [k.CONNECTION_KITS_PCS]: "Kituri de conectare [buc.]",
    [k.WIRES]: "Cabluri",
    [k.TAPE_PCS]: "Bandă [buc.]",
    [k.THERMOSTAT_WARRANTY]: "Garanția termostatului",
    [k.CONNECTOR]: "Conector",
    [k.MAT_GUARANTEE]: "Garanția covorașului",
    [k.PRO_TAPE_PCS]: "Bandă PRO [buc.]",
    [k.PRODUCT_DESCRIPTION]: "Descrierea produsului",
    [k.RETURN_TO_THE_KNOWLEDGE_BASE]: "Înapoi la baza de cunoștințe",
    [k.SIMILAR_ARTICLES]: "Articole similare",
    [k.NUMBER_OF_INVESTMENTS]: "Numărul de investiții",
    [k.VIEW_PROFILE]: "Vezi profilul",
    [k.LATEST_INVESTMENTS]: "Cele mai recente investiții",
    [k.THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT]: "Nu există produse în proiect",
    [k.BILL_OF_MATERIALS_FOR_PROJECTS]: "Lista materialelor pentru proiecte",
    [k.YOUR_CALCULATION_WITHOUT_ASSEMBLY]: "CALCULUL TĂU FĂRĂ MONTAJ",
    [k.YOU_WANT_TO_ADD_A_MONTAGE]: "Vrei să adaugi un montaj",
    [k.NUMBER_OF_KILOMETERS]: "Numărul de kilometri",
    [k.PRICE_FOR_ASSEMBLY]: "Preț pentru montaj",
    [k.YOUR_CALCULATION_WITH_ASSEMBLY]: "CALCULUL TĂU CU MONTAJ",
    [k.CHECK_WHAT_RATE_YOU_ARE_AT]: "Verifică la ce rată ești",
    [k.SHOW_HEATING_COSTS]: "Afișează costurile de încălzire",
    [k.USABLE_AREA]: "Suprafață utilă",
    [k.SELECTED_ENERGY_CLASS]: "Clasa energetică selectată",
    [k.THE_PRICE_OF_ELECTRICITY]: "Prețul electricității",
    [k.HEATING_PRICE_IN_KWH_YEAR]: "Prețul încălzirii în kWh/an",
    [k.SAVE_AND_GENERATE_AN_OFFER]: "Salvează și generează o ofertă",
    [k.NUMBER_OF_PROJECTS]: "Numărul de proiecte",
    [k.GO_TO_INVESTMENT]: "Mergi la investiție",
    [k.LATEST_PROJECTS]: "Cele mai recente proiecte",
    [k.ASSEMBLY]: "Asamblare",
    [k.START_TYPING]: "Începe să scrii",
    [k.ORDERS_PLACED]: "Comenzi plasate",
    [k.NO_ORDERS_PLACED]: "Nicio comandă plasată",
    [k.SUBMIT]: "Trimite",
    [k.SAYS]: "spune",
    [k.ROTATE]: "Rotește",
    [k.SCALE]: "Scară",
    [k.ADD_A_NEW_PROJECT]: "Adaugă un proiect nou",
    [k.GO_BACK_TO_PROJECTS]: "Înapoi la proiecte",
    [k.SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED]: "Selectează partea pe care se află planul",
    [k.STEP]: "Pas",
    [k.CREATED_BY]: "creat de",
    [k.INVESTOR_NAME]: "Numele investitorului",
    [k.YOU_DONT_HAVE_ANY_OFFER_YET]: "Nu ai nicio ofertă încă",
    [k.CURRENT_VIEW_CREATOR_SAVED]: `Apăsând butonul "SALVEAZĂ ȘI FINALIZEAZĂ", vizualizarea curentă din asistent va fi salvată în oferta PDF. În caz contrar, vizualizarea nu va fi salvată.`,
    [k.ENTER_DWG_FILE_SIZE]: `Introdu dimensiunea fișierului DWG.`,
    [k.CREATED_BY2]: `Creat de`,
    [k.DO_YOU_WANT_TO_SAVE_CHANGES]: `Vrei să salvezi modificările?`,
    [k.NUMBER_OF_ROOMS]: `Număr de camere`,
    [k.FOIL]: "Folie",
    [k.MAT]: "Covoraș",
    [k.NETTO_PRICE]: "Preț net",
    [k.QUANTITY_PCS_M]: "Cantitate buc./ml",
    [k.NET_VALUE]: "Valoare netă",
    [k.MATERIAL_COST]: "Costul materialului",
    [k.INVESTOR_COST]: "Costul investitorului",
    [k.ADD_NOTE]: "Adaugă o notă",
    [k.REMOVE_PROJECT_FROM_OFFER]: "Șterge proiectul din ofertă",
    [k.COVERING_AREA_OF_ROOM]: "Acoperirea suprafeței camerei",
    [k.SEE_ASSORTMENT]: "Vezi sortimentul",
    [k.LP]: "Nr. crt.",

    [k.SUMMARY]: "Rezumat",
    [k.THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION]: "Comanda a fost transmisă pentru execuție",
    [k.THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB]: "Statusul comenzii poate fi urmărit în fila Comenzi",
    [k.THANK_YOU_FOR_YOUR_TRUST]: "Îți mulțumim pentru încredere",

    [k.TO_PAY]: "De plată",
    [k.SHIPPING_TO_YOUR_OWN_WAREHOUSE]: "Expediere către propriul depozit",
    [k.SHIPPING_TO_THE_CUSTOMER]: "Expediere către client",
    [k.YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER]:
        "Ai primit o reducere suplimentară datorată combinării a două investiții într-o singură comandă",
    [k.SHIPPING_ADDRESS]: "Adresă de livrare",
    [k.ADDRESSEE]: "Destinatar",
    [k.WAREHOUSE_ADDRESS]: "Adresă depozit",
    [k.CONTACT]: "Contact",
    [k.CHOOSE_PAYMENT_METHOD]: "Alege metoda de plată",
    [k.TRADITIONAL_BANK_TRANSFER]: "Transfer bancar tradițional",
    [k.PAYMENT_DEADLINE]: "Termen de plată",
    [k.CASH_ON_DELIVERY]: "Ramburs",
    [k.INVESTMENT_DATA]: "Datele investiției",
    [k.DELIVERY_ADDRESS]: "Adresă de livrare",
    [k.LIST_OF_PRODUCTS]: "Listă de produse",
    [k.PRODUCT_NAME]: "Numele produsului",
    [k.AMOUNT_OF_PRODUCTS]: "Cantitate produse",
    [k.NET_PRICE]: "Preț net",
    [k.GROSS_PRICE]: "Preț brut",
    [k.INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST]:
        "Info: Dacă dorești să adaugi material de rezervă la investiție, apasă editare în colțul din dreapta sus al listei de produse.",
    [k.INVESTMENT_ADDRESS]: "Adresă investiție",
    [k.BANK_TRANSFER_DETAILS]: "Detalii transfer bancar",
    [k.RECIPIENT]: "Beneficiar",
    [k.ACCOUNT_NUMBER]: "Număr de cont",
    [k.DOWNLOAD_PDF]: "Descarcă PDF",
    [k.GO_TO_ORDER]: "Mergi la comandă",
    [k.CHOOSE_A_BANK]: "Alege o bancă",
    [k.THE_VALUE_OF_THE_MATERIALS]: "Valoarea materialelor",
    [k.SHOPPING_CART_ITEMS]: "Articole în coș",
    [k.INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER]: "Info: Pentru a edita adresa de livrare, apasă pe creionul din colțul din dreapta sus",
    [k.NO_ORDERS]: "Nicio comandă",
    [k.STATUS_OF_THE_ORDER]: "Statusul comenzii",
    [k.DATE]: "Data",
    [k.SHIPPING_NO]: "Nr. transport",
    [k.DOWNLOAD_THE_INVOICE]: "Descarcă factura",
    [k.MORE_INFORMATION]: "Mai multe informații",
    [k.SHIPPING_INFORMATION]: "Informații despre livrare",
    [k.ORDER_STATUS]: "Status comandă",
    [k.CONTACT_PERSON2]: "Persoană de contact",
    [k.ORDER_NOTES]: "Observații comandă (opțional)",
    [k.QUOTA]: "Cota",
    [k.GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT]: "Începerea unui proiect începe cu adăugarea unei investiții",
    [k.DUPLICATE]: "Duplicat",
    [k.ROOMS]: "Camere",
    [k.COMPLETED_OFFERS]: "Oferte finalizate",
    [k.NO_PROJECTS]: "Niciun proiect",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS]: "Ești sigur că vrei să ștergi proiectele selectate?",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS]: "Ești sigur că vrei să ștergi ofertele selectate?",
    [k.COMPANY]: "Companie",
    [k.YOUR_ADVANTAGE_IN_ASSEMBLY]: "Avantajul tău la asamblare",
    [k.YOUR_BENEFIT_ON_THE_MATERIAL]: "Beneficiul tău pe material",
    [k.ADDED_TO_CART]: "Adăugat în coș",
    [k.CREATED]: "Creat",
    [k.PENDING]: "În așteptare",
    [k.COMPLETE]: "Complet",
    [k.REJECTED]: "Respins",
    [k.GO_TO_OFFERS]: "Mergi la oferte",
    [k.DONE_WITH_INSTALLATION]: "Finalizat cu instalare",
    [k.DONE_WITHOUT_INSTALLATION]: "Finalizat fără instalare",
    [k.THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED]: "Numele investiției este obligatoriu",
    [k.INVESTOR_DATA]: "Date investitor",
    [k.USERS]: "Utilizatori",
    [k.RULES]: "Reguli",
    [k.CONTACT_DETAILS]: "Detalii de contact",
    [k.THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER]: "Logo-ul va fi utilizat pe oferte și facturi pentru client",
    [k.UPLOAD_THE_FILE]: "Încarcă fișierul",
    [k.UPLOAD_THE_LOGO_FILE]: "Încarcă fișierul cu logo",
    [k.MAX_SIZE]: "Dimensiune maximă",
    [k.MAX_DIMENSIONS]: "Dimensiuni maxime",
    [k.CURRENT_FILE]: "Fișier curent",
    [k.NO_PHOTO]: "Fără fotografie",
    [k.DELETE_FILE]: "Șterge fișierul",
    [k.WIZARD_LANGUAGE]: "Limba asistentului",
    [k.COMPANY_LOGO]: "Logo companie",
    [k.MAIN_USER]: "Utilizator principal",
    [k.OTHER_USERS]: "Alți utilizatori",
    [k.ADD_NEW_PROFILE]: "adaugă un profil nou",
    [k.CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM]: "S-au făcut modificări, dorești să le salvezi?",
    [k.DISCARD]: "Renunță",
    [k.POSITION_OPTIONAL]: "Poziție (opțional)",
    [k.LOCK_PROFILE]: "blochează profilul",
    [k.DELETE_PROFILE]: "șterge profilul",
    [k.DO_SAVE_CHANGES]: "Dorești să salvezi modificările?",
    [k.POSITION]: "Poziție",
    [k.THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED]: "Numele clasei energetice este obligatoriu",
    [k.THE_NAME_OF_THE_ENERGY_CLASS]: "Numele clasei energetice",
    [k.EU_DEMAND]: "Cerere UE",
    [k.EU_REQUIREMENT_IS_REQUIRED]: "Cererea UE este obligatorie",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT]: "Sistem de încălzire sub șapă",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED]: "Sistemul de încălzire sub șapă este obligatoriu",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Sistem de încălzire sub șapă pentru camere: baie, toaletă, spălătorie, saună",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]:
        "Sistemul de încălzire sub șapă pentru camere: baie, toaletă, spălătorie, saună este obligatoriu",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL]: "Sistem de încălzire sub panou",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED]: "Sistemul de încălzire sub panou este obligatoriu",
    [k.REMOVE_ENERGY_CLASS]: "șterge clasa energetică",
    [k.TABLE_OF_ENERGY_CLASSES]: "Tabel cu clase energetice",
    [k.EU_DEMAND_EVERY_KWH]: "Cerere UE pentru fiecare KWh/m2*an",
    [k.SYSTEM_UNDER_THE_SPOUT]: "Sistem sub șapă",
    [k.SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Sistem sub șapă pentru camere (baie, toaletă, spălătorie, saună)",
    [k.SYSTEM_UNDER_THE_PANEL]: "Sistem sub panou",
    [k.INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS]: "Info: Poți adăuga setările proprii ale clasei energetice.",
    [k.ADD_YOUR_OWN_ENERGY_CLASS]: "Adaugă clasa energetică proprie",
    [k.ASSEMBLY_RULE]: "Regulă de asamblare",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM]: "Atenție! Suma netă este calculată pe baza suprafeței utile a camerei (contur).",
    [k.HEAT_DECOR_RECOMMENDATION]: "Recomandare Heat Decor",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT]: "Atenție! Suma netă este calculată pe baza suprafeței covorașului de încălzire.",
    [k.VAT_RULE]: "Regula TVA",
    [k.ACTIVE_VAT_RULES]: "Reguli TVA active",
    [k.ADD_A_NEW_USER]: "Adaugă un utilizator nou",
    [k.UNLOCK_PROFILE]: "deblochează profilul",
    [k.NET_PRICE_PER_M2]: "Preț net pe m2",
    [k.SYSTEM_FOR_TILES]: "Sistem pentru plăci",
    [k.INSULATION_MAT]: "covoraș de izolație 5 mm",
    [k.XPS_EXTRUDED_POLYSTYRENE]: "polistiren extrudat XPS 6 mm",
    [k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY]: "Sistem pentru panouri (substrat XPS 6 mm)",
    [k.SYSTEM_FOR_PANELS_5_MM_BASE]: "Sistem pentru panouri (substrat 5 mm)",
    [k.THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM]: "Valoarea minimă nu poate fi mai mare decât valoarea maximă",
    [k.UNDER_LAMINATED_PANELS]: "sub panouri laminate",
    [k.UNDER_THE_SPOUT]: "sub șapă",
    [k.UNDER_SPC_VINYL_PANELS]: "sub panouri din vinil SPC",
    [k.PRODUCT_TAILORED_TO_YOUR_SYSTEM]: "produs adaptat sistemului tău",
    [k.YEARS_WARRANTY]: "ani garanție",
    [k.THERMOREGULATOR]: "Termoregulator",
    [k.INTERNAL_AIR_SENSOR]: "Senzor de aer intern",
    [k.EXTERNAL_AIR_SENSOR]: "Senzor de aer extern",
    [k.CONNECTION]: "Conexiune",
    [k.HAS]: "Are",
    [k.DOES_NOT_HAVE]: "Nu are",
    [k.WHITE]: "Alb",
    [k.BLACK]: "Negru",
    [k.MANUAL]: "Manual",
    [k.INFO_ZIGBEE]: "Info: *La proiect va fi adăugat un controler ZigBee 3.0 necesar pentru funcționarea sistemului. Suportă până la 50 de dispozitive.",
    [k.SKIP]: "Omite",
    [k.ATTENTION]: "Atenție!",
    [k.ERROR]: "Eroare",
    [k.INFO]: "Info",
    [k.DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS]: "Vrei să adaugi alte elemente de construcție fixă?",
    [k.RECTANGLE]: "Dreptunghi",
    [k.LINE]: "Linie",
    [k.ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED]: "A fost aplicat un discount suplimentar!",
    [k.IRREGULAR_SHAPE]: "Formă neregulată",
    [k.VAPOR_BARRIER_FILM]: "Folie de barieră de vapori",
    [k.INSULATING_UNDERLAY]: "Substrat de izolație",
    [k.WIRES_BROWN]: "Cabluri - maro",
    [k.WIRES_BLUE]: "Cabluri - albastru",
    [k.CONNECTORS_FOR_HEATING_FOIL]: "Conectori pentru folie de încălzire (tip A sau B)",
    [k.SELF_FUSING_TAPE]: "Bandă auto-vulcanizantă",
    [k.HEAT_DECOR_ADHESIVE_TAPE]: "Bandă adezivă Heat Decor",
    [k.ARRANGEMENT]: "Aranjament",
    [k.DRAG]: "Trage",
    [k.DELETE_ROOM]: "Șterge camera",
    [k.INCORRECT_FORMAT]: "Format incorect",
    [k.USE_HAND]: "Mută vizualizarea",
    [k.FILE_TYPE_MUST_BE]: "Tip de fișier incorect. Fișierul trebuie să fie de tip: image/jpeg, image/png, .pdf, image/gif, .dwg",
    [k.THERE_IS_NO_SUCH_OFFER]: "Nu există o astfel de ofertă",
    [k.COPY_DATA]: "Copiază date",
    [k.DELETE_THERMOSTAT]: "Șterge termostatul",
    [k.SHOWER_BATH__WASHING_MACHINE]: "Duș/baie/mașină de spălat",
    [k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL]: "Chiuvetă independentă/vas independent",
    [k.WALL_HUNG_WASHBASIN__HUNG_BOWL]: "Chiuvetă suspendată/vas suspendat",
    [k.SEWAGE_GRATE]: "Grătar de canalizare",
    [k.SELECT_POINTS_TO_CREATE_A_FIGURE]: "Selectează punctele pentru a crea o figură",
    [k.STAINLESS_STEEL_MESH]: "Plasă din oțel inoxidabil",
    [k.GROUNDING_WIRE]: "Cablu de împământare",
    [k.CONNECTORS_FOR_STAINLESS_STEEL_MESH]: "Conectori pentru plasă din oțel inoxidabil (tip A sau B)",
    [k.SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS]: "Bandă auto-vulcanizantă pentru conectori de folie de încălzire",
    [k.REINFORCED_TAPE]: "Bandă armată",
    [k.INSTALLATION_BOX]: "Cutie de instalare",
    [k.PROTECTIVE_CONDUIT]: "Tub de protecție",
    [k.CITY]: "Oraș",
    [k.INPUTENERGY]: "Clasa energetică",
    [k.LISTOFINVEST]: "Lista investițiilor",
    [k.PROJECTSSTATUS]: "Statusul proiectului",
    [k.FLOORAREA]: "Suprafață",
    [k.COMPLETENOTES]: "Completează notițele",
    [k.TEXTADDINVEST]:
        "Investitorul în Asistentul Heat Decor este o persoană fizică sau juridică ce intenționează să achiziționeze materiale necesare instalării unui sistem de încălzire modern și, opțional, să comande montajul acestuia. Datele identificatoare ale investitorului includ numărul NIP, numele în cazul unei persoane juridice și prenumele și numele pentru o persoană fizică, precum și datele de contact.",
    [k.IMPORTANT]: "Important!",
    [k.TEXTADDINVEST2]: "Locul de reședință sau sediul investitorului trebuie să fie în România.",
    [k.INVESTORPROFILTEXT]: "Nu există nicio investiție pentru acest investitor",
    [k.INVESTORPROFILTEXT2]: "Vrei să o adaugi acum?",
    [k.INVESTORPROFILTEXT3]: "Începerea unui proiect începe cu adăugarea unei investiții",
    [k.HEATING_SYSTEM]: "Sistem de încălzire",
    [k.SYSTEMCOMPONENTS]: "Componentele sistemului",
    [k.MODIFY_THE_PERMANENT_STRUCTURE]: "Modifică structura permanentă",
    [k.ACCESSORIES]: "Accesorii",
    [k.DATE_CREATED]: "Data creării",
    [k.COPY_OFFER]: "Copiază oferta",
    [k.UNDO]: "Anulează",
    [k.FILL_IN_THE_DETAILS]: "Completează detaliile de contact",
    [k.E_G]: "ex. Arhitect",
    [k.ADD_INVEST_TEXT]:
        "Investiția în Asistentul Heat Decor include toate încăperile dintr-o clădire în care este planificat un sistem de încălzire modern. Datele identificatoare ale investiției includ planurile încărcate ale fiecărei camere sau etaje, suprafața utilă a fiecărei camere, zonele de siguranță marcate unde instalarea încălzirii electrice prin pardoseală nu este permisă, zonele de construcție fixă marcate care nu au picioare de minimum 3 cm înălțime, necesarul de energie pentru încălzire bazat pe izolarea termică a clădirii (EUco) și datele de adresă.",
    [k.COMPLATE_THE_INVEST]: "Completează datele investiției",
    [k.DISCOUNT_VALUE]: "Valoarea reducerii",
    [k.ADD_MONTAGE]: "Adaugă montaj",
    [k.MOUNTING_SYSTEM]: "Sistem de montare",
    [k.COST_OF_INSTALL]: "Costul serviciului de instalare",
    [k.NOTE_CHECK]: "Notă! Verifică cum este setată regula de montaj",
    [k.VAT_RATE]: "Rata TVA",
    [k.NOTE_CHECK_VAT]: "Notă! Verifică ce rată TVA ai!",
    [k.HEATING_COSTS]: "Costuri de încălzire",
    [k.ADD_HEATING_COSTS]: "Adaugă costurile de încălzire",
    [k.RESET]: "Resetează",
    [k.RETURN_TO_OFFERS]: "ÎNAPOI LA OFERTE",
    [k.LACK_OF_ROOMS]: "Lipsă camere",
    [k.DRAW_A_ROOM]: "Desenează o cameră",
    [k.HEATING_FOIL_UNDER_THE_LAYERED]: "Folie de încălzire sub scândură stratificată",
    [k.CONFIGURATION_OF_PRICE]: "Configurarea prețului pe 1 km",
    [k.SALEOFFER]: "Ofertă de vânzare",
    [k.COSTOFINSTALLSERVICE]: "Costul serviciului de instalare",
    [k.COSTOFALLMATERIALS]: "Costul tuturor materialelor",
    [k.LISTOFMATERIALS]: "Lista materialelor",
    [k.MATERIAL]: "Material",
    [k.NO]: "Nu",
    [k.MANUFACTUREROFHEATING]: "Producător de folii de încălzire și covorașe de încălzire: Heat Decor",
    [k.VISUALIZATION]: "Vizualizarea sistemului de încălzire",
    [k.VISUALIZATIONOFTHEHEATING]: "Vizualizarea sistemului de încălzire este atașată acestei oferte.",
    [k.LOGODISTRIBUTOR]: "Logo distribuitor",
    [k.VISUALIZATIONMADE]: "Vizualizare realizată de",
    [k.AREAOFTHEHEATING]: "Suprafața foliei de încălzire",
    [k.POWEROFTHEHEATING]: "Puterea foliei de încălzire",
    [k.ELECTRICALCONNECTION]: "Conexiune electrică",
    [k.ESTIMATEDANNUAL]: "Cost anual estimat de exploatare",
    [k.ENERGYCLASSSOLO]: "Clasa energetică",
    [k.CHANGETHEPOWER]: "Schimbă puterea foliei",
    [k.GERMAN]: "Germană",
    [k.ROMANIAN]: "Română",
    [k.HUNGARIAN]: "Maghiară",
    [k.RODO]: "Rodo distribuitor",
    [k.RODOTEXT]:
        "Heat Decor sp. z o.o. nu își asumă responsabilitatea pentru proiectul/componența realizată de Utilizator, inclusiv pentru selecția tipului de sistem de încălzire pentru încăpere, alegerea puterii acestuia și a materialelor. Utilizatorul își asumă responsabilitatea și riscul pentru alegerea, eventualele funcționări defectuoase și eficiența sistemului de încălzire configurat de el.",
    [k.RODOHD]: "Rodo Heat Decor",
    [k.SCOPEOFRESPONSIBILITY]: "Domeniul de responsabilitate",
    [k.ATTACHMENTS]: "Anexe",
    [k.ATTACHMENTSTEXT]: "Oferta este completă cu un atașament care conține vizualizarea sistemului de încălzire în diferite încăperi.",
    [k.ANNOTATIONS]: "Adnotări la ofertă",
    [k.THANKYOU]: "Vă mulțumim",
    [k.THANKYOUTEXT]: "pentru interesul acordat sistemului modern de încălzire.",
    [k.THANKYOUQUESTION]: "Pentru întrebări, vă rugăm să contactați persoana care oferă oferta.",
    [k.OFFERINGPERSON]: "Persoana care oferă",
    [k.OFFERINGCOMPANY]: "Compania ofertantă",
    [k.RECOMMENDMODERN]: "Recomandăm sistemele moderne de încălzire Heat Decor",
    [k.MAINTENANCE]: "fără întreținere",
    [k.COMPLETLY]: "complet",
    [k.SILENT]: "silențioase",
    [k.FRIENDLY]: "prietenoase",
    [k.ENVIRONMENT]: "cu mediul înconjurător",
    [k.WITHINTELLIGENT]: "cu control inteligent",
    [k.CONTROL]: "control",
    [k.RODO]: "Rodo forgalmazó",
    [k.RODOTEXT]:
        "A Heat Decor sp. z o.o. nem vállal felelősséget a Felhasználó által készített tervért/összeállításért, beleértve a helyiséghez választott fűtési rendszer típusát, annak teljesítményét és az anyagokat. A Felhasználó vállalja a felelősséget és a kockázatot az általa konfigurált fűtési rendszer választásáért, esetleges helytelen működéséért és hatékonyságáért.",
    [k.RODOHD]: "Rodo Heat Decor",
    [k.SCOPEOFRESPONSIBILITY]: "Felelősségi kör",
    [k.ATTACHMENTS]: "Mellékletek",
    [k.ATTACHMENTSTEXT]: "Az ajánlat a melléklettel együtt teljes, amely tartalmazza a fűtési rendszer vizualizációját a különböző helyiségekben.",
    [k.ANNOTATIONS]: "Megjegyzések az ajánlathoz",
    [k.THANKYOU]: "Köszönjük",
    [k.THANKYOUTEXT]: "az érdeklődést a modern fűtési rendszer iránt.",
    [k.THANKYOUQUESTION]: "Kérdés esetén forduljon az ajánlatot készítő személyhez.",
    [k.OFFERINGPERSON]: "Ajánlattevő személy",
    [k.OFFERINGCOMPANY]: "Ajánlatot adó cég",
    [k.RECOMMENDMODERN]: "Ajánljuk a Heat Decor modern fűtési rendszereit",
    [k.MAINTENANCE]: "karbantartásmentes",
    [k.COMPLETLY]: "teljesen",
    [k.SILENT]: "csendes",
    [k.FRIENDLY]: "környezetbarát",
    [k.ENVIRONMENT]: "a környezet számára",
    [k.WITHINTELLIGENT]: "intelligens",
    [k.CONTROL]: "vezérléssel",
    [k.VISUALIZATIONOFTHEDISTRIBUTION]: "Vizualizarea distribuției",
    [k.HEATINGSYSTEM]: "sistemului de încălzire",
    [k.TOTALPOWER]: "Puterea totală a sistemelor",
    [k.HEATINGFLOORS]: "etajelor încălzite",
    [k.LEGEND]: "Legendă",
    [k.PERMANENT_BUILDINGS2]: "construcție permanentă",
    [k.CAPACITYOFHEATING]: "PUTEREA SISTEMELOR DE ÎNCĂLZIRE ÎN CAMERELE INDIVIDUALE",
    [k.NAMEOFROOM]: "Numele camerei",
    [k.SYSTEMTYPE]: "Tipul sistemului",
    [k.AREACOVERAGE]: "Acoperirea suprafeței [ % ]",
    [k.POWERPER]: "Putere pe (m2) [ W/(m2) ]",
    [k.SYSTEMPOWER]: "Puterea sistemului în cameră [ W ]",
    [k.HEATINGCIRCUIT]: "Rezistența circuitului de încălzire [ \u03A9 ]",
    [k.CONTACTOR]: "* - este necesar un contactor pentru cameră",
    [k.OFFERNO]: "OFERTA NR.",
    [k.DEVELOPMENT]: "Elaborare",
    [k.BASEMENTPLAN]: "Plan subsol",
    [k.FLOORPLAN]: "Plan etaj",
    [k.ATTICPLAN]: "Plan mansardă",
    [k.STOREYPLAN]: "Plan nivel",
    [k.LEVELPLAN]: "Plan nivel",
    [k.ROOMPLAN]: "Plan cameră",
    [k.GROUNDFLOORPLAN]: "Plan parter",
    [k.NO_THERMOSTAT]: "Fără termostat",
    [k.EXTERNAL_SENSOR]: "Senzor extern",
    [k.APPLY_DISCOUNT_TO_ALL_PRODUCTS]: "Aplică reducere la toate produsele",
    [k.INVESTOR_PRICE_BELOW_COST]: "Atenție! Prețul pentru investitor la unul dintre produse este sub prețul dumneavoastră de achiziție!",
    [k.CURRENCY]: "Monedă",
    [k.POLISH_ZLOTY]: "Zlot polonez (PLN)",
    [k.EURO]: "Euro (EUR)",
    [k.CZECH_KORUNA]: "Coroană cehă (CZK)",
    [k.BRITISH_POUND]: "Liră britanică (GBP)",
    [k.CURRENCY_IS_REQUIRED]: "Moneda este obligatorie",
    [k.SELECT_DISTRIBUTOR]: "Selectați un distribuitor",
    [k.SELECT_ACTIVE_PRODUCTS]: "Selectați produsele active",
    [k.MANAGE_PRODUCTS]: "Gestionarea produselor",
    [k.ENTER_MESSAGE]: "Introduceți un mesaj",
    [k.LOGFF]: "Deconectează utilizatorul",
    [k.SELECTUSER]: "Selectează utilizatorul",
    [k.USERACCOUNT]: "Cont utilizator",
    [k.US_DOLLAR]: "Dolar american (USD)",
    [k.PRODUCT_SUMMARY]: "Sumar produse",
    [k.ERROR_OCCURRED]: "A apărut o eroare",
};
