const k = require("./keys");

export const german = {
    [k._]: `:`,
    [k._1]: ``,
    [k._10]: `4`,
    [k._11]: `5`,
    [k._12]: `6`,
    [k._13]: `06:00`,
    [k._14]: `10`,
    [k._15]: `1:`,
    [k._16]: `]`,
    [k._17]: `100%`,
    [k._2]: `-`,
    [k._3]: `/`,
    [k._4]: `.`,
    [k._5]: `(`,
    [k._6]: `12:00`,
    [k._7]: `1`,
    [k._8]: `2`,
    [k._9]: `3`,
    [k.ADD]: `Hinzufügen`,
    [k.ADD_ADDRESS]: `Adresse hinzufügen`,
    [k.ADD_ANOTHER_ROOM]: `Weiteres Zimmer hinzufügen`,
    [k.ADD_NEW_ADDRESS]: `Neue Adresse hinzufügen`,
    [k.ADDTOCART]: `In den Warenkorb`,
    [k.ANNUAL_ELECTRICITY_CONSUMPTION]: `Jährlicher Stromverbrauch`,
    [k.ANNUAL_OPERATING_COSTS]: `Jährliche Betriebskosten`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS]: `Sind Sie sicher, dass Sie diese Adresse löschen möchten?`,
    [k.ARCHITECT]: `Architekt`,
    [k.APARTMENT]: `Wohnung`,
    [k.BACK]: `Zurück`,
    [k.TRANSFER]: `Überweisung`,
    [k.BANK_TRANSFER]: `Banküberweisung`,
    [k.BLOCKOFFLATS]: `Wohnblock`,
    [k.BUILDINGENERGYCLASS]: `Energieklasse des Gebäudes`,
    [k.BUYBYMETER]: `Ich kaufe pro Meter`,
    [k.BUYONROLLS]: `Ich kaufe auf Rollen`,
    [k.BYMETERS]: `(pro Meter)`,
    [k.CONFIRM]: `Bestätigen`,
    [k.CZECH]: `Tschechisch`,
    [k.CALCULATECOSTS]: `Kosten berechnen`,
    [k.CALCULATE_HEATING_COSTS]: `Heizkosten berechnen`,
    [k.CREATE_ACCOUNT]: `Konto erstellen`,
    [k.CANCEL]: `Abbrechen`,
    [k.CHOOSELANG]: `Sprache auswählen`,
    [k.CHOOSE_NEXT_STEP]: `Nächsten Schritt wählen`,
    [k.COSTCALCULATION]: `Kostenberechnung`,
    [k.CONSTRUCTION]: `Bauwesen`,
    [k.DELETE]: `Löschen`,
    [k.DEVELOPER]: `Entwickler`,
    [k.DISCOUNTGRANTED]: `Gewährter Rabatt`,
    [k.DISCOUNTLEVEL]: `Ihr aktuelles Rabattniveau`,
    [k.DISTRIBUTORS]: `Vertriebspartner`,
    [k.ELECTRICHEATERS]: `Elektroheizungen`,
    [k.EMPLOYEE]: `Mitarbeiter`,
    [k.ENGLISH]: `Englisch`,
    [k.ENTER_ROOMS_DIMENSIONS]: `Geben Sie die Abmessungen des Raums an`,
    [k.ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE]: `Geben Sie die Abmessungen des Raums basierend auf der Figur an`,
    [k.ENTER_A_NEW_PASSWORD]: `Das neue Passwort sollte mindestens 8 Zeichen, einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten`,
    [k.FINISHED]: `Fertig`,
    [k.FOILPOWER]: `Folienleistung`,
    [k.FOILWIDTH]: `Folienbreite`,
    [k.FRENCH]: `Französisch`,
    [k.GERMAN]: `Deutsch`,
    [k.GROSS]: `brutto`,
    [k.ELECRICITYPRICE]: `Strompreis (PLN / kWh)`,
    [k.ENERGYCLASS]: `Energieklasse des Gebäudes (kWh / m2 pro Jahr)`,
    [k.ENTER_INSTALLATION_PARAMS]: `Geben Sie die Installationsparameter ein, um den Verbrauch zu berechnen`,
    [k.FOR_CLIENT]: `Für den Kunden`,
    [k.GENERAL_HEATING_COST_CALCULATOR]: `Allgemeiner Heizkostenrechner für Infrarotheizung mit der Energieklasse des Gebäudes:`,
    [k.LOWENERGY]: `A Niedrigenergie`,
    [k.GOTOCASHREGISTER]: `Zur Kasse gehen`,
    [k.HEATINGCOSTCALCULATOR]: `Heizkostenrechner`,
    [k.HEATINGFOIL]: `Heizfolie`,
    [k.HEATINGMAT]: `Heizmatte`,
    [k.HOUSE]: `Haus`,
    [k.HOUSINGESTATE]: `Wohnsiedlung`,
    [k.INSTALLER]: `Installateur`,
    [k.I_ORDER_WITH_SHIPPING_TO_CUSTOMERS]: `Ich bestelle mit Versand an Kunden`,
    [k.I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE]: `Ich bestelle für mein eigenes Lager`,
    [k.ITALIAN]: `Italienisch`,
    [k.HEATING_SYSTEM_POWER]: `Nennleistung des Heizsystems`,
    [k.INSTALLATIONDESIGN]: `Installationsentwurf`,
    [k.INVESTMENT_MATERIALS_COSTS]: `Investitionskosten der Materialien`,
    [k.INVESTORS]: `Investoren`,
    [k.INVESTMENTS]: `Investitionen`,
    [k.KNOWLEDGEBASE]: `Wissensdatenbank`,
    [k.LARGEQUANTITYINSTOCK]: `Große Menge auf Lager`,
    [k.LEAVEPROJECT]: `Projekt verlassen`,
    [k.LENGTH_IN_CM]: `Länge in cm`,
    [k.LOGIN]: `Anmelden`,
    [k.LOG_IN]: `Einloggen`,
    [k.LOGOUT]: `Abmelden`,
    [k.MANAGER]: `Manager`,
    [k.MARKETING]: `Marketing`,
    [k.MEDIUMENERGYSAVING]: `C Mittlere Energieeinsparung`,
    [k.ENERGYSAVING]: `B Energieeinsparung`,
    [k.MIN_PV_INSTALATION_REQUIRED]: `Mindest-Photovoltaikanlage erforderlich, um die Heizkosten auszugleichen`,
    [k.MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL]: `Produkte und zum höheren Rabattniveau wechseln`,
    [k.NO]: `Nein`,
    [k.NODATA]: `Keine Daten`,
    [k.NORWEGIAN]: `Norwegisch`,
    [k.ONROLLS]: `(auf Rollen)`,
    [k.POLISH]: `Polnisch`,
    [k.MONTHLY_OPERATING_COSTS]: `Monatliche Betriebskosten`,
    [k.NET]: `netto`,
    [k.NEWROOM]: `Neuer Raum`,
    [k.OFFERGENERATING]: `Angebotserstellung`,
    [k.OFFERS]: `Angebote`,
    [k.ORDERS]: `Bestellungen`,
    [k.ONLINE_PAYMENT]: `Online-Zahlung`,
    [k.PAYMENT_METHOD]: `Zahlungsmethode`,
    [k.OWN]: `Eigene`,
    [k.OWNER]: `Besitzer`,
    [k.PASSIVE_A_PLUS]: `A+ Passiv`,
    [k.PHOTOVOLTAIC]: `Photovoltaik`,
    [k.PRESENTED_CALC_ARE_INFORMATIVE]: `*Die präsentierten Berechnungen dienen nur Informationszwecken und stellen kein Angebot im Sinne von Art. 66 Abs. 1 BGB dar`,
    [k.PRICELIST]: `Preisliste`,
    [k.PRODUCT]: `Produkt`,
    [k.PRODUCTSINCART]: `Produkte im Warenkorb`,
    [k.PRODUCTAVAILABLE]: `Produkt verfügbar`,
    [k.PROFIT]: `Gewinn`,
    [k.PROJECTS]: `Projekte`,
    [k.PUBLICFACILITY]: `Öffentliche Einrichtung`,
    [k.RATED_ENERGY_CONSUMPTION]: `Nennenergieverbrauch`,
    [k.REJECT_CHANGES]: `Änderungen ablehnen`,
    [k.REMIND_PASSWORD]: `Passwort erinnern`,
    [k.REMEMBER_ME]: `Erinnere dich an mich`,
    [k.RETURN_TO_THE_LOGIN_PANEL]: `Zurück zum Anmeldebereich`,
    [k.QUANTITY]: `Menge`,
    [k.ROMANIAN]: `Rumänisch`,
    [k.SALE]: `Rabatte`,
    [k.SAVEANDCLOSE]: `Speichern und beenden`,
    [k.SAVECHANGES]: `Änderungen speichern`,
    [k.SEARCHFORPRODUCTS]: `Nach Produkten suchen`,
    [k.SEETHEPRODUCT]: `Produkt ansehen`,
    [k.SETTINGS]: `Einstellungen`,
    [k.SHOWCROSS_SECTION]: `Querschnitt anzeigen`,
    [k.SHOWLAYERS]: `Schichten anzeigen`,
    [k.SLOVAK]: `Slowakisch`,
    [k.SMALLQUANTITYINSTOCK]: `Kleine Menge auf Lager`,
    [k.SOMETHING_WENT_WRONG]: `Etwas ist schiefgelaufen:`,
    [k.SPAIN]: `Spanisch`,
    [k.RELOADAPPLICATION]: `Anwendung neu laden`,
    [k.SWEDISH]: `Schwedisch`,
    [k.THERMOSTAT]: `Thermostat`,
    [k.THERMOSTATFUNCTIONS]: `Thermostatfunktionen`,
    [k.THERMOSTATS]: `Thermostate`,
    [k.TOGETHER]: `Zusammen`,
    [k.TOGETHERTOPAY]: `Gesamtbetrag`,
    [k.USABLEAREA]: `Nutzfläche (m²)`,
    [k.ZEROENERGY]: `A++ Nullenergie`,
    [k.FLOOR]: `Boden`,
    [k.CEILING]: `Decke`,
    [k.WALL]: `Wände`,
    [k.WAREHOUSE]: `Lagerhalle`,
    [k.WHOLESALER]: `Großhändler`,
    [k.WT2021]: `WT2021`,
    [k.ADDROOM]: `Raum hinzufügen`,
    [k.CHANGE]: `Ändern`,
    [k.YES]: `Ja`,
    [k.GOBACKTOCREATOR]: `Zurück zum Ersteller`,
    [k.GOBACKTOCART]: `Zurück zur Preisliste`,
    [k.SQUAREM]: `m²`,
    [k.PIECE]: `Stk.`,
    [k.RUNNINGM]: `lfm.`,
    [k.COLOR]: `Farbe`,
    [k.UNDO]: `Rückgängig`,
    [k.REDO]: `Wiederholen`,
    [k.ZOOMIN]: `Hineinzoomen`,
    [k.ZOOMOUT]: `Herauszoomen`,
    [k.MEASURE]: `Messen`,
    [k.CLOSEPOLYGON]: `Figur schließen`,
    [k.CONTACTORREQUIRED]: `Schütz erforderlich`,
    [k.WRONGLOGINORPASSWORD]: "Ungültiger Login oder Passwort.",
    [k.WIDTH_IN_CM]: "Breite in cm",
    [k.WIDTH]: "Breite",
    [k.MISSINGUSER]: "Benutzer nicht gefunden.",
    [k.PASSWORD]: "Passwort",
    [k.PASSWORDREQUIRED]: "Passwort ist erforderlich.",
    [k.CONFIRMPASSWORDMUSTBESAME]: "Bestätigungspasswort muss identisch sein.",
    [k.PASSWORDSHOULDBEMIN8CHARLONG]: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    [k.PASSWORDPATTERNQUOTE]: "Das Passwort muss mindestens eine Ziffer, einen Großbuchstaben, einen Kleinbuchstaben und ein Sonderzeichen (z.B.: @, #, &, !, $) enthalten.",
    [k.PASSWORD_HAS_BEEN_RESET]: "Das Passwort wurde zurückgesetzt",
    [k.CALIBRATION]: "Kalibrierung",
    [k.READY]: "Bereit",
    [k.EDIT]: "Bearbeiten",
    [k.START]: "Start",
    [k.UNFOLDAUTOMATICALLY]: "Automatisch entfalten",
    [k.CUTTINGPOINT]: "Schnittpunkt",
    [k.TYPE]: "Typ",
    [k.FURNITURE]: "Möbel",
    [k.BATH_SHOWERTRAY]: "Badewanne/Duschtasse",
    [k.SINK]: "Waschbecken/WC-Hängeelement",
    [k.KITCHEN_FURNITURE]: "Küchenmöbel",
    [k.FIREPLACE]: "Kamin",
    [k.HOUSEHOLD_APPLIANCES]: "Haushaltsgeräte",
    [k.YOUR_ADDRESSES]: "Ihre Adressen",
    [k.YOUR_NET_PRICE]: "Ihr Nettopreis",
    [k.YOUR_GROSS_PRICE]: "Ihr Bruttopreis",
    [k.OPTIMAL]: "Optimal",
    [k.VERTICALY]: "Vertikal",
    [k.HORIZONTALY]: "Horizontal",
    [k.ALLWIDTHS]: "Alle Breiten",
    [k.DRAWOFFAREA]: "Möchten Sie feste Einbauten hinzufügen?",
    [k.INNER]: "Inner",
    [k.OUTER]: "Außen",
    [k.OTHER]: "Andere",
    [k.ROOM]: "Raum",
    [k.BATHROOM]: "Badezimmer",
    [k.BASEMENT]: "Keller",
    [k.GROUND_FLOOR]: "Erdgeschoss",
    [k.FIRST_FLOOR]: "Erster Stock",
    [k.ATTIC]: "Dachboden",
    [k.STOREY]: "Geschoss",
    [k.LEVEL]: "Ebene",
    [k.ROOM]: "Zimmer",
    [k.ADDFILE]: "Datei hinzufügen",
    [k.DRAWPLAN]: "Plan zeichnen",
    [k.LIVINGROOM]: "Wohnzimmer",
    [k.KITCHEN]: "Küche",
    [k.VESTIBULE]: "Windfang",
    [k.BEDROOM]: "Schlafzimmer",
    [k.DININGROOM]: "Esszimmer",
    [k.WARDROBE]: "Garderobe",
    [k.TOILET]: "Toilette",
    [k.LAUNDRY]: "Waschküche",
    [k.HALL]: "Flur",
    [k.CABINET]: "Büro",
    [k.ANTEROOM]: "Vorzimmer",
    [k.CORRIDOR]: "Korridor",
    [k.PANTRY]: "Speisekammer",
    [k.LIVING_DINING_ROOM]: "Wohn- und Esszimmer",
    [k.LIVINGROOM_WITH_KITCHENETTE]: "Wohnzimmer mit Kochnische",
    [k.GARAGE]: "Garage",
    [k.TECHNICALROOM]: "Technikraum",
    [k.UTILITYROOM]: "Hauswirtschaftsraum",
    [k.RECREATIONROOM]: "Freizeitraum",
    [k.ENTRESOL]: "Zwischengeschoss",
    [k.SAUNA]: "Sauna",
    [k.BOILERROOM]: "Heizraum",
    [k.CUBBY]: "Abstellraum",
    [k.STAIRS]: "Treppe",
    [k.FIREPLACE_ROOM]: "Kaminzimmer",
    [k.GO_BACK_EDIT]: "Zurück zur Bearbeitung",
    [k.GO_FURTHER]: "Weiter",
    [k.DRAW_NEXT]: "Nächsten zeichnen",
    [k.START_DESIGNING]: "Design starten",
    [k.IN_THE_WIZARD_HEAT_DECOR]: "im Heat Decor Assistenten",
    [k.YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS]: "In wenigen einfachen Schritten erstellen Sie ein Infrarot-Heizprojekt.",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Geben Sie die bei der Kontoerstellung angegebene E-Mail-Adresse ein.",
    [k.WHAT_WILL_YOU_GAIN_BY_WORKING]: "Was gewinnen Sie durch die Zusammenarbeit",
    [k.WITH_THE_HEAT_DECOR_CREATOR]: "mit dem Heat Decor Assistenten?",
    [k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE]: "• Präzise Entwürfe des Heizsystems im",
    [k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY]: "Heat Decor Standard, bereit für den Einsatz",
    [k.THE_INSTALLATION_TEAM]: "durch das Installationsteam",
    [k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR]: "• Einfachere und umfassendere Erstellung eines Angebots für",
    [k.YOUR_CLIENT]: "Ihren Kunden",
    [k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS]: "• Erleichterung des Bestellvorgangs für Produkte",
    [k.HEAT_DECOR]: "Heat Decor",
    [k.WE_ARE_CHANGING_THE_FUTURE_FOR]: "Wir gestalten die Zukunft ",
    [k.GREEN]: "grün",
    [k.WATCH_HEAT_DECOR]: "Heat Decor beobachten",
    [k.READ_AND_SUBSCRIBE]: "lesen und abonnieren",
    [k.BLOG]: "Blog",
    [k.PRIMARY_USER]: "Hauptnutzer:",
    [k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES]: "• Hat Zugang zu allen Funktionen des Assistenten.",
    [k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR]:
        "• Die Definition der Rolle in der Firmenstruktur erleichtert die weitere Zusammenarbeit mit Heat Decor.",
    [k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS]:
        "• Die Angabe des Unternehmens-typs ermöglicht die Anpassung des Assistenten an Ihre Bedürfnisse.",
    [k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER]: "• Nach der Registrierung wird sich Heat Decor mit dem angegebenen Hauptnutzer in Verbindung setzen.",
    [k.ADDITIONAL_USERS]: "Zusätzliche Nutzer:",
    [k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS]: "• Sie können den Assistenten nutzen, um Entwürfe und Angebote für ihre Kunden zu erstellen.",
    [k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES]: "• Sie haben eingeschränkte Möglichkeiten zur Bearbeitung des Kontos.",
    [k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD]:
        "Bei wiederholten Schwierigkeiten mit der Nutzung des Assistenten kontaktieren Sie den Administrator des Heat Decor Assistenten:",
    [k.EMAIL]: "E-Mail",
    [k.TELEPHONE]: "Telefon:",
    [k.PRIVACY_POLICY]: "Datenschutzrichtlinie",
    [k.WEBSITE_REGULATIONS]: "Website-Bedingungen",
    [k.THANK_YOU_FOR_YOUR_REGISTRATION]: "Vielen Dank für Ihre Registrierung",
    [k.LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY]: "Das Einloggen wird nach erfolgreicher",
    [k.SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE]: "Passworteinstellung und Verifizierung des Kontos durch",
    [k.ADMINISTRATOR]: "den Administrator möglich sein.",
    [k.YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD]: "Sie erhalten einen Link zur Einrichtung eines neuen Passworts.",
    [k.EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS]: "E-Mail wurde gesendet. Folgen Sie den Anweisungen",
    [k.IN_THE_EMAIL]: " in der E-Mail.",
    [k.CHECK_EMAIL]: "E-Mail prüfen",
    [k.TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION]:
        "Um den Registrierungsprozess abzuschließen, prüfen Sie die automatisch gesendete Nachricht an die angegebene E-Mail-Adresse und klicken Sie auf den Bestätigungslink.",
    [k.REGISTRATION_ERROR]: "Registrierungsfehler",
    [k.AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT]: "Ein Fehler ist bei der Erstellung eines neuen Kontos aufgetreten.",
    [k.TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US]:
        "Versuchen Sie es erneut. Falls der Fehler weiterhin besteht, versuchen Sie, sich mit einer anderen E-Mail-Adresse zu registrieren oder kontaktieren Sie uns.",
    [k.ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION]: "Geben Sie die Daten des Hauptnutzers des Kontos ein.",
    [k.REGISTRATION]: "Registrierung",
    [k.ADDITIONAL_USERS_OPTIONAL]: "Zusätzliche Benutzer (optional)",
    [k.INVESTMENT]: "Investition",
    [k.INVESTORS_NAME]: "Name des Investors:",
    [k.ADDED_BY]: "Hinzugefügt von:",
    [k.NOTES]: "Notizen",
    [k.STREET]: "Straße",
    [k.CITY]: "Stadt",
    [k.ZIP_CODE]: "Postleitzahl",
    [k.GROSS]: "Brutto",
    [k.NET_PRICE_FOR_THE_CLIENT]: "Netto-Preis für den Kunden:",
    [k.NET_PROFIT]: "Netto-Gewinn:",
    [k.DOWNLOAD_MULTIPLE_PDF_FILES]: "Mehrere PDFs herunterladen",
    [k.DELETE_PROJECTS]: "Projekte löschen",
    [k.ADD_TO_CART]: "In den Warenkorb legen",
    [k.REMOVE_OFFERS]: "Angebote entfernen",
    [k.GENEROWANIE_OFERTY]: "Angebotserstellung",
    [k.RESET_PASS]: "Passwort zurücksetzen",
    [k.VALUE_OF_THE_OFFER]: "Wert des Angebots",
    [k.YOUR_COST]: "Ihre Kosten",
    [k.YOUR_PRICE]: "Ihr Preis",
    [k.THE_INVESTOR_PAYS]: "Der Investor zahlt",
    [k.YOU_EARN]: "Sie verdienen",
    [k.GO_ON]: "Weiter",
    [k.ENTER_NEW_PASSWORD]: "Neues Passwort eingeben",
    [k.NEW_PASSWORD]: "Neues Passwort",
    [k.PRICE]: "Preis",
    [k.PRICE_FOR_THE_CLIENT]: "Preis für den Kunden:",
    [k.A_PRICE_FOR_YOU]: "Preis für Sie:",
    [k.INCORRECT_LOGIN_OR_PASSWORD]: "Falscher Benutzername oder Passwort",
    [k.OFFER]: `Angebot`,
    [k.BY]: `von`,
    [k.LIST_OF_ORDERS]: "Bestellliste",
    [k.THANK_YOU_FOR_YOUR_ORDER]: "Vielen Dank für Ihre Bestellung",
    [k.GO_TO_ORDERS]: "Zu den Bestellungen",
    [k.SMS_CODE]: "SMS-Code",
    [k.INCORRECT_AUTHORIZATION_CODE]: "Falscher Autorisierungscode",
    [k.ENTER_AUTHORIZATION_CODE]: "Autorisierungscode eingeben",
    [k.CONFIRMED]: "Bestätigt",
    [k.RESEND_CODE]: "Code erneut senden",
    [k.REENTER_THE_6_DIGIT_CODE]: "Geben Sie den 6-stelligen SMS-Code erneut ein Nr.",
    [k.TO_VERIFY_YOUR_ACCOUNT]: "um Ihr Konto zu verifizieren",
    [k.ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT]: "Geben Sie den 6-stelligen SMS-Code ein, um Ihr Konto zu verifizieren",
    [k.NR]: "Nr",
    [k.GO_TO_PAYMENT]: "Zur Zahlung",
    [k.ORDERED_PRODUCTS]: "Bestellte Produkte",
    [k.NOTES_TO_THE_ORDER]: "Bestellnotizen",
    [k.NO_PRODUCTS]: "Keine Produkte",
    [k.THERE_ARE_NO_PRODUCTS_IN_YOUR_CART]: "Es befinden sich keine Produkte im Warenkorb",
    [k.NO_OFFERS]: "Keine Angebote",
    [k.YOUR_CART]: "Ihr Warenkorb",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER]: "Ihre Bestellung mit Lieferung an den Kunden",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE]: "Ihre Bestellung mit Lieferung an Ihr Lager",
    [k.PAY_FOR_YOUR_ORDER]: "Bezahlen Sie Ihre Bestellung",
    [k.PHONE]: "Telefon",
    [k.SHIPPING_TO]: "Versand an",
    [k.SAVE]: "Speichern",
    [k.EMAIL_IS_REQUIRED]: "E-Mail ist erforderlich",
    [k.CODE_AND_CITY_ARE_REQUIRED]: "Postleitzahl und Stadt sind erforderlich",
    [k.POSTAL_CODE_AND_CITY]: "Postleitzahl und Stadt",
    [k.STREET_AND_BUILDING_NUMBER_ARE_REQUIRED]: "Straße und Hausnummer sind erforderlich",
    [k.STREET_AND_NUMBER]: "Straße und Nummer",
    [k.NAME_AND_SURNAME]: "Vorname und Nachname",
    [k.DISCOUNT]: "Rabatt",
    [k.SUM]: "Summe",
    [k.YOU_WANT_TO_GIVE_A_DISCOUNT_ON_ALL_MATERIAL]: "Möchten Sie einen Rabatt auf das gesamte Material gewähren?",
    [k.ALL_MATERIAL_DISCOUNT]: "Rabatt auf gesamtes Material",
    [k.SORRY_NOTHING_HERE]: "Entschuldigung... hier ist nichts.",
    [k.GO_HOME]: "Zur Startseite",
    [k.I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN]: "Ich verstehe, beim nächsten Login nicht anzeigen",
    [k.DISTRIBUTOR]: "Vertriebspartner",
    [k.GENERATE_REPORT]: "Bericht generieren",
    [k.SKETCH]: "Skizze",
    [k.GENERATE_AN_OFFER]: "Ein Angebot erstellen",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT]: "Sind Sie sicher, dass Sie das ausgewählte Projekt löschen möchten?",
    [k.FIND_A_PROJECT]: "Projekt finden",
    [k.THERE_IS_NO_SUCH_PROJECT]: "Es gibt kein solches Projekt",
    [k.YOU_DONT_HAVE_ANY_PROJECT_YET]: "Sie haben noch kein Projekt",
    [k.YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT]: "Sie müssen einen Investor und eine Investition haben, um ein Projekt dafür zu erstellen",
    [k.DO_YOU_WANT_TO_CREATE_AN_INVESTOR]: "Möchten Sie einen Investor erstellen?",
    [k.GETTING_STARTED_WITH_ADDING_AN_INVESTOR]: "Der Einstieg beginnt mit dem Hinzufügen eines Investors",
    [k.ADD_AN_INVESTOR]: "Einen Investor hinzufügen",
    [k.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN]: "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut",
    [k.SELECT_THE_TYPE_OF_ROOM]: "Wählen Sie die Raumart aus",
    [k.GO_BACK_TO_EDITING]: "Zurück zur Bearbeitung",
    [k.TITLE]: "Titel",
    [k.GROUP]: "Gruppe",
    [k.GENERAL]: "Allgemein",
    [k.HEATING_FOIL]: "Heizfolie",
    [k.ELECTRIC_HEATERS]: "Elektroheizgeräte",
    [k.HEATING_MAT]: "Heizmatte",
    [k.IMAGE]: "Bild",
    [k.DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT]: "Bild ziehen und ablegen oder klicken und auswählen",
    [k.PREVIEW]: "Vorschau",
    [k.TEXT_EDITOR]: "Texteditor",
    [k.SAVE_CHANGES]: "Änderungen speichern",
    [k.EXIT]: "Beenden",
    [k.ADD_A_NEW_COMPANY]: "Ein neues Unternehmen hinzufügen",
    [k.GO_BACK_TO_THE_LIST]: "Zurück zur Liste",
    [k.FIRST_NAME]: "Vorname",
    [k.LAST_NAME]: "Nachname",
    [k.USER]: "Benutzer",
    [k.IS_REQUIRED]: "ist erforderlich",
    [k.FIRST_NAME_IS_REQUIRED]: "Vorname ist erforderlich",
    [k.LAST_NAME_IS_REQUIRED]: "Nachname ist erforderlich",
    [k.PHONE_IS_REQUIRED]: "Telefon ist erforderlich",
    [k.EMAIL_IS_INVALID]: "E-Mail ist ungültig",
    [k.ADD_USER]: "Benutzer hinzufügen",
    [k.REGISTER]: "Registrieren",
    [k.COUNTRY]: "Land",
    [k.PHONE_NUMBER]: "Telefonnummer",
    [k.PREFIX_IS_REQUIRED]: "Vorwahl ist erforderlich",
    [k.NEXT_STEP]: "Nächster Schritt",
    [k.TAX_ID_IS_REQUIRED]: "Steuernummer ist erforderlich",
    [k.COUNTRY_IS_REQUIRED]: "Land ist erforderlich",
    [k.TAX_ID]: "Steuernummer",
    [k.TAX_ID_IS_INCORRECT]: "Steuernummer ist ungültig",
    [k.ZIP_CODE_IS_REQUIRED]: "Postleitzahl ist erforderlich",
    [k.CITY_IS_REQUIRED]: "Stadt ist erforderlich",
    [k.HOUSE_NUMBER_IS_REQUIRED]: "Hausnummer ist erforderlich",
    [k.NUMBER]: "Nummer",
    [k.DOCUMENT_NUMBER]: "Dokumentennummer",
    [k.DOWNLOAD]: "Herunterladen",
    [k.WAS_THIS_ARTICLE_HELPFUL]: "War dieser Artikel hilfreich?",
    [k.MIN_READING]: "Min. Lesedauer",
    [k.SEE_MORE]: "MEHR ANZEIGEN",
    [k.ADDRESS]: "Adresse",
    [k.DEFAULT_ADDRESS]: "Standardadresse",
    [k.NAME]: "Name",
    [k.NAME_OF_THE_INVESTMENT]: "Name der Investition",
    [k.SEARCH_CRITERIA]: "Suchkriterien",
    [k.WHO_ADDED]: "Wer hat hinzugefügt?",
    [k.IS_IT_A_COMPANY]: "Ist es ein Unternehmen?",
    [k.BUSINESS]: "Unternehmen",
    [k.NATURAL_PERSON]: "Natürliche Person",
    [k.YOU_DONT_HAVE_ANY_INVESTOR_YET]: "Sie haben noch keinen Investor",
    [k.DO_YOU_WANT_TO_ADD_IT_NOW]: "Möchten Sie ihn jetzt hinzufügen?",
    [k.THERE_IS_NO_SUCH_INVESTOR]: "Es gibt keinen solchen Investor",
    [k.YOU_DONT_HAVE_ANY_INVESTMENTS_YET]: "Sie haben noch keine Investitionen",
    [k.ADD_YOUR_FIRST_INVESTMENT]: "Fügen Sie Ihre erste Investition hinzu",
    [k.THERE_IS_NO_SUCH_INVESTMENT]: "Es gibt keine solche Investition",
    [k.ADD_AN_INVESTMENT]: "Eine Investition hinzufügen",
    [k.INVESTOR]: "Investor",
    [k.CONTACT_PERSON]: "Kontaktperson",
    [k.STREET_IS_REQUIRED]: "Straße ist erforderlich",
    [k.NO_IS_REQUIRED]: "Nr. ist erforderlich",
    [k.CODE_IS_REQUIRED]: "Code ist erforderlich",
    [k.THE_ENERGY_CLASS_IS_REQUIRED]: "Energieklasse ist erforderlich",
    [k.CUSTOM_ENERGY_CLASS_IS_REQUIRED]: "Eigene Energieklasse ist erforderlich",
    [k.GENERATE_OFFERS]: "Angebote generieren",
    [k.THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT]: "Das Angebot befindet sich bereits im Warenkorb, sind Sie sicher, dass Sie es entfernen möchten?",
    [k.COMPANY_NAME_IS_REQUIRED]: "Firmenname ist erforderlich",
    [k.THE_ADDRESS_IS_REQUIRED]: "Adresse ist erforderlich",
    [k.THE_ZIP_CODE_IS_INCORRECT]: "Postleitzahl ist ungültig",
    [k.THE_CITY_IS_NOT_CORRECT]: "Stadt ist ungültig",
    [k.THE_HOUSE_NUMBER_IS_INCORRECT]: "Hausnummer ist ungültig",
    [k.COMPANY_NAME]: "Firmenname",
    [k.PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED]: "Vorwahl und Telefonnummer sind erforderlich",
    [k.POSTAL_CODE_AND_CITY_ARE_REQUIRED]: "Postleitzahl und Stadt sind erforderlich",
    [k.STREET_AND_HOUSE_NUMBER_ARE_REQUIRED]: "Straße und Hausnummer sind erforderlich",
    [k.FULL_NAME_IS_REQUIRED]: "Vollständiger Name ist erforderlich",
    [k.REQUIRED_FIELD]: "Pflichtfeld",
    [k.REQUIRED]: "Erforderlich",
    [k.ADD_A_PROJECT]: "Projekt hinzufügen",
    [k.PROJECT_NAME_IS_REQUIRED]: "Projektname ist erforderlich",
    [k.THE_BUILDING_NUMBER_IS_REQUIRED]: "Hausnummer ist erforderlich",
    [k.No]: "Nr.",
    [k.ADDITIONAL_CONTACT_PERSON]: "Zusätzliche Kontaktperson",
    [k.CLASS_ENERGY_IS_REQUIRED]: "Energieklasse ist erforderlich",
    [k.ENERGY_CLASS]: "Energieklasse",
    [k.THE_STREET_IS_NOT_CORRECT]: "Straße ist ungültig",
    [k.HOUSE_NUMBER]: "Hausnummer",
    [k.TYPE_OF_INVESTMENT]: "Art der Investition",
    [k.CODE]: "Code",
    [k.THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN]: "Die Investition wurde nicht hinzugefügt. Versuchen Sie es erneut",
    [k.COMPANY_TYPE]: "Unternehmenstyp",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE]: "Telefonnummer zur Verifizierung des Kontos mit SMS-Code",
    [k.GRAPHICS]: "Grafiken",
    [k.GO_BACK_TO_LOGIN]: "Zurück zum Login",
    [k.ADD_A_NEW_INVESTMENT]: "Neue Investition hinzufügen",
    [k.GO_BACK_TO_THE_LIST_OF_INVESTMENTS]: "Zurück zur Liste der Investitionen",
    [k.ADD_A_NEW_INVESTOR]: "Neuen Investor hinzufügen",
    [k.COMPLETE_THE_INVESTOR_PROFILE]: "Investorprofil vervollständigen",
    [k.GO_BACK_TO_INVESTORS]: "Zurück zu den Investoren",
    [k.PROJECT_NAME]: "Projektname",
    [k.WHAT_DO_YOU_WANT_TO_DESIGN]: "Was möchten Sie entwerfen?",
    [k.UPLOAD_THE_FILE_WITH_THE_VIEW]: "Datei mit Ansicht hochladen",
    [k.ADD_FILE]: "DATEI HINZUFÜGEN",
    [k.DISCARD_CHANGES]: "Änderungen verwerfen",
    [k.DRAW_ANY_ROOM_PLAN]: "Beliebigen Raumplan zeichnen",
    [k.GENERATE_AS_IMAGE]: "Als Bild generieren",
    [k.DRAW_PLAN]: "PLAN ZEICHNEN",
    [k.CREATE_A_PROJECT]: "Projekt erstellen",
    [k.CREATE_A_THROW_IN_THE_WIZARD]: "Ansicht im Assistenten erstellen",
    [k.COME_BACK]: "Zurück",
    [k.HEATING_MAT_UNDER_THE_TILES]: "Heizmatte unter Fliesen",
    [k.HEATING_FOIL_UNDER_THE_SPOUT]: "Heizfolie unter Estrich",
    [k.HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS]: "Heizfolie unter SPC-Vinylpaneelen",
    [k.HEATING_FOIL_FOR_LAMINATED_PANELS]: "Heizfolie für Laminatpaneele",
    [k.SELECT_A_HEATING_SYSTEM]: "Heizsystem auswählen",
    [k.GIVE_THE_REAL_DIMENSION]: "GEBEN SIE DIE ECHTE ABMESSUNG AN",
    [k.ENTER_THE_REAL_LENGTH]: "Geben Sie die reale Länge ein",
    [k.DESIGNATED_EPISODE]: "angegebener Abschnitt",
    [k.SEE_PREVIEW]: "Vorschau anzeigen",
    [k.ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT]: "Passen Sie die Ansicht durch Zuschneiden und Drehen an",
    [k.THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA]: "Keine Produkte für die angegebenen Kriterien",
    [k.CHOOSE_A_MODEL]: "MODELL AUSWÄHLEN",
    [k.PLN_GROSS]: "zł Brutto",
    [k.FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM]: "Zur Kalibrierung markieren Sie die längste Seite des Raums",
    [k.STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM]: "Schritt 1: Umriss des Raums zeichnen",
    [k.SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT]: "Art des ausgewählten festen Bauelements auswählen",
    [k.STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION]: "Schritt 2: Flächen für die dauerhafte Bebauung ausschließen",
    [k.ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL]: "Schicht Dampfsperrfolie hinzufügen",
    [k.PLACE_THE_THERMOREGULATOR]: "Platzieren Sie den Thermoregler am Rand des Raums",
    [k.SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING]: "Energieklasse des Gebäudes auswählen",
    [k.THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD]: "Das Projekt hat nicht gespeicherte Änderungen. Möchten Sie den Assistenten wirklich verlassen?",
    [k.DO_YOU_WANT_TO_SAVE_THE_CHANGES]: "Möchten Sie die Änderungen speichern?",
    [k.CONFIRM_NEW_PASSWORD]: "Neues Passwort bestätigen",
    [k.SET_A_PASSWORD]: "Passwort festlegen",
    [k.THE_PHONE_IS_NOT_VALID]: "Telefonnummer ist ungültig",
    [k.MANAGE_ADDRESSES]: "Adressen verwalten",
    [k.PLEASE_SELECT_A_SHIPPING_ADDRESS]: "Bitte wählen Sie eine Versandadresse",
    [k.ADD_A_COMPANY]: "Ein Unternehmen hinzufügen",
    [k.SEARCH_FOR_A_COMPANY]: "Nach einem Unternehmen suchen",
    [k.MESSAGE]: "Nachricht",
    [k.NO_EMAIL_ADDRESS]: "Keine E-Mail-Adresse",
    [k.EDIT_CUSTOMER_DETAILS]: "Kundendaten bearbeiten",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY]: "Sind Sie sicher, dass Sie dieses Unternehmen löschen möchten?",
    [k.RULER_CALIBRATION]: "Lineal-Kalibrierung",
    [k.CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW]: "Werkzeug kalibrieren, um ein Distanzberechnungsprogramm für Ihre Ansicht zu lehren",
    [k.SEE_AN_EXAMPLE]: "Ein Beispiel ansehen",
    [k.CALCULATE_YOUR_HEATING_COSTS]: "Berechnen Sie Ihre Heizkosten",
    [k.THE_AREA_OF_THE_ROOM]: "Raumfläche",
    [k.PERMANENT_BUILDINGS]: "Dauerhafte Bebauung",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM]: "Sind Sie sicher, dass Sie den Raum löschen möchten?",
    [k.THERMOREGULATORS]: "Thermoregulatoren",
    [k.FLOOR_SENSOR]: "Bodensensor",
    [k.INDOOR_AIR_SENSOR]: "Innenraumsensor",
    [k.OUTSIDE_AIR_SENSOR]: "Außensensor",
    [k.OPTIONAL]: "Optional",
    [k.PROGRAMMING]: "Programmierung",
    [k.LEVEL_OF_SECURITY]: "Sicherheitsstufe",
    [k.CONTROL_PANEL]: "Bedienfeld",
    [k.TOUCH]: "Touch",
    [k.KEYBOARD]: "Tastatur",
    [k.KNOB]: "Drehknopf",
    [k.FIND_OUT_MORE]: "Mehr erfahren",
    [k.CHOOSE]: "Wählen",
    [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART]: "Sind Sie sicher, dass Sie dieses Produkt aus Ihrem Warenkorb entfernen möchten?",
    [k.FILTER]: "FILTERN",
    [k.CUTTING]: "Schneiden",
    [k.POWER]: "Leistung",
    [k.MAX_TEMP]: "Max. Temp.",
    [k.AREA]: "Fläche",
    [k.HEATING_MAT_POWER]: "Heizmattenleistung",
    [k.HEIGHT]: "Höhe",
    [k.THICKNESS]: "Dicke",
    [k.PRODUCT_PARAMETERS]: "PRODUKTDETAILS",
    [k.LENGTH]: "Länge",
    [k.HEATING_POWER]: "Heizleistung",
    [k.FOIL_SURFACE]: "Folienfläche",
    [k.MAX_HEATING_TEMPERATURE]: "Max. Heiztemperatur",
    [k.THE_WIDTH_OF_THE_FILM]: "Breite der Folie",
    [k.POSSIBILITY_TO_CUT_THE_FILM_CO]: "Möglichkeit, die Folie zu schneiden alle",
    [k.MAT_AREA]: "Mattenfläche",
    [k.THERMOSTAT_MODEL]: "Thermostatmodell",
    [k.RESISTANCE]: "Widerstand",
    [k.FOIL_MODEL]: "Folienmodell",
    [k.BRAND]: "Marke",
    [k.WEIGHT]: "Gewicht",
    [k.FOIL_GUARANTEE]: "Foliengarantie",
    [k.CONNECTION_KITS_PCS]: "Verbindungssätze [Stk.]",
    [k.WIRES]: "Kabel",
    [k.TAPE_PCS]: "Klebeband [Stk.]",
    [k.THERMOSTAT_WARRANTY]: "Thermostatgarantie",
    [k.CONNECTOR]: "Verbinder",
    [k.MAT_GUARANTEE]: "Mattengarantie",
    [k.PRO_TAPE_PCS]: "PRO-Klebeband [Stk.]",
    [k.PRODUCT_DESCRIPTION]: "Produktbeschreibung",
    [k.RETURN_TO_THE_KNOWLEDGE_BASE]: "Zurück zur Wissensdatenbank",
    [k.SIMILAR_ARTICLES]: "Ähnliche Artikel",
    [k.NUMBER_OF_INVESTMENTS]: "Anzahl der Investitionen",
    [k.VIEW_PROFILE]: "Profil ansehen",
    [k.LATEST_INVESTMENTS]: "Neueste Investitionen",
    [k.THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT]: "Keine Produkte im Projekt",
    [k.BILL_OF_MATERIALS_FOR_PROJECTS]: "Stückliste für Projekte",
    [k.YOUR_CALCULATION_WITHOUT_ASSEMBLY]: "IHRE BERECHNUNG OHNE MONTAGE",
    [k.YOU_WANT_TO_ADD_A_MONTAGE]: "Möchten Sie eine Montage hinzufügen?",
    [k.NUMBER_OF_KILOMETERS]: "Anzahl der Kilometer",
    [k.PRICE_FOR_ASSEMBLY]: "Preis für Montage",
    [k.YOUR_CALCULATION_WITH_ASSEMBLY]: "IHRE BERECHNUNG MIT MONTAGE",
    [k.CHECK_WHAT_RATE_YOU_ARE_AT]: "Überprüfen Sie, bei welchem Tarif Sie sind",
    [k.SHOW_HEATING_COSTS]: "Heizkosten anzeigen",
    [k.USABLE_AREA]: "Nutzfläche",
    [k.SELECTED_ENERGY_CLASS]: "Ausgewählte Energieklasse",
    [k.THE_PRICE_OF_ELECTRICITY]: "Strompreis",
    [k.HEATING_PRICE_IN_KWH_YEAR]: "Heizkosten in kWh/Jahr",
    [k.SAVE_AND_GENERATE_AN_OFFER]: "Speichern und Angebot generieren",
    [k.NUMBER_OF_PROJECTS]: "Anzahl der Projekte",
    [k.GO_TO_INVESTMENT]: "Zur Investition gehen",
    [k.LATEST_PROJECTS]: "Neueste Projekte",
    [k.ASSEMBLY]: "Montage",
    [k.START_TYPING]: "Tippen Sie los",
    [k.ORDERS_PLACED]: "Aufgegebene Bestellungen",
    [k.NO_ORDERS_PLACED]: "Keine Bestellungen aufgegeben",
    [k.SUBMIT]: "Senden",
    [k.SAYS]: "sagt",
    [k.ROTATE]: "Drehen",
    [k.SCALE]: "Maßstab",
    [k.ADD_A_NEW_PROJECT]: "Neues Projekt hinzufügen",
    [k.GO_BACK_TO_PROJECTS]: "Zurück zu den Projekten",
    [k.SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED]: "Wählen Sie die Seite, auf der sich die Ansicht befindet",
    [k.STEP]: "Schritt",
    [k.CREATED_BY]: "erstellt von",
    [k.INVESTOR_NAME]: "Name des Investors",
    [k.YOU_DONT_HAVE_ANY_OFFER_YET]: "Sie haben noch kein Angebot",
    [k.CURRENT_VIEW_CREATOR_SAVED]: `Nach Drücken der Schaltfläche "SPEICHERN UND SCHLIESSEN" wird die aktuelle Ansicht aus dem Assistenten im PDF-Angebot gespeichert. Andernfalls wird die Ansicht nicht gespeichert.`,
    [k.ENTER_DWG_FILE_SIZE]: `Geben Sie die DWG-Dateigröße ein.`,
    [k.CREATED_BY2]: `Erstellt von`,
    [k.DO_YOU_WANT_TO_SAVE_CHANGES]: `Möchten Sie die Änderungen speichern?`,
    [k.NUMBER_OF_ROOMS]: `Anzahl der Räume`,
    [k.FOIL]: "Folie",
    [k.MAT]: "Matte",
    [k.NETTO_PRICE]: "Netto-Preis",
    [k.QUANTITY_PCS_M]: "Menge Stk./lfm",
    [k.NET_VALUE]: "Netto-Wert",
    [k.MATERIAL_COST]: "Materialkosten",
    [k.INVESTOR_COST]: "Investorenkosten",
    [k.ADD_NOTE]: "Notiz hinzufügen",
    [k.REMOVE_PROJECT_FROM_OFFER]: "Projekt aus dem Angebot entfernen",
    [k.COVERING_AREA_OF_ROOM]: "Abdeckfläche des Raums",
    [k.SEE_ASSORTMENT]: "Sortiment ansehen",
    [k.LP]: "Nr.",

    [k.SUMMARY]: "Zusammenfassung",
    [k.THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION]: "Die Bestellung wurde zur Ausführung weitergeleitet",
    [k.THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB]: "Der Bestellstatus kann im Reiter Bestellungen verfolgt werden",
    [k.THANK_YOU_FOR_YOUR_TRUST]: "Vielen Dank für Ihr Vertrauen",

    [k.TO_PAY]: "Zu zahlen",
    [k.SHIPPING_TO_YOUR_OWN_WAREHOUSE]: "Versand an eigenes Lager",
    [k.SHIPPING_TO_THE_CUSTOMER]: "Versand an den Kunden",
    [k.YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER]:
        "Sie haben einen zusätzlichen Rabatt erhalten, der sich aus der Zusammenlegung von zwei Investitionen in eine Bestellung ergibt",
    [k.SHIPPING_ADDRESS]: "Versandadresse",
    [k.ADDRESSEE]: "Empfänger",
    [k.WAREHOUSE_ADDRESS]: "Lageradresse",
    [k.CONTACT]: "Kontakt",
    [k.CHOOSE_PAYMENT_METHOD]: "Zahlungsmethode wählen",
    [k.TRADITIONAL_BANK_TRANSFER]: "Traditionelle Banküberweisung",
    [k.PAYMENT_DEADLINE]: "Zahlungsfrist",
    [k.CASH_ON_DELIVERY]: "Nachnahme",
    [k.INVESTMENT_DATA]: "Investitionsdaten",
    [k.DELIVERY_ADDRESS]: "Lieferadresse",
    [k.LIST_OF_PRODUCTS]: "Produktliste",
    [k.PRODUCT_NAME]: "Produktname",
    [k.AMOUNT_OF_PRODUCTS]: "Produktmenge",
    [k.NET_PRICE]: "Netto-Preis",
    [k.GROSS_PRICE]: "Brutto-Preis",
    [k.INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST]:
        "Info: Wenn Sie Reserve-Material zur Investition hinzufügen möchten, klicken Sie oben rechts in der Produktliste auf Bearbeiten.",
    [k.INVESTMENT_ADDRESS]: "Investitionsadresse",
    [k.BANK_TRANSFER_DETAILS]: "Banküberweisungsdetails",
    [k.RECIPIENT]: "Empfänger",
    [k.ACCOUNT_NUMBER]: "Kontonummer",
    [k.DOWNLOAD_PDF]: "PDF herunterladen",
    [k.GO_TO_ORDER]: "Zur Bestellung gehen",
    [k.CHOOSE_A_BANK]: "Wählen Sie eine Bank",
    [k.THE_VALUE_OF_THE_MATERIALS]: "Materialwert",
    [k.SHOPPING_CART_ITEMS]: "Warenkorbelemente",
    [k.INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER]: "Info: Um die Versandadresse zu bearbeiten, klicken Sie auf den Stift oben rechts",
    [k.NO_ORDERS]: "Keine Bestellungen",
    [k.STATUS_OF_THE_ORDER]: "Bestellstatus",
    [k.DATE]: "Datum",
    [k.SHIPPING_NO]: "Versandnummer",
    [k.DOWNLOAD_THE_INVOICE]: "Rechnung herunterladen",
    [k.MORE_INFORMATION]: "Mehr Informationen",
    [k.SHIPPING_INFORMATION]: "Versanddaten",
    [k.ORDER_STATUS]: "Bestellstatus",
    [k.CONTACT_PERSON2]: "Kontaktperson",
    [k.ORDER_NOTES]: "Bestellnotizen (optional)",
    [k.QUOTA]: "Betrag",
    [k.GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT]: "Der Beginn der Projektarbeit beginnt mit der Hinzufügung einer Investition",
    [k.DUPLICATE]: "Duplizieren",
    [k.ROOMS]: "Räume",
    [k.COMPLETED_OFFERS]: "Abgeschlossene Angebote",
    [k.NO_PROJECTS]: "Keine Projekte",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS]: "Sind Sie sicher, dass Sie die ausgewählten Projekte löschen möchten?",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS]: "Sind Sie sicher, dass Sie die ausgewählten Angebote löschen möchten?",
    [k.COMPANY]: "Unternehmen",
    [k.YOUR_ADVANTAGE_IN_ASSEMBLY]: "Ihr Vorteil bei der Montage",
    [k.YOUR_BENEFIT_ON_THE_MATERIAL]: "Ihr Vorteil beim Material",
    [k.ADDED_TO_CART]: "Zum Warenkorb hinzugefügt",
    [k.CREATED]: "Erstellt",
    [k.PENDING]: "Ausstehend",
    [k.COMPLETE]: "Abgeschlossen",
    [k.REJECTED]: "Abgelehnt",
    [k.GO_TO_OFFERS]: "Zu den Angeboten gehen",
    [k.DONE_WITH_INSTALLATION]: "Mit Montage durchgeführt",
    [k.DONE_WITHOUT_INSTALLATION]: "Ohne Montage durchgeführt",
    [k.THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED]: "Der Name der Investition ist erforderlich",
    [k.INVESTOR_DATA]: "Investorendaten",
    [k.USERS]: "Benutzer",
    [k.RULES]: "Regeln",
    [k.CONTACT_DETAILS]: "Kontaktdaten",
    [k.THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER]: "Das Logo wird auf dem Angebot und den Rechnungen für den Kunden verwendet",
    [k.UPLOAD_THE_FILE]: "Datei hochladen",
    [k.UPLOAD_THE_LOGO_FILE]: "Logo-Datei hochladen",
    [k.MAX_SIZE]: "Max. Größe",
    [k.MAX_DIMENSIONS]: "Max. Abmessungen",
    [k.CURRENT_FILE]: "Aktuelle Datei",
    [k.NO_PHOTO]: "Kein Foto",
    [k.DELETE_FILE]: "Datei löschen",
    [k.WIZARD_LANGUAGE]: "Assistentensprache",
    [k.COMPANY_LOGO]: "Firmenlogo",
    [k.MAIN_USER]: "Hauptbenutzer",
    [k.OTHER_USERS]: "Andere Benutzer",
    [k.ADD_NEW_PROFILE]: "neues Profil hinzufügen",
    [k.CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM]: "Änderungen wurden vorgenommen, möchten Sie diese speichern?",
    [k.DISCARD]: "Verwerfen",
    [k.POSITION_OPTIONAL]: "Position (optional)",
    [k.LOCK_PROFILE]: "Profil sperren",
    [k.DELETE_PROFILE]: "Profil löschen",
    [k.DO_SAVE_CHANGES]: "Änderungen speichern?",
    [k.POSITION]: "Position",
    [k.THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED]: "Der Name der Energieklasse ist erforderlich",
    [k.THE_NAME_OF_THE_ENERGY_CLASS]: "Name der Energieklasse",
    [k.EU_DEMAND]: "EU-Anforderung",
    [k.EU_REQUIREMENT_IS_REQUIRED]: "EU-Anforderung ist erforderlich",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT]: "Heizsystem unter dem Estrich",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED]: "Heizsystem unter dem Estrich ist erforderlich",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Heizsystem unter dem Estrich für Räume: Badezimmer, Toilette, Waschküche, Sauna.",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]:
        "Heizsystem unter dem Estrich für Räume: Badezimmer, Toilette, Waschküche, Sauna ist erforderlich",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL]: "Heizsystem unter dem Paneel",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED]: "Heizsystem unter dem Paneel ist erforderlich",
    [k.REMOVE_ENERGY_CLASS]: "Energieklasse entfernen",
    [k.TABLE_OF_ENERGY_CLASSES]: "Tabelle der Energieklassen",
    [k.EU_DEMAND_EVERY_KWH]: "EU-Anforderung pro KWh/m2*Jahr",
    [k.SYSTEM_UNDER_THE_SPOUT]: "System unter dem Estrich",
    [k.SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "System unter dem Estrich für Räume (Badezimmer, Toilette, Waschküche, Sauna)",
    [k.SYSTEM_UNDER_THE_PANEL]: "System unter dem Paneel",
    [k.INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS]: "Info: Es ist möglich, eigene Energieklasseneinstellungen hinzuzufügen.",
    [k.ADD_YOUR_OWN_ENERGY_CLASS]: "Eigene Energieklasse hinzufügen",
    [k.ASSEMBLY_RULE]: "Montageregel",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM]: "Achtung! Der Netto-Betrag wird auf Grundlage der Nutzfläche des Raumes berechnet.",
    [k.HEAT_DECOR_RECOMMENDATION]: "Heat Decor Empfehlung",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT]: "Achtung! Der Netto-Betrag wird auf Grundlage der Fläche der Heizmatte berechnet.",
    [k.VAT_RULE]: "Mehrwertsteuerregel",
    [k.ACTIVE_VAT_RULES]: "Aktive Mehrwertsteuerregeln",
    [k.ADD_A_NEW_USER]: "Neuen Benutzer hinzufügen",
    [k.UNLOCK_PROFILE]: "Profil entsperren",
    [k.NET_PRICE_PER_M2]: "Netto-Preis pro m²",
    [k.SYSTEM_FOR_TILES]: "System für Fliesen",
    [k.INSULATION_MAT]: "Isoliermatte 5 mm",
    [k.XPS_EXTRUDED_POLYSTYRENE]: "Extrudiertes Polystyrol XPS 6 mm",
    [k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY]: "System für Paneele (XPS-Unterlage 6 mm)",
    [k.SYSTEM_FOR_PANELS_5_MM_BASE]: "System für Paneele (Unterlage 5 mm)",
    [k.THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM]: "Der Mindestwert darf nicht größer als der Höchstwert sein",
    [k.UNDER_LAMINATED_PANELS]: "unter Laminatplatten",
    [k.UNDER_THE_SPOUT]: "unter dem Estrich",
    [k.UNDER_SPC_VINYL_PANELS]: "unter SPC-Vinylplatten",
    [k.PRODUCT_TAILORED_TO_YOUR_SYSTEM]: "Produkt angepasst an Ihr System",
    [k.YEARS_WARRANTY]: "Jahre Garantie",
    [k.THERMOREGULATOR]: "Thermoregler",
    [k.INTERNAL_AIR_SENSOR]: "Interner Luftsensor",
    [k.EXTERNAL_AIR_SENSOR]: "Externer Luftsensor",
    [k.CONNECTION]: "Verbindung",
    [k.HAS]: "Hat",
    [k.DOES_NOT_HAVE]: "Hat nicht",
    [k.WHITE]: "Weiß",
    [k.BLACK]: "Schwarz",
    [k.MANUAL]: "Manuell",
    [k.INFO_ZIGBEE]: "Info: *Dem Projekt wird eine ZigBee 3.0-Zentrale hinzugefügt, die für den Betrieb des Systems erforderlich ist. Unterstützt bis zu 50 Geräte.",
    [k.SKIP]: "Überspringen",
    [k.ATTENTION]: "Achtung!",
    [k.ERROR]: "Fehler",
    [k.INFO]: "Info",
    [k.DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS]: "Möchten Sie weitere feste Bauelemente hinzufügen?",
    [k.RECTANGLE]: "Rechteck",
    [k.LINE]: "Linie",
    [k.ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED]: "Ein zusätzlicher Rabatt wurde berechnet!",
    [k.IRREGULAR_SHAPE]: "Unregelmäßige Form",
    [k.VAPOR_BARRIER_FILM]: "Dampfsperrfolie",
    [k.INSULATING_UNDERLAY]: "Isolierunterlage",
    [k.WIRES_BROWN]: "Kabel - braun",
    [k.WIRES_BLUE]: "Kabel - blau",
    [k.CONNECTORS_FOR_HEATING_FOIL]: "Verbinder für Heizfolie (Typ A oder B)",
    [k.SELF_FUSING_TAPE]: "Selbstverschmelzendes Klebeband",
    [k.HEAT_DECOR_ADHESIVE_TAPE]: "Heat Decor Klebeband",
    [k.ARRANGEMENT]: "Anordnung",
    [k.DRAG]: "Ziehen",
    [k.DELETE_ROOM]: "Raum löschen",
    [k.INCORRECT_FORMAT]: "Falsches Format",
    [k.USE_HAND]: "Ansicht verschieben",
    [k.FILE_TYPE_MUST_BE]: "Falscher Dateityp. Datei muss eines der folgenden Formate haben: image/jpeg, image/png, .pdf, image/gif, .dwg",
    [k.THERE_IS_NO_SUCH_OFFER]: "Es gibt kein solches Angebot",
    [k.COPY_DATA]: "Daten kopieren",
    [k.DELETE_THERMOSTAT]: "Thermostat löschen",
    [k.SHOWER_BATH__WASHING_MACHINE]: "Dusche/Badewanne/Waschmaschine",
    [k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL]: "Freistehendes Waschbecken/Schüssel",
    [k.WALL_HUNG_WASHBASIN__HUNG_BOWL]: "Wandhängendes Waschbecken/Schüssel",
    [k.SEWAGE_GRATE]: "Abflussgitter",
    [k.SELECT_POINTS_TO_CREATE_A_FIGURE]: "Punkte auswählen, um eine Figur zu erstellen",
    [k.STAINLESS_STEEL_MESH]: "Edelstahlgitter",
    [k.GROUNDING_WIRE]: "Erdungskabel",
    [k.CONNECTORS_FOR_STAINLESS_STEEL_MESH]: "Verbinder für Edelstahlgitter (Typ A oder B)",
    [k.SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS]: "Selbstverschmelzendes Klebeband für Heizfolienverbinder",
    [k.REINFORCED_TAPE]: "Verstärktes Klebeband",
    [k.INSTALLATION_BOX]: "Installationsdose",
    [k.PROTECTIVE_CONDUIT]: "Schutzrohr",
    [k.CITY]: "Stadt",
    [k.INPUTENERGY]: "Energieklasse",
    [k.LISTOFINVEST]: "Investitionsliste",
    [k.PROJECTSSTATUS]: "Projektstatus",
    [k.FLOORAREA]: "Fläche",
    [k.COMPLETENOTES]: "Notizen vervollständigen",
    [k.TEXTADDINVEST]:
        "Ein Investor im Heat Decor Creator ist eine natürliche oder juristische Person, die Materialien für die Installation eines modernen Heizsystems kaufen und möglicherweise dessen Montage in Auftrag geben möchte. Zu den Identifikationsdaten des Investors gehören die Steuernummer (NIP), der Name im Falle einer juristischen Person sowie Vor- und Nachname im Falle einer natürlichen Person sowie Kontaktdaten.",
    [k.IMPORTANT]: "Wichtig!",
    [k.TEXTADDINVEST2]: "Der Wohn- oder Geschäftssitz des Investors muss in Polen liegen.",
    [k.INVESTORPROFILTEXT]: "Für diesen Investor gibt es keine Investition",
    [k.INVESTORPROFILTEXT2]: "Möchten Sie diese jetzt hinzufügen?",
    [k.INVESTORPROFILTEXT3]: "Der Beginn der Arbeit am Projekt beginnt mit dem Hinzufügen einer Investition",
    [k.HEATING_SYSTEM]: "Heizsystem",
    [k.SYSTEMCOMPONENTS]: "Systemkomponenten",
    [k.MODIFY_THE_PERMANENT_STRUCTURE]: "Feste Struktur ändern",
    [k.ACCESSORIES]: "Zubehör",
    [k.DATE_CREATED]: "Erstellungsdatum",
    [k.COPY_OFFER]: "Angebot kopieren",
    [k.UNDO]: "RÜCKGÄNGIG",
    [k.FILL_IN_THE_DETAILS]: "Kontaktdaten ausfüllen",
    [k.E_G]: "z.B. Architekt",
    [k.ADD_INVEST_TEXT]:
        "Eine Investition im Heat Decor Creator ist die Gesamtheit der Räume in einem Gebäude, in denen ein modernes Heizsystem geplant ist. Zu den Identifikationsdaten der Investition gehören die in den Creator hochgeladenen Grundrisse einzelner Räume oder Stockwerke, die Nutzfläche einzelner Räume, markierte Sicherheitszonen, in denen keine elektrische Fußbodenheizung installiert werden darf, markierte Bereiche mit fester Bebauung ohne Mindesthöhe von 3 cm Beinen, der Energiebedarf zur Beheizung, der sich aus der Wärmedämmung des Gebäudes (EUco) ergibt, sowie die Adressdaten.",
    [k.COMPLATE_THE_INVEST]: "Investitionsdaten vervollständigen",
    [k.DISCOUNT_VALUE]: "Rabattwert",
    [k.ADD_MONTAGE]: "Montage hinzufügen",
    [k.MOUNTING_SYSTEM]: "Montagesystem",
    [k.COST_OF_INSTALL]: "Installationskosten",
    [k.NOTE_CHECK]: "Achtung! Überprüfen Sie, wie die Montageregel eingestellt ist",
    [k.VAT_RATE]: "Mehrwertsteuersatz",
    [k.NOTE_CHECK_VAT]: "Achtung! Überprüfen Sie, auf welchem Mehrwertsteuersatz Sie sich befinden!",
    [k.HEATING_COSTS]: "Heizkosten",
    [k.ADD_HEATING_COSTS]: "Heizkosten hinzufügen",
    [k.RESET]: "Zurücksetzen",
    [k.RETURN_TO_OFFERS]: "ZU DEN ANGEBOTEN ZURÜCK",
    [k.LACK_OF_ROOMS]: "Keine Räume",
    [k.DRAW_A_ROOM]: "Raum zeichnen",
    [k.HEATING_FOIL_UNDER_THE_LAYERED]: "Heizfolie unter Schichtboden",
    [k.CONFIGURATION_OF_PRICE]: "Preisgestaltung pro km",
    [k.SALEOFFER]: "Verkaufsangebot",
    [k.COSTOFINSTALLSERVICE]: "Installationsservicekosten",
    [k.COSTOFALLMATERIALS]: "Kosten aller Materialien",
    [k.LISTOFMATERIALS]: "Materialliste",
    [k.MATERIAL]: "Material",
    [k.NO]: "Keine",
    [k.MANUFACTUREROFHEATING]: "Hersteller der Heizfolie und Heizmatten: Heat Decor",
    [k.VISUALIZATION]: "Visualisierung des Heizsystems",
    [k.VISUALIZATIONOFTHEHEATING]: "Die Visualisierung des Heizsystems ist Anhang zu diesem Angebot.",
    [k.LOGODISTRIBUTOR]: "Logo des Vertriebspartners",
    [k.VISUALIZATIONMADE]: "Visualisierung erstellt von",
    [k.AREAOFTHEHEATING]: "Fläche der Heizfolie",
    [k.POWEROFTHEHEATING]: "Leistung der Heizfolie",
    [k.ELECTRICALCONNECTION]: "Elektrischer Anschluss",
    [k.ESTIMATEDANNUAL]: "Geschätzte jährliche Betriebskosten",
    [k.ENERGYCLASSSOLO]: "Energieklasse",
    [k.CHANGETHEPOWER]: "Leistung der Folie ändern",
    [k.GERMAN]: "Deutsch",
    [k.ROMANIAN]: "Rumänisch",
    [k.HUNGARIAN]: "Ungarisch",
    [k.RODO]: "Rodo des Distributors",
    [k.RODOTEXT]:
        "Heat Decor sp. z o.o. übernimmt keine Verantwortung für das vom Benutzer erstellte Projekt/Materialzusammenstellung, einschließlich der Auswahl des Heizsystems für den Raum, der Auswahl seiner Leistung und Materialien. Der Benutzer trägt die Verantwortung und das Risiko für die Auswahl, mögliche Fehlfunktionen und die Effizienz des von ihm konfigurierten Heizsystems.",
    [k.RODOHD]: "Rodo Heat Decor",
    [k.SCOPEOFRESPONSIBILITY]: "Verantwortungsbereich",
    [k.ATTACHMENTS]: "Anhänge",
    [k.ATTACHMENTSTEXT]: "Das Angebot ist vollständig mit einem Anhang, der die Visualisierung des Heizsystems in den einzelnen Räumen enthält.",
    [k.ANNOTATIONS]: "Anmerkungen zum Angebot",
    [k.THANKYOU]: "Vielen Dank",
    [k.THANKYOUTEXT]: "für Ihr Interesse an dem modernen Heizsystem.",
    [k.THANKYOUQUESTION]: "Bei Fragen wenden Sie sich bitte an die anbietende Person.",
    [k.OFFERINGPERSON]: "Anbietende Person",
    [k.OFFERINGCOMPANY]: "Anbietendes Unternehmen",
    [k.RECOMMENDMODERN]: "Wir empfehlen die modernen Heizsysteme von Heat Decor",
    [k.MAINTENANCE]: "wartungsfrei",
    [k.COMPLETLY]: "vollständig",
    [k.SILENT]: "leise",
    [k.FRIENDLY]: "umweltfreundlich",
    [k.ENVIRONMENT]: "für die Umwelt",
    [k.WITHINTELLIGENT]: "mit intelligenter",
    [k.CONTROL]: "Steuerung",
    [k.VISUALIZATIONOFTHEDISTRIBUTION]: "Visualisierung der Verteilung",
    [k.HEATINGSYSTEM]: "Heizsystem",
    [k.TOTALPOWER]: "Gesamtleistung des Systems",
    [k.HEATINGFLOORS]: "Heizgeschosse",
    [k.LEGEND]: "Legende",
    [k.PERMANENT_BUILDINGS2]: "feste Gebäude",
    [k.CAPACITYOFHEATING]: "HEIZSYSTEMLEISTUNG IN EINZELNEN RÄUMEN",
    [k.NAMEOFROOM]: "Raumname",
    [k.SYSTEMTYPE]: "Systemtyp",
    [k.AREACOVERAGE]: "Flächenabdeckung [ % ]",
    [k.POWERPER]: "Leistung pro (m2) [ W/(m2) ]",
    [k.SYSTEMPOWER]: "Systemleistung im Raum [ W ]",
    [k.HEATINGCIRCUIT]: "Widerstand des Heizkreises [ \u03A9 ]",
    [k.CONTACTOR]: "* - für den Raum ist ein Schütz erforderlich",
    [k.OFFERNO]: "ANGEBOT NR.",
    [k.DEVELOPMENT]: "Entwicklung",
    [k.BASEMENTPLAN]: "Kellergrundriss",
    [k.FLOORPLAN]: "Etagenplan",
    [k.ATTICPLAN]: "Dachbodenplan",
    [k.STOREYPLAN]: "Geschossplan",
    [k.LEVELPLAN]: "Ebenenplan",
    [k.ROOMPLAN]: "Raumplan",
    [k.GROUNDFLOORPLAN]: "Erdgeschossplan",
    [k.NO_THERMOSTAT]: "Kein Thermostat",
    [k.EXTERNAL_SENSOR]: "Externer Sensor",
    [k.APPLY_DISCOUNT_TO_ALL_PRODUCTS]: "Rabatt auf alle Produkte anwenden",
    [k.INVESTOR_PRICE_BELOW_COST]: "Achtung! Der Preis für einen der Produkte liegt unter Ihrem Einkaufspreis!",
    [k.CURRENCY]: "Währung",
    [k.POLISH_ZLOTY]: "Polnischer Zloty (PLN)",
    [k.EURO]: "Euro (EUR)",
    [k.CZECH_KORUNA]: "Tschechische Krone (CZK)",
    [k.BRITISH_POUND]: "Britisches Pfund (GBP)",
    [k.CURRENCY_IS_REQUIRED]: "Währung ist erforderlich",
    [k.SELECT_DISTRIBUTOR]: "Wählen Sie einen Distributor aus",
    [k.SELECT_ACTIVE_PRODUCTS]: "Wählen Sie aktive Produkte aus",
    [k.MANAGE_PRODUCTS]: "Produkte verwalten",
    [k.ENTER_MESSAGE]: "Nachricht eingeben",
    [k.LOGFF]: "Benutzer abmelden",
    [k.SELECTUSER]: "Benutzer auswählen",
    [k.USERACCOUNT]: "Benutzerkonto",
    [k.US_DOLLAR]: "US-Dollar (USD)",
    [k.PRODUCT_SUMMARY]: "Produktübersicht",
    [k.ERROR_OCCURRED]: "Ein Fehler ist aufgetreten",
};
