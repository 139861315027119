import { React } from "react";
import styled from "styled-components";
import { FaFacebookF } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { FiYoutube } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import device from "../_styles/media-queries";
import i18n from "i18next";
import k from "../i18n/keys";

const GreenboxSocial = styled.div`
    position: relative;
    font-size: 55px;
    background-color: ${({ theme: { colors } }) => `${colors.green.normal}bb`};
    border-radius: 10px;
    padding: 50px 70px;
    text-align: center;
    color: ${({ theme: { colors } }) => `${colors.base}`};
    span {
        position: absolute;
        align-items: center;
        display: flex;
        text-align: center;
        font-size: 45px;
        left: -35px;
        top: -30px;
        width: 73px;
        height: 73px;
        border-radius: 37px;
        background: #efefef;
        svg {
            color: #000000;
        }
    }

    h1 {
        text-align: center;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 40px 0 20px;
    }
    b {
        letter-spacing: 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 63px;
    }
    h2 {
        letter-spacing: 0px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
    }
    h4 {
        text-align: center;
        color: #ffffff;
    }
    h2 {
        text-align: center;
        margin: 50px 0 20px;
    }
    a {
        text-decoration: none;
        color: #ffffff;
    }

    div {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        border-top: 2px solid #fff;
    }
    svg {
        color: #ffffff;
        width: 70px;
        height: 28px;
    }

    @media (max-width: 767px) {
        padding: 25px 25px 0;
        h1 {
            letter-spacing: 0px;
            font-size: 25px;
            margin: 0 0 20px;
        }
        svg {
            width: 42px;
            height: 20px;
        }
        span {
            font-size: 30px;
            left: -20px;
            top: -17px;
            width: 50px;
            height: 50px;
        }
        h4 {
            font-size: 14px;
        }
        h2 {
            font-size: 20px;
        }
    }
    @media ${device.maxLaptopM} {
        background-color: ${({ theme: { colors } }) => `${colors.green.normal}`};
        margin: 40px 0;
        h1 {
            font-size: 32px;
        }
    }

    @media ${device.maxLaptop} {
        h1 {
            font-size: 25px;
        }
    }
`;

const InfoSocial = () => {
    return (
        <GreenboxSocial>
            <span>
                <FaInfo />
            </span>
            <h1>{i18n.t(k.WATCH_HEAT_DECOR)}</h1>
            <h4>{i18n.t(k.READ_AND_SUBSCRIBE)}</h4>
            <a href=" https://heatdecor.com/kategoria/blog/" rel="noreferrer">
                <h2>{i18n.t(k.BLOG)}</h2>
            </a>
            <div>
                <a href="https://www.facebook.com/heatdecor/" rel="noreferrer" target="_blank">
                    <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/heat_decor/" rel="noreferrer" target="_blank">
                    <FiInstagram />
                </a>
                <a href="https://www.youtube.com/c/HeatDecor" rel="noreferrer" target="_blank">
                    <FiYoutube />
                </a>
                <a href="https://www.linkedin.com/company/heat-decor/" rel="noreferrer" target="_blank">
                    <FaLinkedinIn />
                </a>
                <a href="https://twitter.com/heat_decor" rel="noreferrer" target="_blank">
                    <FaTwitter />
                </a>
            </div>
        </GreenboxSocial>
    );
};

export { InfoSocial };
