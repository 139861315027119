import { createContext, useEffect, useCallback, useMemo, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as auth from "../_helpers/authProvider";
import { client } from "../_api";
import { useAsync } from "../_utils";
import { FullPageSpinner, FullPageErrorFallback } from "../_components/common";
import { QueryClient } from "react-query";
import useToast from "../hooks/useToast";
import i18n from "i18next";
import k from "../i18n/keys";

const queryClient = new QueryClient();

async function getAppData() {
    let user = null;

    const token = await auth.getToken();
    if (token) {
        const data = await client("User/GetCurrentUser", { token });
        user = data;
        /*     user = {};
    user.username = 'Testowy';
    user.role = 1; */
    }
    localStorage.setItem("lang", user?.distributor?.language == null ? "pl" : user?.distributor?.language);
    return user;
}

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
    const { data: user, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync();
    const [deviceId, setDeviceId] = useState();
    const [loginUser, setLoginUser] = useState("");
    const [isNewDevice, setIsNewDevice] = useState("");
    const [defaultCalibration, setDefaultCalibration] = useState();
    const { toastError } = useToast();

    const [defaultZoomValue1, setDefaultZoomValue1] = useState(1);
    const [defaultZoomValue2, setDefaultZoomValue2] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        const appDataPromise = getAppData();
        run(appDataPromise);
    }, [run]);

    const login = useCallback(
        (form) =>
            auth
                .login(form)
                .then((user) => {
                    setData(user);
                    navigate("/");
                })
                .catch(() => {
                    toastError(i18n.t(k.INCORRECT_LOGIN_OR_PASSWORD));
                }),
        [setData, navigate, useLocation]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    const register = useCallback(
        (form) =>
            auth
                .register(form)
                .then((user) => {
                    setData(user);
                    navigate("/");
                    window.location.reload();
                })
                .catch((error) => {
                    toastError(error);
                }),
        [setData]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    const forgotPass = useCallback(
        (form) =>
            auth
                .forgotPass(form)
                .then(() => {
                    navigate("/thanks");
                })
                .catch((error) => {
                    toastError(error);
                }),
        [setData]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    const resetPass = useCallback(
        (form) =>
            auth
                .resetPass(form)
                .then(() => {
                    navigate("/resetpasswordsuccessful");
                })
                .catch((error) => {
                    toastError(error);
                }),
        [setData]
    );

    const logout = useCallback(() => {
        auth.logout();
        queryClient.clear();
        setIsNewDevice("");
        setData(null);
    }, [setData]);

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            register,
            forgotPass,
            resetPass,
            setData,
            deviceId,
            setDeviceId,
            loginUser,
            setLoginUser,
            isNewDevice,
            setIsNewDevice,
            defaultCalibration,
            setDefaultCalibration,
            defaultZoomValue1,
            setDefaultZoomValue1,
            defaultZoomValue2,
            setDefaultZoomValue2,
        }),
        [
            login,
            logout,
            register,
            user,
            forgotPass,
            resetPass,
            setData,
            deviceId,
            setDeviceId,
            loginUser,
            setLoginUser,
            isNewDevice,
            setIsNewDevice,
            defaultCalibration,
            setDefaultCalibration,
            defaultZoomValue1,
            setDefaultZoomValue1,
            defaultZoomValue2,
            setDefaultZoomValue2,
        ]
    );

    if (isLoading || isIdle) {
        return <FullPageSpinner />;
    }

    if (isError) {
        return <FullPageErrorFallback error={error} onRefresh={logout} />;
    }

    if (isSuccess) {
        return <AuthContext.Provider value={value} {...props} />;
    }

    throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
}

function useClient() {
    const { user } = useAuth();
    const token = user?.token;
    return useCallback((endpoint, method, config) => client(endpoint, { ...config, token, method }), [token]);
}

export { AuthProvider, useAuth, useClient };
