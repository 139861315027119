import { React, createContext, useState, useContext, cloneElement, useEffect } from "react";
import { VisuallyHidden } from "@reach/visually-hidden";
import { CircleButton } from "./buttons";
import { Dialog } from "./common";
import { DialogOverlay } from "@reach/dialog";

const callAll =
    (...fns) =>
    (...args) =>
        fns.forEach((fn) => fn && fn(...args));

const ModalContext = createContext();

function Modal(props) {
    const [isOpen, setIsOpen] = useState(false);
    return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />;
}

function ModalDismissButton({ children: child }) {
    const [, setIsOpen] = useContext(ModalContext);
    return cloneElement(child, {
        onClick: callAll(() => setIsOpen(false), child.props.onClick),
    });
}

function ModalOpenButton({ children: child }) {
    const [, setIsOpen] = useContext(ModalContext);
    return cloneElement(child, {
        onClick: callAll(() => setIsOpen(true), child?.props?.onClick),
    });
}

function ModalContentsMultiBase(props) {
    const [isOpen, setIsOpen] = useContext(ModalContext);
    const additionalStyle = props.addedStyles;

    return (
        <DialogOverlay style={{ background: "none", width: window.innerWidth > 575 ? null : "100vw" }} isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
            <div style={{ display: "flex", justifyContent: "space-around", width: "70vw", margin: "0 auto" }}>
                <Dialog {...props} />
                <Dialog {...props} />
                <Dialog {...props} />
            </div>
        </DialogOverlay>
        /*  <Dialog isOpen={isOpen} onDismiss={() => setIsOpen(false)} {...props} style={{...additionalStyle}}/>*/
    );
}

function ModalContentsBase(props) {
    const [isOpen, setIsOpen] = useContext(ModalContext);
    const additionalStyle = props.addedStyles;
    useEffect(() => {
        if (props.toggle) {
            setIsOpen(props.toggle);
        }
        if (props.toggle === "close") setIsOpen(false);
    }, [props.toggle]);
    return (
        <DialogOverlay
            isOpen={isOpen}
            style={{ width: window.innerWidth > 575 ? null : "100vw" }}
            onDismiss={() => {
                setIsOpen(true);
            }}>
            <Dialog {...props} style={{ ...additionalStyle }} />
        </DialogOverlay>
    );
}

function ModalContents({ title, addCloseBtn, dialogCount, children, ...props }) {
    if (dialogCount > 1)
        return (
            <ModalContentsMultiBase dialogs={dialogCount} {...props}>
                {children}
            </ModalContentsMultiBase>
        );
    else
        return (
            <ModalContentsBase {...props}>
                {addCloseBtn && (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ModalDismissButton>
                            <CircleButton>
                                <VisuallyHidden>Close</VisuallyHidden>
                                <span aria-hidden>×</span>
                            </CircleButton>
                        </ModalDismissButton>
                    </div>
                )}
                <h3 style={{ textAlign: "center", fontSize: "2em" }}>{title}</h3>
                {children}
            </ModalContentsBase>
        );
}

export { Modal, ModalDismissButton, ModalOpenButton, ModalContents };
