import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: null },
        },
    },
});

appInsights.loadAppInsights();

const setReactHistory = (history) => {
    reactPlugin.setHistory(history);
};

export { appInsights, reactPlugin, setReactHistory };
