import React, { useEffect } from "react";
import { lazy, Suspense } from "react";
import { useAuth } from "./_context/authContext";
import { FullPageSpinner } from "./_components";
import { ThemeProvider } from "styled-components";
import theme from "./_styles/theme";
import { CookiesProvider } from "react-cookie";
import { appInsights } from "./appInsights";
import { ToastContainer } from "react-toastify";

const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./UnauthenticatedApp"));

function App() {
    const { user } = useAuth();

    useEffect(() => {
        appInsights.trackPageView();
    }, []);

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />
            <ThemeProvider theme={theme}>
                <Suspense fallback={<FullPageSpinner />}>
                    <CookiesProvider>{user ? <AuthenticatedApp /> : <UnauthenticatedApp />}</CookiesProvider>
                </Suspense>
            </ThemeProvider>
        </>
    );
}

export { App };
