import { React } from "react";
import styled from "styled-components";

const Progress = styled.div`
    width: 45px;
    height: 15px;
    border-radius: 7px;
    margin: 20px 10px;
    border: 0.5px solid rgb(189, 113, 113);
    overflow: hidden;
    position: relative;
    left: 72%;
    top: -50%;
    overflow: hidden;
`;

const ProgressFill = styled.div`
    width: ${({ value }) => (value ? `${value}%` : "0%")};
    height: 100%;
    background: ${({ theme: { colors } }) => colors.green.normal};
    transition: all 0.2s;
`;

export const ProgressBar = ({ value }) => {
    return (
        <Progress>
            <ProgressFill value={Math.round(value)}></ProgressFill>
        </Progress>
    );
};
