import React from "react";
import { Modal, ModalContents, ModalOpenButton, ModalDismissButton, ModalButtonsWrapper, Button } from "./index";
import i18n from "i18next";
import k from "../i18n/keys";
import { FaCheck } from "react-icons/fa";

export const ConfirmModal = ({
    children,
    message,
    message2,
    confirmText,
    confirmAction,
    rejectText,
    rejectAction,
    toggle,
    className,
    disableConfirmButton,
    rejectButtonOff,
    noFaCheckIcon,
    buttonInsideModal,
}) => {
    return (
        <Modal>
            {children && <ModalOpenButton>{children}</ModalOpenButton>}
            <ModalContents aria-label="Calibration form" dialogCount={1} toggle={toggle} className={className} addedStyles={{ borderRadius: "10px" }}>
                {message2 && <h2 style={{ textAlign: "center", marginBottom: "10px" }}>{message2}</h2>}
                <h5 style={{ textAlign: "center", marginBottom: "30px", marginTop: "30px" }}>{message}</h5>
                <ModalButtonsWrapper style={{ marginTop: "30px" }}>
                    {rejectButtonOff ? null : (
                        <ModalDismissButton>
                            <Button style={{ color: "#646464", backgroundColor: "white", border: "1px solid #646464" }} primary onClick={rejectAction}>
                                {rejectText ? rejectText : i18n.t(k.NO)}
                            </Button>
                        </ModalDismissButton>
                    )}
                    <ModalDismissButton>
                        <Button
                            primary
                            icon={noFaCheckIcon ? null : <FaCheck style={{ fontSize: "15px" }} />}
                            onClick={confirmAction}
                            disabled={disableConfirmButton ? disableConfirmButton : false}>
                            {confirmText ? confirmText : i18n.t(k.YES)}
                        </Button>
                    </ModalDismissButton>
                </ModalButtonsWrapper>
            </ModalContents>
        </Modal>
    );
};
